import React, { useEffect, useState, useTransition } from "react";
import { useDispatch, useSelector } from "react-redux";
import CommonIcons from "../../assets/icons";
import CameraSVg from "../../assets/svg/camera.svg";
import DeleteSvg from "../../assets/svg/delete.svg";
import CloseSvg from "../../assets/svg/dismiss.svg";
import EditPolygonSvg from "../../assets/svg/edit-polygon.svg";
import IconInfoSvg from "../../assets/svg/search-info.svg";
import IconIntersect from "../../assets/svg/search-intersect.svg";
import InfoSvg from "../../assets/svg/info.svg";
import LayerSvg from "../../assets/svg/layer.svg";
import { RootState } from "../../store/store";
import themeColor from "../../themes/colorTheme";
import ButtonIconComp from "./components/ButtonIcon";
import { convertGeoJSONToSVG } from "./components/ultis";
import {
  changeActiveFeature,
  clearFeatures,
  removeFeature,
  setFlagEditPolygon,
  setSelected,
  setSourceData,
} from "../../store/reducers/mapTaskingSlice";
import {
  changeLeftDrawerType,
  changeShowDrawer,
  resetBottomDrawer,
} from "../../store/reducers/drawerSlice";
import { setLoadingGlobal } from "../../store/reducers/appCommonSlice";
import { getHistoryByUserAsync } from "../../store/reducers/searchSlice";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { ChangeActionActiveFooter } from "../../store/reducers/statusAction";

const DrawerHistorySearch = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [data, setData] = useState<any>([]);
  let featuresDraw = useSelector(
    (state: RootState) => state.mapTasking.features
  );

  let selectedPolygon = useSelector(
    (state: RootState) => state.mapTasking.activeFeature
  );

  let sourceId = useSelector((state: RootState) => state.mapTasking.sourceId);

  let maplibreGL = useSelector(
    (state: RootState) => state.mapTasking.maplibreGL
  );

  let flagEditPolygon = useSelector(
    (state: RootState) => state.mapTasking.flagEditPolygon
  );

  let leftDrawerType = useSelector(
    (state: RootState) => state.drawerSlice.leftDrawerType
  );

  let showDrawer = useSelector(
    (state: RootState) => state.drawerSlice.showDrawer
  );

  let draw = useSelector((state: RootState) => state.mapTasking.draw);

  let bottomDrawerType = useSelector(
    (state: RootState) => state.drawerSlice.bottomDrawerType
  );

  const deleteSeclectedPolygon = () => {
    draw?.delete(selectedPolygon?.id);

    dispatch(removeFeature(selectedPolygon));
    dispatch(changeActiveFeature({}));

    dispatch(changeLeftDrawerType(""));

    maplibreGL
      .getSource(sourceId + "_hover")
      .setData({ type: "FeatureCollection", features: [] });

    dispatch(
      setSourceData({
        type: "FeatureCollection",
        features: [],
      })
    );

    dispatch(setSelected([]));
    dispatch(setLoadingGlobal(false));
  };

  const removeAllFeatures = () => {
    if (draw) {
      dispatch(setLoadingGlobal(true));
      draw.deleteAll();

      // @ts-ignore
      maplibreGL.getSource(sourceId + "_hover").setData({
        type: "FeatureCollection",
        features: [],
      });

      // @ts-ignore
      maplibreGL.removeLayer(sourceId + "_hover_fill");
      // @ts-ignore
      maplibreGL.removeLayer(sourceId + "_hover_outline");

      dispatch(clearFeatures());

      dispatch(changeLeftDrawerType(""));
    }

    dispatch(setLoadingGlobal(false));
  };
  const renderHeaderAction = () => {
    return (
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-2">
          {/* <ButtonIconComp
            className="!h-7 div-one-center"
            item={{ icon: CameraSVg, description: "Search results" }}
            classNameImage="h-4 w-4"
            disabled={!selectedPolygon?.id}
          />

          <ButtonIconComp
            className={
              "!h-7 div-one-center " +
              (showDrawer && leftDrawerType === "Info AOI"
                ? "btn-action-active"
                : "")
            }
            item={{ icon: InfoSvg, description: "Information polygon" }}
            classNameImage="h-4 !w-4"
            onClick={() => {
              dispatch(changeShowDrawer(!showDrawer));
              dispatch(
                changeLeftDrawerType(
                  leftDrawerType === "Info AOI" ? "" : "Info AOI"
                )
              );
            }}
            disabled={!selectedPolygon?.id}
          />
          <ButtonIconComp
            className={
              "!h-7 div-one-center " +
              (flagEditPolygon ? "btn-action-active" : "")
            }
            item={{ icon: EditPolygonSvg, description: "Edit polygon" }}
            classNameImage="h-4 !w-4"
            onClick={() => {
              // dispatch(setFlagEditPolygon(!flagEditPolygon));
              // if (flagEditPolygon) {
              //   draw.changeMode("simple_select", { featureIds: [] });
              // } else
              //   draw.changeMode("direct_select", {
              //     featureId: selectedPolygon?.id,
              //   });
            }}
            disabled={!selectedPolygon?.id}
          />
          <ButtonIconComp
            className="!h-7 div-one-center"
            item={{ icon: DeleteSvg, description: "Delete polygon" }}
            classNameImage="h-4 !w-4"
            onClick={deleteSeclectedPolygon}
            disabled={!selectedPolygon?.id}
          /> */}
        </div>

        <div className="flex items-center gap-2 justify-end">
          {/* <ButtonIconComp
            className="!h-7 div-one-center !px-3"
            item={{
              icon: DeleteSvg,
              description: "Delete all polygon",
              title: "Remove all",
            }}
            onClick={removeAllFeatures}
            disabled={featuresDraw?.length === 0}
          /> */}

          <ButtonIconComp
            className="!h-7"
            item={{
              icon: CloseSvg,
              description: t("Close"),
            }}
            onClick={() => {
              // @ts-ignore
              dispatch(ChangeActionActiveFooter("histories"));
              dispatch(resetBottomDrawer());
            }} // here
          />
        </div>
      </div>
    );
  };

  const PolygonItem = ({
    img,
    selected = false,
    label,
    icon,
    onClick,
  }: any) => {
    return (
      <div
        className="!w-[152px] !h-[126px] flex flex-col gap-1 p-1 cursor-pointer hover:outline hover:outline-2 hover:outline-common-primary100 hover:-outline-offset-2 !rounded-[3px]"
        style={{
          backgroundColor: selected ? themeColor.primary100 : "transparent",
        }}
        onClick={() => onClick()}
      >
        <div
          className="!w-[144px] !h-[94px] div-one-center"
          style={{
            background: `url(${require("../../assets/test/Polygon1.png")})`,
            backgroundSize: "cover",
          }}
        >
          <img src={img} alt="" />
        </div>

        <div className="flex items-center gap-1 text-sm text-common-white font-normal">
          <span>{icon}</span>
          <span className="truncate">{label}</span>
        </div>
      </div>
    );
  };

  const handleScroll = (event: React.WheelEvent<HTMLDivElement>) => {
    if (event.deltaY !== 0) {
      event.currentTarget.scrollLeft += event.deltaY;
      event.preventDefault();
    }
  };

  const getData = async () => {
    // @ts-ignore
    let res = await dispatch(
      // @ts-ignore
      getHistoryByUserAsync({ page: 0, size: 100 })
    ).unwrap();

    setData(res?.data?.data?.content_page ?? []);
  };

  useEffect(() => {
    if (bottomDrawerType === "History list") {
      getData();
    }
  }, [bottomDrawerType]);
  return (
    <div className="w-full h-full p-3">
      {/* {renderHeaderAction()} */}
      <div className="w-full flex flex-col gap-2 mt-4">
        <div className="flex justify-between items-center gap-1 text-sm font-semibold text-common-white">
          <span className="flex items-center gap-1">
            <img src={LayerSvg} />
            {t("Histories")}
          </span>
          {renderHeaderAction()}
        </div>

        <div
          className="w-full flex items-center gap-5 overflow-y-hidden pb-3 horizontal-scroll-list"
          onWheel={handleScroll}
        >
          {data?.map((f: any, index: number) => {
            const svgImageUrl = f.request?.aois?.[0]
              ? convertGeoJSONToSVG(f.request?.aois?.[0], 50, 50)
              : "";

            return (
              <div className="polygon-item" key={index}>
                <PolygonItem
                  selected={selectedPolygon?.id === f?.id}
                  key={index}
                  img={svgImageUrl}
                  icon={
                    <img
                      src={f.request?.aois?.[0] ? IconIntersect : IconInfoSvg}
                    />
                  }
                  label={moment(f?.created_at).format("HH:mm:ss DD/MM/YYYY")}
                  onClick={() => {}}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default DrawerHistorySearch;
