import { useNavigate } from "react-router-dom";
import CardManagementComp from "../../components/Commons/CardManagement";
import ContentLayoutManagement from "../../layouts/ContentLayoutManagement";
import useGetBasemap from "../../services/basemap/hooks/useGetBasemap";
import { baseRoutes } from "../../router/RouterPage";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { changeDataBreadcrumb } from "../../store/reducers/layoutSlice";
import { useTranslation } from "react-i18next";

const BasemapSetting = () => {
  //! state
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { data: dataBaseMap } = useGetBasemap();
  const navigate = useNavigate();
  //! function
  const itemCard = {
    id: dataBaseMap?.data?.id,
    title: t("BaseMap setting"),
    des:
      dataBaseMap?.data && dataBaseMap?.data?.configs.length > 0
        ? `${dataBaseMap?.data?.configs.length} ${t("BaseMap")}`
        : t("No basemap"),
  };
  //! effect
  useEffect(() => {
    dispatch(changeDataBreadcrumb([{ title: t("BaseMap") }]));
    return () => {
      dispatch(changeDataBreadcrumb([]));
    };
  }, []);
  //! render
  return (
    <ContentLayoutManagement>
      <div className="relative w-full h-full p-6 !pr-[44px] bg-content-default">
        <CardManagementComp
          data={itemCard}
          onClick={() => {
            navigate(`${baseRoutes.basemapSetting}/setting`);
          }}
        />
      </div>
    </ContentLayoutManagement>
  );
};

export default BasemapSetting;
