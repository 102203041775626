import { Divider } from "antd";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import PolygonSvg from "../../assets/svg/edit-polygon.svg";
import FilterSvg from "../../assets/svg/new-filter.svg";
import SettingSvg from "../../assets/svg/setting.svg";
import { CoordinateDisplayType, MouseCoordinateElementIds } from "../../enums";
import {
  changeBottomDrawerType,
  changeLeftDrawerType,
  changeShowBottomDrawer,
} from "../../store/reducers/drawerSlice";
import {
  ChangeActionActiveFooter,
  ChangeActionActiveRight,
  TActionActiveFooter,
  TActionActiveRight,
} from "../../store/reducers/statusAction";
import { RootState } from "../../store/store";
import { LeftDrawerType } from "../../types/common";
import ButtonIconComp from "./components/ButtonIcon";
import { useTranslation } from "react-i18next";
import LayerSvg from "../../assets/svg/layer.svg";

interface IProps {
  onShowPolygonTemplateList: () => void;
  showFilter?: boolean;
}

export default function Footer({
  onShowPolygonTemplateList,
  showFilter,
}: IProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { leftDrawerType } = useSelector(
    (state: RootState) => state.drawerSlice
  );

  const actionActiveFooter = useSelector(
    (state: RootState) => state.statusAction.actionActiveFooter
  );
  const actionActiveRight = useSelector(
    (state: RootState) => state.statusAction.actionActiveRight
  );

  const isSearching = useSelector(
    (state: RootState) => state.search.isSearching
  );
  const onChangeLeftDrawerType = (type: any) => {
    if (leftDrawerType === type && !isSearching) {
      dispatch(changeLeftDrawerType(""));
      return;
    } else if (leftDrawerType === type && isSearching) {
      dispatch(changeLeftDrawerType("Search results"));
      return;
    }
    dispatch(changeLeftDrawerType(type));
  };

  const CheckActionActive = (actionName: any) => {
    const styleActive =
      " !bg-common-activeAction hover:!bg-common-activeAction !text-black ";
    if (actionName === actionActiveRight || actionName === actionActiveFooter) {
      return styleActive;
    }
    return "";
  };

  let bottomDrawerType = useSelector(
    (state: RootState) => state.drawerSlice.bottomDrawerType
  );

  const { colorConfig } = useSelector(
    (state: RootState) => state.appCommonSlice
  );

  //! render
  return (
    <div
      className={`h-14 py-2  flex justify-between items-center pr-[11px]`}
      style={{ background: colorConfig.mainColor }}
    >
      <div className="flex gap-2 ml-3">
        <div className="flex items-center gap-2 text-xs text-common-white font-normal">
          {t("footer.lng")}: <span id={MouseCoordinateElementIds.LNG_ID}></span>
          {t("footer.lat")}: <span id={MouseCoordinateElementIds.LAT_ID}></span>
        </div>
      </div>

      <div className="flex gap-2 justify-end">
        <ButtonIconComp
          item={{
            description: t("footer.myPolygon"),
            icon: PolygonSvg,
            title: t("footer.myPolygon"),
          }}
          className={`!flex !items-center !text-xs !rounded-[3px] !px-3 !min-w-[30px] bg-[#FFFFFF1A] !h-7 ${CheckActionActive(
            "Polygon"
          )}`}
          onClick={() => {
            // @ts-ignore
            dispatch(ChangeActionActiveFooter("Polygon"));
            onShowPolygonTemplateList();
          }}
        />

        <ButtonIconComp
          item={{
            description: t("polygonType.histories"),
            icon: LayerSvg,
            title: t("polygonType.histories"),
          }}
          className={`!flex !items-center !text-xs !rounded-[3px] !px-3 !min-w-[30px] bg-[#FFFFFF1A] !h-7 ${CheckActionActive(
            "histories"
          )}`}
          onClick={() => {
            // @ts-ignore
            dispatch(ChangeActionActiveFooter("histories"));

            if (bottomDrawerType === "History list") {
              dispatch(changeShowBottomDrawer(false));
              dispatch(changeBottomDrawerType(""));
              return;
            }
            dispatch(changeShowBottomDrawer(true));
            dispatch(changeBottomDrawerType("History list"));
          }}
        />
        <Divider
          type="vertical"
          style={{ background: "#353B4F", margin: "3px 0", height: "24px" }}
        />

        {showFilter && (
          <ButtonIconComp
            item={{
              description: t("footer.filter"),

              icon: FilterSvg,
              // title: "Apply",
            }}
            className={`!text-xs !rounded-[3px] bg-[#FFFFFF1A] !h-7 ${CheckActionActive(
              "Filter"
            )}`}
            onClick={() => {
              dispatch(ChangeActionActiveRight("Filter"));
              onChangeLeftDrawerType("Filter");
            }}
          />
        )}

        <ButtonIconComp
          item={{
            description: t("footer.setting"),

            icon: SettingSvg,
            // title: "Apply",
          }}
          className={`!text-xs !rounded-[3px] bg-[#FFFFFF1A] !h-7 ${CheckActionActive(
            "Setting"
          )}`}
          onClick={() => {
            dispatch(ChangeActionActiveRight("Setting"));
            onChangeLeftDrawerType("Setting basemap");
          }}
        />
      </div>
    </div>
  );
}
