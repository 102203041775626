import { Collapse } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CheckRoleAccessControlAdmin } from "../../../helpers/roleAccessControl";
import { getProductBundleByItemTypeAsync } from "../../../store/reducers/cartSlice";
import CommonComponents from "../../Commons";
import CardItemConfirmComp from "../CardItemConfirm";
import { onMouseEnterItem, onMouseLeaveItem } from "../../MapTasking/ultis";
import { RootState } from "../../../store/store";
import { useTranslation } from "react-i18next";

//! step only for booking image planet

const Step3 = (props: any) => {
  const [productBundles, setProductBundles] = useState<{
    [key: string]: any[];
  }>({});
  const [itemTypes, setItemTypes] = useState<any>([]);
  const [activeKey, setActiveKey] = useState<any>([]);
  const [imageSelected, setImagesSelected] = useState<any>([]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (props.form && itemTypes && props?.initialValues) {
      const products = Object.keys(itemTypes).reduce(
        (acc: any, itemType: any) => {
          let productBundleValue = undefined;
          props?.initialValues?.forEach((initialData: any) => {
            const item_type = initialData?.item_type;
            const product_bundle = initialData?.product_bundle;

            if (item_type === itemType) {
              productBundleValue = product_bundle;
            }
          });

          acc[itemType] = {
            productBundle: productBundleValue,
          };

          return acc;
        },
        {}
      );

      props.form.setFieldsValue({
        dataPlanet: {
          products,
        },
      });
    }
  }, [itemTypes, props.form]);

  useEffect(() => {
    const a = props?.dataImages?.map((itemM: any) => itemM?.id);
    setImagesSelected(a);
  }, [props?.dataImages]);

  useEffect(() => {
    const fetchAllProductBundles = async () => {
      const itemsByType = props?.dataImages?.reduce((acc: any, item: any) => {
        const itemType = item?.feature?.collection;
        if (itemType) {
          if (!acc[itemType]) acc[itemType] = [];
          acc[itemType].push(item);
        }
        return acc;
      }, {});

      const bundles: { [key: string]: any[] } = {};
      const itemTypes = Object.keys(itemsByType ?? {});

      for (const itemType of itemTypes) {
        // @ts-ignore
        const data = await dispatch(
          // @ts-ignore
          getProductBundleByItemTypeAsync({ itemType })
        ).unwrap();
        bundles[itemType] = data?.data?.data ?? [];
        if (!props?.initialValues) {
          props?.form.setFieldValue(
            ["dataPlanet", "products", itemType, "productBundle"],
            data?.data?.data?.[0]?.name
          );
        }
      }

      setProductBundles(bundles);
      setItemTypes(itemsByType);
      setActiveKey(itemTypes.map((_, index) => `${index}`));
    };
    fetchAllProductBundles();
  }, [props?.dataImages, props?.form]);

  const handleImageSelect = (itemId: any) => {
    setImagesSelected((prevSelected: any) =>
      prevSelected.includes(itemId)
        ? prevSelected.filter((id: any) => id !== itemId)
        : [...prevSelected, itemId]
    );
  };

  const { t } = useTranslation();

  return (
    <div className="div-collapse-in-collapse">
      <Collapse
        className="bg-[#2C3243]"
        bordered={false}
        expandIconPosition="end"
        activeKey={activeKey}
        items={
          Object.keys(itemTypes)?.length > 0
            ? Object.keys(itemTypes)?.map((itemType, index) => ({
                key: index,
                label: (
                  <span>
                    {itemType} ({itemTypes[itemType]?.length} images)
                  </span>
                ),
                children: (
                  <div key={itemType}>
                    <CommonComponents.FormFieldUI
                      name={[
                        "dataPlanet",
                        "products",
                        itemType,
                        "productBundle",
                      ]}
                      rules={[
                        { required: true, message: t("Please input here!") },
                      ]}
                      // initialValue={productBundles?.[itemType]?.[0]?.name ?? ""}
                      component={
                        <CommonComponents.SelectUI
                          label="Product Bundle"
                          placeholder="Product Bundle"
                          data={productBundles[itemType] || []}
                          required={true}
                          disabled={props?.readOnly}
                        />
                      }
                    />
                    {itemTypes[itemType]?.map((item: any) => (
                      <CardItemConfirmComp
                        style={{ background: "#3C4152" }}
                        key={item.id}
                        item={item}
                        selected={imageSelected.includes(item.id)}
                        onMouseLeaveItem={() =>
                          onMouseLeaveItem(
                            item?.feature,
                            props.map,
                            props.sourceId
                          )
                        }
                        onMouseEnterItem={() =>
                          onMouseEnterItem(
                            item?.feature,
                            props.map,
                            props.sourceId
                          )
                        }
                        onClick={() => {
                          props?.onClick(item?.id);
                          handleImageSelect(item.id);
                        }}
                      />
                    ))}

                    <div className="hidden">
                      <CommonComponents.FormFieldUI
                        name={["dataPlanet", "products", itemType, "imageId"]}
                        initialValue={itemTypes[itemType]?.map((itemM: any) => {
                          let a =
                            itemM?.feature?.properties[
                              `${itemM?.provider_name?.toLowerCase()}:id`
                            ] ??
                            itemM?.feature?.properties[
                              `${itemM?.provider?.name?.toLowerCase()}:id`
                            ];
                          return a;
                        })}
                        component={null}
                      />
                    </div>
                  </div>
                ),
              }))
            : []
        }
      />
    </div>
  );
};

export default Step3;
