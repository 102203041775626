import React, { useState } from "react";
import bbox from "@turf/bbox";
import area from "@turf/area";
import length from "@turf/length";
import { decimalToDMS } from "./ultis";
import CommonComponents from "../Commons";
import { Divider, Select } from "antd";

const PolygonInfo = (props: any) => {
  const [format, setFormat] = useState("lat, lng minutes");

  if (!props?.selectedPolygon?.id) {
    return <p>No polygon data available.</p>;
  }

  const outArea = area(props?.selectedPolygon);
  const perimeter = length(props?.selectedPolygon, { units: "kilometers" });

  let output;
  if (outArea > 10000) {
    output = Math.round((outArea / 1000000) * 100) / 100 + " km²";
  } else {
    output = Math.round(outArea * 100) / 100 + " m²";
  }

  const handleFormatChange = (value: string) => {
    setFormat(value);
  };

  const convertCoordinates = (item: any) => {
    if (format === "lat, lng degrees") {
      return `${item?.[0]}, ${item?.[1]}`; // Định dạng thập phân
    } else {
      const latitudeDMS = decimalToDMS(item?.[1], "N", "S");
      const longitudeDMS = decimalToDMS(item?.[0], "E", "W");
      return `${longitudeDMS}, ${latitudeDMS}`; // Định dạng độ phút giây
    }
  };

  return (
    <div className="text-white">
      <div className="flex gap-2 justify-between items-center">
        <p>{props?.t("Format")}</p>
        <Select
          className="mb-2 !bg-[#3C4152] border-none rounded"
          defaultValue="lat, lng minutes"
          onChange={handleFormatChange}
          style={{ width: 200 }}
          options={[
            { value: "lat, lng degrees", label: "lat, lng degrees" },
            { value: "lat, lng minutes", label: "lat, lng minutes" },
          ]}
        />
      </div>
      <Divider style={{ background: "#545C77", margin: "10px 0" }} />
      {props?.selectedPolygon?.geometry?.coordinates?.[0]?.map(
        (item: any, index: any) => {
          const val = convertCoordinates(item);
          return (
            <div key={index} className="flex justify-between p-2">
              <p className="mb-1 text-des">
                {props?.t("Coordinate")} {index + 1}
              </p>
              <span>{val}</span>
            </div>
          );
        }
      )}
      <div key={"Area"} className="flex justify-between p-2">
        <p className="mb-1 text-des">{props?.t("Area")}</p>
        <span>{output}</span>
      </div>
      <div key={"Perimeter"} className="flex justify-between p-2">
        <p className="mb-1 text-des">{props?.t("Perimeter")}</p>
        <span>{Math.round(perimeter * 100) / 100} km</span>
      </div>
    </div>
  );
};

export default PolygonInfo;
