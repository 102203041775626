import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import OrderTaskingForm from "../../../components/BookingImage/OrderTasking";
import CommonComponents from "../../../components/Commons";
import { ERoleAccessControl, ESttausCode } from "../../../enums";
import { showError, showSuccess } from "../../../helpers/toast";
import ContentLayoutManagement from "../../../layouts/ContentLayoutManagement";
import { resetAllDrawer } from "../../../store/reducers/drawerSlice";
import {
  changeDataBreadcrumb,
  changeLayoutName,
} from "../../../store/reducers/layoutSlice";
import {
  getOrderByIdAsync,
  getOrderByIdUserAsync,
  getPriorityAsync,
  getTaskingSchemaAsync,
  updateArchiveOrderAsync,
  updateArchiveOrderByAdminAsync,
} from "../../../store/reducers/orderSlice";
import { RootState } from "../../../store/store";
import { convertToKeyValueList } from "./ultis";
import { CheckRoleAccessControlAdmin } from "../../../helpers/roleAccessControl";
import BackSVG from "../../../assets/svg/back-arrow.svg";
import { useTranslation } from "react-i18next";

const EditOrderTaskingPage = () => {
  const { idOrder } = useParams();
  const dispatch = useDispatch();
  const formRef = useRef<any>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const roleAdmin = CheckRoleAccessControlAdmin();

  const arrAllProviders = useSelector(
    (state: RootState) => state.mapTasking.arrAllProviders
  );

  const [dataSchemaOnePlan, setDataSchemaOnePlan] = useState<any>({});
  const [data, setData] = useState<any>(undefined);
  const [dataSchema, setDataSchema] = useState<any>({});
  const [dataPriority, setDataPriority] = useState<any>([]);
  const [itemStep, setItemStep] = useState<
    { title: string; status?: "wait" | "process" | "finish" | "error" }[]
  >([
    {
      title: t("Information order"),
      status: "finish",
    },
    {
      title: t("Setting"),
      status: "finish",
    },

    {
      title: t("Confirm"),
      status: "finish",
    },
  ]);
  const [fieldRequireStep2, setFieldRequireStep2] = useState([]);

  const handleGetPriority = async () => {
    // @ts-ignore
    const res = await dispatch(getPriorityAsync()).unwrap();

    setDataPriority(
      res?.data?.data?.content_page?.filter(
        (itemF: any) => itemF?.key === "PRIORITY_LEVEL"
      ) ?? []
    );
  };

  const getSchema = async () => {
    let nameProvider = arrAllProviders?.filter(
      (itemF: any) =>
        itemF?.id === location?.state?.item?.order_details?.[0]?.provider?.id
    )?.[0]?.name;
    // @ts-ignore
    let res = await dispatch(
      // @ts-ignore
      getTaskingSchemaAsync({
        // idProvider: arrAllProviders?.filter(
        //   (itemF: any) => itemF?.name.toLowerCase() === "capella" // sau sua lai thanh provider user chon
        // )?.[0]?.id,
        idProvider: location?.state?.item?.order_details?.[0]?.provider?.id,
        type: "order_tasking",
      })
    ).unwrap();

    if (
      location?.state?.item?.mission?.includes("ONEPLAN") &&
      nameProvider.toLowerCase() === "oneatlas"
    ) {
      // @ts-ignore
      let resSchemaOnePlan = await dispatch(
        // @ts-ignore
        getTaskingSchemaAsync({
          idProvider: location?.state?.item?.order_details?.[0]?.provider?.id,
          type: "ORDER_TASKING_ONEATLAS_ONE_PLAN",
        })
      ).unwrap();

      setDataSchemaOnePlan(
        resSchemaOnePlan?.data?.data?.ui_schema?.properties ?? {}
      );
    }
    setDataSchema(res?.data?.data ?? {});
  };

  const getData = async () => {
    let res;

    if (roleAdmin?.includes(ERoleAccessControl.user)) {
      // @ts-ignore
      res = await dispatch(
        // @ts-ignore
        getOrderByIdUserAsync(idOrder)
      ).unwrap();
    } else {
      // @ts-ignore
      res = await dispatch(
        // @ts-ignore
        getOrderByIdAsync(idOrder)
      ).unwrap();
    }

    setData(res?.data?.data);
  };

  const handleUpdate = async () => {
    let values = formRef.current.getFieldsValue();
    const errorFields = formRef.current.getFieldsError();
    let statusPassStep = true;
    errorFields.forEach((element: any) => {
      if (element.errors.length > 0) {
        statusPassStep = false;
        return;
      }
    });

    const Step1Status =
      !!values?.name && !!values?.priority_id && !!values?.reason;

    let Step2Status = fieldRequireStep2.every(
      (itemF: any) => !!values?.properties?.[itemF]
    );
    //! check form onatlas
    if (data?.order_details?.[0]?.provider?.name.toLowerCase() === "oneatlas") {
      Step2Status = fieldRequireStep2.every(
        (itemF: any) => !!values?.optionsPerProductType?.options?.[itemF]
      );

      if (
        values?.mission === "ONENOW/SPOT" &&
        (!values?.hiddenAcquisition || values?.hiddenAcquisition === "123")
      ) {
        Step2Status = false;
      }
    }
    const itemStepClone = [...itemStep];
    itemStepClone[0].status = Step1Status ? "process" : "error";
    itemStepClone[1].status = Step2Status ? "process" : "error";

    setItemStep(itemStepClone);
    //! if field required not yet filled
    if (!Step1Status || !Step2Status) {
      showError(t("Please fill in all required fields"));

      // setLoadingSubmit(false);
      return;
    }

    let payload =
      data?.order_details?.[0]?.provider?.name.toLowerCase() === "capella"
        ? {
            name: values?.name,
            priority_id: values?.priority_id,
            description: values?.description ?? undefined,
            reason: values?.reason ?? undefined,
            order_details: [
              {
                order_detai_id: data?.order_details?.[0]?.id,
                request_order: {
                  geometry: data?.order_details?.[0]?.request_order?.geometry,
                  properties: {
                    ...values?.properties,
                    collectConstraints: {
                      ...values?.properties?.collectConstraints,
                      orbitalPlanes: [
                        Number(
                          values?.properties?.collectConstraints?.orbitalPlanes
                            ?.orbitalPlanes
                        ),
                      ], // hot-fix
                    },
                  },
                },
              },
            ],
          }
        : data?.order_details?.[0]?.provider?.name.toLowerCase() === "oneatlas"
        ? {
            name: values?.name,
            priority_id: values?.priority_id,
            description: values?.description ?? undefined,
            reason: values?.reason ?? undefined,
            mission: values?.mission,
            order_details: [
              {
                order_detai_id: data?.order_details?.[0]?.id,
                request_order: {
                  ...data?.order_details?.[0]?.request_order,
                  optionsPerProductType: [
                    {
                      options: convertToKeyValueList(
                        values?.optionsPerProductType?.options
                      ),
                    },
                  ],
                },
              },
            ],
          }
        : {};

    let res;

    if (roleAdmin?.includes(ERoleAccessControl.user)) {
      // @ts-ignore
      res = await dispatch(
        // @ts-ignore
        updateArchiveOrderAsync({ ...payload, orderId: idOrder })
      ).unwrap();
    } else {
      // @ts-ignore
      res = await dispatch(
        // @ts-ignore
        updateArchiveOrderByAdminAsync({ ...payload, orderId: idOrder })
      ).unwrap();
    }

    if (res?.status === ESttausCode.OK || res?.status === ESttausCode.CREATED) {
      showSuccess(t("Update successfully"));

      navigate(`/order/management-tasking/${idOrder}`);
    } else {
      showError(t("Update failed"));
    }
  };

  useEffect(() => {
    getData();
  }, [idOrder]);

  useEffect(() => {
    if (
      location?.state?.item?.order_details?.[0]?.provider?.id &&
      arrAllProviders?.length > 0
    ) {
      getSchema();
    }
  }, [
    location?.state?.item?.order_details?.[0]?.provider?.id,
    arrAllProviders,
  ]);

  useEffect(() => {
    handleGetPriority();
    dispatch(changeLayoutName("/update-tasking-order"));
    dispatch(
      changeDataBreadcrumb([
        {
          title: t("Tasking Orders"),
          path: "/order/management-tasking",
        },
        {
          title: location?.state?.item?.name,
          path: `/${location?.state?.item?.id}`,
        },
        {
          title: t("Edit"),
        },
      ])
    );
    dispatch(resetAllDrawer());
    return () => {
      dispatch(changeLayoutName(""));
      dispatch(changeDataBreadcrumb([]));
    };
  }, []);
  return (
    <ContentLayoutManagement
      header={
        <div className="flex !justify-between pr-6 w-full">
          <CommonComponents.ButtonUI
            commonType="standard"
            className="btn-cancel hover:bg-[#3E4353] !text-xs !h-7 "
            onClick={() => navigate("/order/management-tasking")}
            icon={<img src={BackSVG} alt="BackSVG" />}
          ></CommonComponents.ButtonUI>

          <CommonComponents.ButtonUI
            className="!text-xs !h-[27px] btn-save"
            onClick={() => handleUpdate()}
          >
            {t("Save")}
          </CommonComponents.ButtonUI>
        </div>
      }
    >
      <div className="div-form-default !justify-start !pt-10">
        <OrderTaskingForm
          geometry={
            data?.order_details?.[0]?.request_order?.geometry ??
            data?.order_details?.[0]?.request_order?.aoi?.[0]?.geometry
          }
          initialValues={data}
          dataSchema={dataSchema}
          dataPriority={dataPriority}
          formRef={formRef}
          type="edit"
          itemStep={itemStep}
          setItemStep={setItemStep}
          setFieldRequireStep2={setFieldRequireStep2}
          dataSchemaOnePlan={dataSchemaOnePlan}
          fieldRequireStep2={fieldRequireStep2}
        />
      </div>
    </ContentLayoutManagement>
  );
};

export default EditOrderTaskingPage;
