import { Editor } from "@monaco-editor/react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import CommonComponents from "../../../components/Commons";
import ContentLayoutManagement from "../../../layouts/ContentLayoutManagement";
import { setLoadingGlobal } from "../../../store/reducers/appCommonSlice";
// import * as monaco from "monaco-editor/esm/vs/editor/editor.api";
import { ESttausCode } from "../../../enums";
import { getErrorMsg } from "../../../helpers/common";
import { showError, showSuccess } from "../../../helpers/toast";
import { changeDataBreadcrumb } from "../../../store/reducers/layoutSlice";
import { updateBlockCodeByProviderAsync } from "../../../store/reducers/providerSlice";
import { RootState } from "../../../store/store";
import BackSvg from "../../../assets/svg/back-arrow.svg";
import { useTranslation } from "react-i18next";

const customTheme = {
  base: "vs-dark",
  inherit: true,
  rules: [
    {
      token: "comment",
      foreground: "#5d7988",
      fontStyle: "italic",
    },
    { token: "constant", foreground: "#e06c75" },
  ],
  colors: {
    "editor.background": "#21252b",
  },
};

const SettingCodeBlockProviderPage = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const data = location.state.item;
  const navigate = useNavigate();
  const arrAllProviders = useSelector(
    (state: RootState) => state.mapTasking.allProvider
  );
  const [value, setValue] = useState<any>(undefined);

  const [flag, setFlag] = useState<boolean>(true);

  // useEffect(() => {
  //   // @ts-ignore
  //   // monaco.editor.defineTheme("my-custom-theme", customTheme);
  //   // monaco.editor.setTheme("my-custom-theme");
  // }, []);

  useEffect(() => {
    dispatch(
      changeDataBreadcrumb([
        {
          title: `Providers ${
            arrAllProviders?.filter(
              (itemF: any) => itemF?.id === data?.provider?.id
            )?.[0]?.name
          }`,
          path: "provider-branch",
        },
        {
          title: "List block code",
          path: `${data?.provider?.id}/list-block-code`,
        },
        {
          title: `Edit ${data?.key}`,
        },
      ])
    );
    return () => {
      dispatch(changeDataBreadcrumb([]));
    };
  }, []);

  useEffect(() => {
    let encodedString = data?.code_runtime;

    const decodedString = atob(encodedString);

    setValue(decodedString);
  }, [data]);

  const handleEditorChange = (value: any, event: any) => {
    setValue(value);
    setFlag(false);
  };

  const { t } = useTranslation();

  const handleEdit = async () => {
    try {
      dispatch(setLoadingGlobal(true));
      const base64String = btoa(unescape(encodeURIComponent(value)));

      let { id, code_runtime, provider, ...newData } = data;

      let pay = {
        id: data?.id,
        val: {
          ...newData,
          code_runtime: base64String,
          provider_id: data?.provider?.id,
        },
      };

      // @ts-ignore
      let res = await dispatch(
        // @ts-ignore
        updateBlockCodeByProviderAsync(pay)
      ).unwrap();

      if (res.status === ESttausCode.OK || res.status === ESttausCode.CREATED) {
        showSuccess(t("Edit block code successfully"));

        navigate(`/provider-branch/${data?.provider?.id}/list-block-code`);
      }

      dispatch(setLoadingGlobal(false));
    } catch (error) {
      showError(t(getErrorMsg(error)));

      dispatch(setLoadingGlobal(false));
    }
  };

  return (
    <ContentLayoutManagement
      header={
        <div className="!pr-2 w-full">
          <div className="flex justify-between  w-full">
            <CommonComponents.ButtonUI
              commonType="default"
              className="btn-cancel "
              icon={<img src={BackSvg} alt="BackSvg" />}
              onClick={() => navigate(-1)}
            ></CommonComponents.ButtonUI>

            <CommonComponents.ButtonUI
              onClick={handleEdit}
              style={{ background: "#60CDFF" }}
              className="h-[28px] !bg-[#60CDFF] btn-save"
              disabled={flag}
            >
              Save
            </CommonComponents.ButtonUI>
          </div>
        </div>
      }
    >
      <div className="relative w-full h-full p-6 bg-content-default">
        <Editor
          height="80vh"
          defaultLanguage="python"
          value={value}
          onChange={handleEditorChange}
          onMount={() => dispatch(setLoadingGlobal(false))}
          theme="my-custom-theme"
        />
      </div>
    </ContentLayoutManagement>
  );
};

export default SettingCodeBlockProviderPage;
