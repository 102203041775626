import React, { ReactNode, useCallback } from "react";
import CommonIcons from "../../../assets/icons";
import CommonComponents from "../../Commons";
import { ConvertTimeStamp } from "../../../helpers/common";
import { Collapse } from "antd";
import { useTranslation } from "react-i18next";

function InfoItem({
  label,
  value,
  icon,
}: {
  label: string;
  value?: string | number;
  icon?: ReactNode;
}) {
  const _label = (
    <div className="flex items-center gap-1 text-[#959595] text-xs">
      {icon}
      <span>{label}</span>
    </div>
  );

  return (
    <CommonComponents.InfoItemUI
      label={_label}
      value={value}
      valueClassName="text-sm text-white pl-5"
      orientation="vertical"
    />
  );
}

interface IProps {
  item?: any;
}

export default function Metadata({ item }: IProps) {
  const { t } = useTranslation();
  const ParseDataCollapse = useCallback(() => {
    if (!item?.properties) {
      return;
    }
    const temp: any = [];
    for (const [key, value] of Object.entries(item?.properties)) {
      if (key.includes(":")) {
        if (!temp.includes(key.split(":")[0])) {
          temp.push(key.split(":")[0]);
        }
      }
    }
    return temp;
  }, [item?.properties]);

  const renderCollapse = useCallback(() => {
    return ParseDataCollapse()?.map((collapse: any, index: any) => {
      const data = [];
      if (!item?.properties) {
        return;
      }
      for (const [key, value] of Object.entries(item?.properties)) {
        if (key.includes(":") && key.split(":")[0] === collapse) {
          data.push({
            [key.split(":")[1]]: value,
          });
        }
      }

      return (
        <Collapse
          key={index}
          bordered={false}
          expandIconPosition="end"
          size="small"
          items={[
            {
              key: "1",
              label: `${collapse}`,
              children: (
                <div className="border-t border-common-line pt-2 flex flex-col gap-2">
                  {data?.map((element, index) => {
                    return (
                      <div key={index} className="break-all flex items-center ">
                        <span className="text-[#959595] text-xs basis-1/3">
                          {`${Object.keys(element)}:`}
                        </span>
                        <span className="text-white text-sm basis-2/3 text-right">{`${Object.values(
                          element
                        )}`}</span>
                      </div>
                    );
                  })}

                  {/* <div>
                    <span className="text-[#959595] text-xs">cloud_cover:</span>{" "}
                    <span className="text-white text-sm">
                      {item?.properties
                        ? Number(item.properties["eo:cloud_cover"]).toFixed(2)
                        : ""}
                    </span>
                  </div> */}
                </div>
              ),
            },
          ]}
        />
      );
    });
  }, [item?.properties]);
  return (
    <div className="grid grid-cols-2 my-5 gap-4">
      <InfoItem
        label={t("Datetime")}
        icon={<CommonIcons.ImageInfoDatetimeIcon />}
        value={ConvertTimeStamp(item?.properties?.datetime, "DD/MM/YYYY")}
      />
      <InfoItem
        label={t("Created")}
        icon={<CommonIcons.ImageInfoCreatedIcon />}
        value={ConvertTimeStamp(item?.properties?.created, "DD/MM/YYYY")}
      />
      <InfoItem
        label={t("Description")}
        icon={<CommonIcons.ImageInfoPlatformIcon />}
        value={item?.properties?.description}
      />

      <InfoItem
        label="GSD"
        icon={<CommonIcons.ImageInfoPlatformIcon />}
        value={item?.properties?.gsd}
      />
      <InfoItem
        label={t("Platform")}
        icon={<CommonIcons.ImageInfoPlatformIcon />}
        value={item?.properties?.platform}
      />
      <InfoItem
        label={t("Instruments")}
        icon={<CommonIcons.ImageInfoInstrumentsIcon />}
        value={item?.properties?.instruments ?? ""}
      />

      <div className="flex flex-col gap-4 col-span-2">{renderCollapse()}</div>
    </div>
  );
}
