import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export type TActionActiveRight = "Setting" | "Filter" | "";
export type TActionActiveFooter = "Polygon" | "";
export type TActionActiveHeader = "";

interface IStatusActionState {
  actionActiveRight: TActionActiveRight;
  actionActiveHeader: TActionActiveHeader;
  actionActiveFooter: any;
}

const initialState: IStatusActionState = {
  actionActiveRight: "",
  actionActiveFooter: "",
  actionActiveHeader: "",
};

export const projectSlice = createSlice({
  name: "statusAction",
  initialState,
  reducers: {
    ChangeActionActiveFooter: (state, action: any) => {
      if (state.actionActiveFooter === action.payload) {
        state.actionActiveFooter = "";
        return;
      }
      state.actionActiveFooter = action?.payload;
    },

    ChangeActionActiveHeader: (
      state,
      action: PayloadAction<TActionActiveHeader>
    ) => {
      if (state.actionActiveHeader === action.payload) {
        state.actionActiveHeader = "";
        return;
      }
      state.actionActiveHeader = action?.payload;
    },

    ChangeActionActiveRight: (
      state,
      action: PayloadAction<TActionActiveRight>
    ) => {
      // if (state.actionActiveRight === action.payload) {
      //   state.actionActiveRight = "";
      //   return;
      // }
      state.actionActiveRight = action?.payload;
    },
  },
});

export const {
  ChangeActionActiveFooter,
  ChangeActionActiveHeader,
  ChangeActionActiveRight,
} = projectSlice.actions;

export default projectSlice.reducer;
