import { Collapse, CollapseProps, Form, Select } from "antd";
import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { v4 as uuidv4 } from "uuid";
import CommonIcons from "../../../assets/icons";
import AngleSvg from "../../../assets/svg/angle.svg";
import FromToSvg from "../../../assets/svg/from-to.svg";
import IconSvg from "../../../assets/svg/icon.svg";
import CommonComponents from "../../../components/Commons";
import MapTaskingPlugin from "../../../components/MapTasking/Map";
import PolygonInfo from "../../../components/MapTasking/PolygonInfo";
import { ERoleAccessControl, ESttausCode } from "../../../enums";
import { capitalizeFirstLetter, getErrorMsg } from "../../../helpers/common";
import { showError, showSuccess } from "../../../helpers/toast";
import ContentLayoutManagement from "../../../layouts/ContentLayoutManagement";
import { resetAllDrawer } from "../../../store/reducers/drawerSlice";
import {
  changeDataBreadcrumb,
  changeLayoutName,
} from "../../../store/reducers/layoutSlice";
import {
  confirmOrderTaskingAdminAsync,
  getDetailOrderTaskingByAdminAsync,
  getProductionAsync,
  getSegmentsByIdOrderAsync,
} from "../../../store/reducers/orderSlice";
import { RootState } from "../../../store/store";
import { RenderHeader, renderInfoOrder } from "./ultis";
import { useTranslation } from "react-i18next";
import { CheckRoleAccessControlAdmin } from "../../../helpers/roleAccessControl";

const UpdateTaskingOrderAdminPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();
  const [form] = Form.useForm();

  const [data, setData] = useState<any>({});
  const [dataSegments, setDataSegments] = useState<any>([]);
  const [heightSelect, setHeightSelect] = useState<any>("32px");
  const [dataProduction, setDataProduction] = useState<any>([]);

  const [itemsPolygonPoint, setItemsPolygonPoint] = useState<any>([]);

  const [itemsInfoOder, setItemsInfoOder] = useState<any>([]);
  const [dataUser, setDataUser] = useState<any>({});
  const items: CollapseProps["items"] = [
    {
      key: "1",
      label: t("Buyer Information"),
      children: <BuyerInfomationTaskingOrderAdmin whoami={dataUser} t={t} />,
    },
  ];

  let publicConfig = useSelector(
    (state: RootState) => state.appCommonSlice.publicConfig
  );

  const getData = async () => {
    let res = // @ts-ignore
      await dispatch(getDetailOrderTaskingByAdminAsync(id)).unwrap();

    setHeightSelect(
      res?.data?.data?.options?.filter(
        (itemF: any) => itemF?.key === "acquisition"
      )?.[0]?.value
        ? "100px"
        : "32px"
    );

    getSegments({
      progTypeNames: res?.data?.data?.plan,
      missions: res?.data?.data?.sensor,
      acquisitionDate: {
        startDate: res?.data?.data?.start_date_request,
        endDate: res?.data?.data?.end_date_request,
      },
      optical: {
        acquisitionMode: "MONO",
        maxCloudCover: 25,
        maxIncidenceAngle: 30,
      },
      aoi: res?.data?.data?.aoi,
    });

    axios
      .get(
        `${publicConfig?.REACT_APP_API_KARATOS}/oa/admin/identities/${res?.data?.data?.user_id}`,
        {
          withCredentials: true,
        }
      )
      .then((data) => {
        setDataUser(data.data);
      });

    dispatch(
      changeDataBreadcrumb([
        {
          title: (
            <span
              className="!cursor-pointer"
              onClick={() => navigate("/order/management-tasking")}
            >
              {t("Tasking Orders")}
            </span>
          ),
        },
        { title: res?.data?.data?.name },
      ])
    );

    let selectedPolygon = {
      id: uuidv4(),
      type: "Feature",
      geometry: res?.data?.data?.aoi?.geometry,
      properties: {},
      name: "Polygon 01",
    };

    setItemsPolygonPoint([
      {
        key: "1",
        label: t("Polygon points"),
        children: <PolygonInfo selectedPolygon={selectedPolygon} t={t} />,
      },
    ]);

    setItemsInfoOder([
      {
        key: "1",
        label: t("Order Information"),
        children: renderInfoOrder(res?.data?.data, null, null, t),
      },
    ]);

    setData(res?.data?.data ?? []);
  };

  const getSegments = async (pay: any) => {
    // @ts-ignore
    let res = await dispatch(getSegmentsByIdOrderAsync(pay)).unwrap();

    setDataSegments(
      res?.data?.data?.progCapacities?.[0]?.progTypes?.[0]?.segments ?? []
    );
  };

  const getProduction = async () => {
    // @ts-ignore
    let res = await dispatch(getProductionAsync()).unwrap();

    setDataProduction(
      res?.data?.data?.availableOptions?.filter(
        (item: any) => item?.category === "production_option"
      ) ?? []
    );
  };

  useEffect(() => {
    getProduction();
  }, []);

  useEffect(() => {
    getData();
  }, [id]);

  useEffect(() => {
    dispatch(changeLayoutName("/order/management-order/:id/update"));

    dispatch(resetAllDrawer());

    return () => {
      dispatch(changeLayoutName(""));
      dispatch(resetAllDrawer());
    };
  }, []);

  useEffect(() => {
    if (dataProduction && data?.options?.length > 0) {
      const newValues: any = {};

      dataProduction.forEach((item: any) => {
        const value = data?.options?.filter(
          (itemF: any) => itemF?.key === item?.name
        )?.[0]?.value;

        if (value) {
          newValues[item?.name] = value;
        }
      });

      form.setFieldsValue(newValues);
      form.setFieldsValue({
        acquisition:
          data?.options?.filter(
            (itemF: any) => itemF?.key === "acquisition"
          )?.[0]?.value || "",
      });
    }
  }, [data, dataProduction]);

  const renderSelectOption = (item: any) => {
    return (
      <div>
        <span className="flex gap-2">
          <img src={IconSvg} />
          {moment(item?.acquisitionStartDate).format(
            "DD/MM/YYYY HH:mm:ss"
          )}{" "}
          <img src={FromToSvg} />{" "}
          {moment(item?.acquisitionEndDate).format("DD/MM/YYYY HH:mm:ss")}
        </span>

        <p className=" flex gap-2">
          <img src={AngleSvg} />
          <span className="text-des">{t("Incidence angle")}:</span>{" "}
          {item?.incidenceAngle}° - {item?.maxIncidenceAngle}°
        </p>
        <p>
          <span className="text-des">{t("Order deadline")}:</span>{" "}
          {moment(item?.orderDeadline).format("DD/MM/YYYY HH:mm:ss")}
          {/* {item?.orderDeadline} */}
        </p>
      </div>
    );
  };

  const handleSubmit = async (values: any) => {
    try {
      let segmentSelected = dataSegments?.filter(
        (item: any) => item?.id === values?.acquisition
      )?.[0];

      const { acquisition, ...restValues } = values;

      const mergedValues = {
        ...restValues,
        ...segmentSelected,
      };

      const options = Object.entries(mergedValues).map(([key, value]) => ({
        key: key,
        value: value,
        label: key,
      }));

      let pay = {
        sensor: data?.sensor,
        plan: data?.plan,
        name: data?.name,
        description: data?.description,
        note: data?.note,
        options: options,
        project_id: data?.project?.id,
        start_date_request: data?.start_date_request,
        end_date_request: data?.end_date_request,
        id: id,
      };

      // @ts-ignore

      let res = await dispatch(confirmOrderTaskingAdminAsync(pay)).unwrap();
      if (
        res?.status === ESttausCode.OK ||
        res?.status === ESttausCode.CREATED
      ) {
        showSuccess(t("Approve successfully"));

        navigate("/order/management-tasking");
      }
    } catch (error) {
      showError(t(getErrorMsg(error)));
    }
  };
  const roleAdmin = CheckRoleAccessControlAdmin();

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={async (values: any) => {
        handleSubmit(values);
      }}
      // onValuesChange={handleValuesChange}
      style={{ color: "white" }}
      className="form-cus"
    >
      <ContentLayoutManagement
        header={
          <div className="flex gap-2">
            <CommonComponents.ButtonUI
              commonType="standard"
              className="btn-cancel hover:bg-[#3E4353] !text-xs !h-7"
              onClick={() => navigate("/order/management-tasking")}
            >
              {t("Cancel")}
            </CommonComponents.ButtonUI>
            <CommonComponents.ButtonUI
              className="!text-xs !h-[27px]"
              htmlType="submit"
              // onClick={() => handleSubmit()}
            >
              {t("Approve")}
            </CommonComponents.ButtonUI>
          </div>
        }
      >
        <div className="flex !flex-row w-full gap-3 h-full overflow-hidden card-archive-order div-page-default ">
          <div className="w-1/2 div-info-confirm-order text-white h-full overflow-auto pr-2">
            {RenderHeader({
              data: data,
              roleAdmin: roleAdmin?.includes(ERoleAccessControl.admin),
              t: t,
            })}
            <div className="div-collapse-pol-point-cus">
              <Collapse
                bordered={false}
                expandIconPosition="end"
                items={items}
                onChange={() => {}}
                className="!p-0"
                expandIcon={({ isActive }) => (
                  <div style={{ rotate: isActive ? "0deg" : "180deg" }}>
                    <CommonIcons.ChevronUpIcon />
                  </div>
                )}
              />
            </div>
            <div className="div-collapse-pol-point-cus">
              <Collapse
                bordered={false}
                expandIconPosition="end"
                items={itemsInfoOder}
                onChange={() => {}}
                className="!p-0"
                expandIcon={({ isActive }) => (
                  <div style={{ rotate: isActive ? "0deg" : "180deg" }}>
                    <CommonIcons.ChevronUpIcon />
                  </div>
                )}
              />
            </div>
            <div className="div-collapse-pol-point-cus mb-3">
              <Collapse
                bordered={false}
                expandIconPosition="end"
                items={itemsPolygonPoint}
                // defaultActiveKey={["1"]}
                onChange={() => {}}
                className="!p-0"
                expandIcon={({ isActive }) => (
                  <div style={{ rotate: isActive ? "0deg" : "180deg" }}>
                    <CommonIcons.ChevronUpIcon />
                  </div>
                )}
              />
            </div>

            <CommonComponents.FormFieldUI
              name="acquisition"
              label="Acquisition day"
              rules={[{ required: true, message: "Please select here!" }]}
              component={
                <Select
                  className={`w-full [&_div.ant-select-selector]:!border-[#585F77] [&_div.ant-select-selector]:!border-0 [&_div.ant-select-selector]:!border-b [&_div.ant-select-selector]:focus-within:!border-[#60CDFF] [&_div.ant-select-selector]:!rounded-t-sm [&_div.ant-select-selector]:!rounded-b-sm [&_div.ant-select-selector]:!shadow-none [&_span.ant-select-clear]:!bg-[#23293A] [&_span.ant-select-clear_span]:!text-common-white [&_span.ant-select-arrow_span]:!text-common-white`}
                  style={{
                    borderRadius: "3px",
                    height: heightSelect,
                  }}
                  placeholder="Select your acquisition day"
                  onSelect={() => setHeightSelect("100px")}
                >
                  {dataSegments?.map((item: any) => (
                    <Select.Option value={item?.id}>
                      {renderSelectOption(item)}
                    </Select.Option>
                  ))}
                </Select>
              }
            />
            <div className="grid grid-cols-3 gap-x-2">
              {dataProduction?.map((item: any) => {
                return (
                  <CommonComponents.FormFieldUI
                    name={item?.name}
                    label={item?.label}
                    rules={[
                      {
                        required: item?.mandatory === "true" ? true : false,
                        message: "Please select here!",
                      },
                    ]}
                    component={
                      <Select
                        className={`w-full [&_div.ant-select-selector]:!border-[#585F77] [&_div.ant-select-selector]:!border-0 [&_div.ant-select-selector]:!border-b [&_div.ant-select-selector]:focus-within:!border-[#60CDFF] [&_div.ant-select-selector]:!rounded-t-sm [&_div.ant-select-selector]:!rounded-b-sm [&_div.ant-select-selector]:!shadow-none [&_span.ant-select-clear]:!bg-[#23293A] [&_span.ant-select-clear_span]:!text-common-white [&_span.ant-select-arrow_span]:!text-common-white`}
                        style={{
                          borderRadius: "3px",
                        }}
                      >
                        {item?.values?.map((itemM: any) => (
                          <Select.Option value={itemM?.id}>
                            {itemM?.label}
                          </Select.Option>
                        ))}
                      </Select>
                    }
                  />
                );
              })}
            </div>
          </div>
          <div className="w-1/2 ">
            <MapTaskingPlugin
              showOnConfirm={true}
              activePolygon={data?.aoi ?? {}}
            />
          </div>
        </div>
      </ContentLayoutManagement>
    </Form>
  );
};

export default UpdateTaskingOrderAdminPage;

export const BuyerInfomationTaskingOrderAdmin = ({
  whoami,
  valueForm,
  buyerInfo,
  t,
}: {
  whoami: any;
  valueForm?: any;
  buyerInfo?: any;
  t?: any;
}) => {
  const renderInfoBuyer = () => {
    if (!buyerInfo) return <></>;
    return Object.entries(buyerInfo).map(([key, value]: any) => (
      <p
        className="flex justify-between p-2"
        key={key}
        style={{ fontSize: "14px", color: "white" }}
      >
        <span className="text-des !mr-1">
          {t ? t(capitalizeFirstLetter(key)) : t(capitalizeFirstLetter(key))}{" "}
        </span>
        <span className="text-right">
          {typeof value === "object"
            ? `${value?.first} ${value?.last}`
            : value ?? ""}
        </span>
      </p>
    ));
  };
  return (
    <div className="!text-white flex flex-col gap-2 font-normal ">
      {renderInfoBuyer()}
    </div>
  );
};
