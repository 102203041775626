import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import EditSvg from "../../assets/svg/edit.svg";
import InfoSvg from "../../assets/svg/setting.svg";
import defaultImage from "../../assets/images/default.png";
import CommonComponents from "../../components/Commons";
import CardManagementComp from "../../components/Commons/CardManagement";
import LoadingUI from "../../components/Commons/Loading";
import NoData from "../../components/NoData";
import { baseRoutes } from "../../router/RouterPage";
import useGetProviderConfigs from "../../services/providerConfigs/hooks/useGetProviderConfigs";
import { RootState } from "../../store/store";
import {
  resetStateFooter,
  setShowPaging,
} from "../../store/reducers/appCommonSlice";
import { useTranslation } from "react-i18next";
export interface IFilterProvider {
  page?: number;
  size?: number;
  sort?: string;
}
const ListProviderBranch = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pageSize = useSelector(
    (state: RootState) => state.appCommonSlice.pageSize
  );
  const pageNumber = useSelector(
    (state: RootState) => state.appCommonSlice.pageNumber
  );

  const {
    data,
    isLoading,
    refetch: refetchProvider,
  } = useGetProviderConfigs(
    {
      page: pageNumber,
      size: pageSize,
    },
    false
  );

  const providerConfigStore = data?.data;

  const ParseData = useCallback(() => {
    if (
      providerConfigStore?.content_page &&
      providerConfigStore?.content_page?.length < 1
    ) {
      return [];
    }

    return providerConfigStore?.content_page?.map((item) => {
      const imageBranch =
        item?.metadatas && item?.metadatas.find((item) => item.key === "LOGO");
      return {
        id: item?.id,
        imgSrc: imageBranch?.value,
        title: item?.name,
        actived: item?.actived,
        des: item?.description,
        errorImage: defaultImage,
      };
    });
  }, [providerConfigStore]);

  const handleEdit = (id: number) => {
    navigate(`${baseRoutes.providerBranch}/${id}?rule=edit`);
  };

  useEffect(() => {
    refetchProvider();
  }, [pageNumber]);

  useEffect(() => {
    dispatch(setShowPaging(true));
    return () => {
      dispatch(resetStateFooter({}));
    };
  }, []);

  const renderProvider = useCallback(() => {
    if (isLoading) {
      return <LoadingUI />;
    }
    if (providerConfigStore?.content_page?.length === 0) {
      return <NoData />;
    }
    return ParseData()?.map((item, index) => {
      return (
        <CardManagementComp
          key={index}
          data={item}
          // onClick={() => {
          //   navigate(`/provider-branch/${item?.id}/list-schema`, {
          //     state: {
          //       item: item,
          //     },
          //   });
          // }}
          actions={[
            <CommonComponents.TooltipUI title={t("Edit")}>
              <img
                alt="EditSvg"
                src={EditSvg}
                onClick={(e) => {
                  e.stopPropagation();
                  handleEdit(Number(item.id));
                }}
              />
            </CommonComponents.TooltipUI>,
            // <CommonComponents.TooltipUI title="Setting schema">
            //   <img
            //     alt="example"
            //     src={InfoSvg}
            //     onClick={(e) => {
            //       e.stopPropagation();
            //       navigate(`/provider-branch/${item?.id}/list-schema`, {
            //         state: {
            //           item: item,
            //         },
            //       });
            //     }}
            //   />
            // </CommonComponents.TooltipUI>,

            // <CommonComponents.TooltipUI title="Setting block code">
            //   <img
            //     alt="example"
            //     src={InfoSvg}
            //     onClick={(e) => {
            //       e.stopPropagation();
            //       navigate(`/provider-branch/${item?.id}/list-block-code`, {
            //         state: {
            //           item: item,
            //         },
            //       });
            //     }}
            //   />
            // </CommonComponents.TooltipUI>,
          ]}
        />
      );
    });
  }, [providerConfigStore]);
  return <div className="p-6">{renderProvider()}</div>;
};

export default ListProviderBranch;
