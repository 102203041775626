import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import vi from "./vi/vi.json";
import en from "./en/en.json";
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: "en",
    // fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        translation: en,
      },
      vi: {
        translation: vi,
      },
    },
  });

export default i18n;
