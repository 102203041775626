import ButtonIconComp from "../MapTasking/components/ButtonIcon";
import CloseSvg from "../../assets/svg/dismiss.svg";
import { useDispatch } from "react-redux";
import { changeLeftDrawerType } from "../../store/reducers/drawerSlice";
import { useTranslation } from "react-i18next";

const Notification = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleClose = () => {
    dispatch(changeLeftDrawerType(""));
  };

  return (
    <div className={`w-[460px] h-full p-2 overflow-x-auto`}>
      <div className="flex justify-between items-center">
        <div className="text-white text-sm font-semibold flex items-center gap-1 ">
          <p>{t("Notifications")}</p>
        </div>

        <ButtonIconComp
          item={{
            name: t("Close"),

            icon: CloseSvg,
            description: t("Close"),
          }}
          className="!h-7 !w-[30px] !min-w-[30px] !rounded !bg-[#272D3C]"
          onClick={() => handleClose()}
        />
      </div>
    </div>
  );
};

export default Notification;
