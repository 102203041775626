import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import MyDataSvg from "../../../assets/svg/ManageData/icon-card-preview-my-data.svg";
import Eye from "../../../assets/svg/eye.svg";
import InfoSvg from "../../../assets/svg/info.svg";
import SendSvg from "../../../assets/svg/send.svg";
import { ERoleAccessControl, ESttausCode } from "../../../enums";
import { capitalizeFirstLetter, getErrorMsg } from "../../../helpers/common";
import { CheckRoleAccessControlAdmin } from "../../../helpers/roleAccessControl";
import ContentLayoutManagement from "../../../layouts/ContentLayoutManagement";
import useGetOrderTaskingAdmin from "../../../services/orderTasking/hooks/useGetorderTaskingAdmin";
import useGetOrderTaskingUser from "../../../services/orderTasking/hooks/useGetorderTaskingUser";
import {
  resetStateFooter,
  setShowFilter,
  setShowPaging,
} from "../../../store/reducers/appCommonSlice";
import { resetAllDrawer } from "../../../store/reducers/drawerSlice";
import {
  changeDataBreadcrumb,
  changeLayoutName,
} from "../../../store/reducers/layoutSlice";
import {
  changeFilterArchive,
  getMetakeysAsync,
  resendEmailAsync,
} from "../../../store/reducers/orderSlice";
import { RootState } from "../../../store/store";
import CommonComponents from "../../Commons";
import CardManagementComp from "../../Commons/CardManagement";
import LoadingUI from "../../Commons/Loading";
import NoData from "../../NoData";
import useToggle from "../../../hooks/useToggle";
import { showError, showSuccess } from "../../../helpers/toast";
import { Spin } from "antd";
import { useTranslation } from "react-i18next";
const ManagementTasking = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const pageSize = useSelector(
    (state: RootState) => state.appCommonSlice.pageSize
  );
  const pageNumber = useSelector(
    (state: RootState) => state.appCommonSlice.pageNumber
  );
  const filterVal = useSelector(
    (state: RootState) => state.orderSlice.filterDrawer
  );

  const roleAdmin = CheckRoleAccessControlAdmin();
  const [dataUsing, setDataUsing] = useState<any>();

  const [arrStatus, setArrStatus] = useState<any>([]);

  const [idReject, setIdReject] = useState<number | null>(null);

  const arrAllProviders = useSelector(
    (state: RootState) => state.mapTasking.allProvider
  );
  //! state
  const getStatus = async () => {
    // @ts-ignore
    let res = await dispatch(
      // @ts-ignore
      getMetakeysAsync({ page: 0, size: 1000 })
    ).unwrap();

    setArrStatus(
      res?.data?.data?.content_page?.filter(
        (item: any) => item?.key === "ORDER_STATUS"
      )
    );
  };

  const {
    data: dataOrderTaskingUser,
    isLoading: loadingOrderTaskingUser,
    refetch: refetchOrderTaskingUser,
  } = useGetOrderTaskingUser(false, {
    page: pageNumber,
    size: pageSize,
    type_order: "tasking",
    status_id: filterVal?.status,
    sort: filterVal?.sortBy,
    start_date: filterVal?.start_date,
    end_date: filterVal?.end_date,
  });

  const {
    data: dataOrderTaskingAdmin,
    isLoading: loadingOrderAdmin,
    refetch: refetchOrderTaskingAdmin,
  } = useGetOrderTaskingAdmin(false, {
    page: pageNumber,
    size: pageSize,
    type_order: "tasking",
    status_id: filterVal?.status,
    sort: filterVal?.sortBy,
    start_date: filterVal?.start_date,
    end_date: filterVal?.end_date,
    email: filterVal?.email,
  });
  const [valueCheckCallApi, setValueCheckCallApi] = useState(false);
  const [loading, setLoading] = useState(false);

  const {
    open: openConfirmSendEmail,
    shouldRender: shouldConfirmSendEmail,
    toggle: toggleConfirmSendEmail,
  } = useToggle();

  const ParseDataOrder = useCallback(() => {
    if (dataUsing?.content_page && dataUsing?.content_page?.length < 1) {
      return [];
    }
    return dataUsing?.content_page?.map((item: any) => {
      return {
        id: item.id,
        imgSrc: MyDataSvg,
        title: item.name,
        status: item?.status,
        des: {
          created_at: item?.created_at,
          description: item?.description,
          priority: item?.priority,
          provider: item?.order_details?.[0]?.provider,
          status: item?.status,
        },
      };
    });
  }, [dataUsing, loadingOrderAdmin, loadingOrderTaskingUser]);

  const renderItemOrder = useCallback(() => {
    if (loadingOrderTaskingUser || loadingOrderAdmin) {
      return <LoadingUI />;
    }
    if (dataUsing?.content_page && dataUsing?.content_page?.length < 1) {
      return <NoData />;
    }
    return ParseDataOrder()?.map((item: any, index: any) => {
      const time = moment(item?.des?.created_at).format("DD/MM/YYYY HH:mm");

      let idProvider = item?.des?.provider?.id;

      const colorTagBranch = arrAllProviders
        ?.find((item: any) => item?.id === idProvider)
        ?.metadatas?.find((itemF: any) => itemF?.key === "COLOR_NAME")?.value;
      const dataItem = {
        ...item,
        des: (
          <div className="flex gap-1">
            {item?.des?.provider?.id && (
              <>
                <span
                  className="rounded-sm h-[20px]  px-1 flex flex-col-reverse  items-center justify-between"
                  style={{
                    color: "white",
                    fontSize: "12px",
                    background: colorTagBranch ?? "#004E72",
                  }}
                >
                  {capitalizeFirstLetter(item?.des?.provider?.name)}
                </span>{" "}
                |{" "}
              </>
            )}
            {item?.des?.status && (
              <>
                <span
                  className="rounded-sm h-[20px]  px-1 flex flex-col-reverse  items-center justify-between"
                  style={{
                    color: "white",
                    fontSize: "12px",
                    background: item?.des?.status?.description,
                  }}
                >
                  {t(item?.des?.status?.value)}
                </span>{" "}
                |{" "}
              </>
            )}
            {item?.des?.created_at && <span>{time}</span>}
            {item?.des?.priority && (
              <div className="before:content-['|']">
                <span
                  style={{
                    color: item?.des?.priority?.description,
                  }}
                >
                  {` ${t(item?.des?.priority.value)}`}
                </span>
              </div>
            )}
            {item?.des?.description && (
              <span
                className="before:content-['|'] flex-1 text-one-line"
                title={item?.des?.description}
              >
                {` ${item?.des?.description}`}
              </span>
            )}
          </div>
        ),
      };

      return (
        <CardManagementComp
          key={index}
          data={dataItem}
          onClick={() => navigate(`${item?.id}`)}
          actions={[
            item?.status?.value === "CLOSED" && (
              // roleAdmin?.includes(ERoleAccessControl.admin) && (
              <CommonComponents.TooltipUI
                title={
                  roleAdmin?.includes(ERoleAccessControl.admin)
                    ? t("Send email link download")
                    : t("Get email link download")
                }
              >
                <div>
                  <img
                    onClick={(event: any) => {
                      event?.stopPropagation();
                      setIdReject(item?.id);
                      toggleConfirmSendEmail();
                    }}
                    src={SendSvg}
                    alt="eye"
                  />
                </div>
              </CommonComponents.TooltipUI>
            ),
            // )
            item?.status?.value === "CLOSED" && (
              <CommonComponents.TooltipUI title={t("Result Order")}>
                <img
                  onClick={(event: any) => {
                    event?.stopPropagation();
                    navigate(`/resultOrder/${item?.id}?type=tasking`);
                  }}
                  src={Eye}
                  alt="eye"
                />
              </CommonComponents.TooltipUI>
            ),
            <CommonComponents.TooltipUI title={t("Information")}>
              <img
                alt="example"
                src={InfoSvg}
                onClick={() => {
                  navigate(`./${item?.id}`);
                }}
              />
            </CommonComponents.TooltipUI>,
          ]}
        />
      );
    });
  }, [dataUsing, loadingOrderAdmin, loadingOrderTaskingUser]);

  useEffect(() => {
    if (valueCheckCallApi) {
      if (roleAdmin?.includes(ERoleAccessControl.admin)) {
        refetchOrderTaskingAdmin();
        return;
      }
      if (!roleAdmin?.includes(ERoleAccessControl.admin)) {
        refetchOrderTaskingUser();
        return;
      }
    }
  }, [roleAdmin, pageNumber, JSON.stringify(filterVal), valueCheckCallApi]);
  useEffect(() => {
    if (!filterVal?.status) {
      setValueCheckCallApi(true);
    }
  }, [filterVal]);

  useEffect(() => {
    dispatch(changeLayoutName("/order/management-tasking"));
    dispatch(
      changeDataBreadcrumb([{ title: t("managementOrder.taskingOrders") }])
    );

    dispatch(resetAllDrawer());
    dispatch(setShowPaging(true));
    dispatch(setShowFilter(true));

    getStatus();

    return () => {
      dispatch(changeLayoutName(""));
      dispatch(resetAllDrawer());
      dispatch(resetStateFooter({}));
      dispatch(
        // @ts-ignore
        changeFilterArchive({
          status: null,
        })
      );
    };
  }, []);
  useEffect(() => {
    if (dataOrderTaskingAdmin || dataOrderTaskingUser) {
      setDataUsing(dataOrderTaskingAdmin?.data ?? dataOrderTaskingUser?.data);
    }
  }, [
    loadingOrderTaskingUser,
    loadingOrderAdmin,
    dataOrderTaskingAdmin,
    dataOrderTaskingUser,
  ]);

  const handleResendEmail = async () => {
    try {
      setLoading(true);
      toggleConfirmSendEmail();

      // @ts-ignore
      const result = await dispatch(resendEmailAsync(idReject)).unwrap();
      if (result.status === ESttausCode.OK) {
        showSuccess(t("Successfully"));
      } else {
        showError(t("Something went wrong!"));
      }
    } catch (error) {
      showError(t(getErrorMsg(error)));
    } finally {
      setIdReject(null);
      setLoading(false);
    }
  };

  return (
    <ContentLayoutManagement>
      <Spin spinning={loading}>
        <div className="relative w-full h-full p-6 bg-content-default">
          {renderItemOrder()}
        </div>
      </Spin>

      {shouldConfirmSendEmail && (
        <CommonComponents.ModalUI
          toggle={toggleConfirmSendEmail}
          title={
            roleAdmin?.includes(ERoleAccessControl.admin)
              ? t("Send email")
              : t("Get email")
          }
          open={openConfirmSendEmail}
          handleConfirm={handleResendEmail}
          // hiddenFooter={true}
        >
          <p>
            {roleAdmin?.includes(ERoleAccessControl.admin)
              ? t("Are you sure you want to send email link download to user?")
              : t("Are you sure you want to get email link download?")}
          </p>
        </CommonComponents.ModalUI>
      )}
    </ContentLayoutManagement>
  );
};

export default ManagementTasking;
