import { Form } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import UpdateArchiveOrder from "../../components/BookingImage/UpdateArchiveOrder";
import CommonComponents from "../../components/Commons";
import { ERoleAccessControl } from "../../enums";
import { showError, showSuccess } from "../../helpers/toast";
import ContentLayoutManagement from "../../layouts/ContentLayoutManagement";
import { setLoadingGlobal } from "../../store/reducers/appCommonSlice";
import { resetAllDrawer } from "../../store/reducers/drawerSlice";
import {
  changeDataBreadcrumb,
  changeLayoutName,
} from "../../store/reducers/layoutSlice";
import {
  getOrderByIdAsync,
  getOrderByIdUserAsync,
  getPriorityAsync,
  updateArchiveOrderAsync,
  updateArchiveOrderByAdminAsync,
} from "../../store/reducers/orderSlice";
import { getMyProjectsAsync } from "../../store/reducers/projectSlice";
import { RootState } from "../../store/store";
import BackSvg from "../../assets/svg/back-arrow.svg";
import { useTranslation } from "react-i18next";

interface IProps {
  hidden?: boolean;
  onClose?: () => void;
}
const UpdateArchiveOrderPage = (props: IProps) => {
  const roleAdmin = useSelector(
    (state: RootState) => state.appCommonSlice.roleUsing
  );
  const location = useLocation();
  const navigate = useNavigate();
  let { id } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const dataItem = location?.state?.item;

  const [data, setData] = useState<any>({});
  const [dataPriority, setDataPriority] = useState<any>([]);
  const [arrProjects, setArrProjects] = useState<any>([]);
  const getData = async () => {
    dispatch(setLoadingGlobal(true));
    // @ts-ignore
    // let res = await dispatch(getOrderByIdAsync(id)).unwrap();
    let res = roleAdmin?.includes(ERoleAccessControl.admin)
      ? // @ts-ignore
        await dispatch(getOrderByIdAsync(id)).unwrap()
      : // @ts-ignore
        await dispatch(getOrderByIdUserAsync(id)).unwrap();
    setData(res?.data?.data);
    dispatch(setLoadingGlobal(false));
  };
  const handleGetPriority = async () => {
    // @ts-ignore
    const res = await dispatch(getPriorityAsync()).unwrap();

    setDataPriority(
      res?.data?.data?.content_page?.filter(
        (itemF: any) => itemF?.key === "PRIORITY_LEVEL"
      ) ?? []
    );
  };

  const getDataProject = async () => {
    // @ts-ignore
    let res = await dispatch(
      // @ts-ignore
      getMyProjectsAsync({ size: 1000, page: 0 })
    ).unwrap();

    setArrProjects(res?.data?.data?.content_page ?? []);
  };

  useEffect(() => {
    handleGetPriority();
    getDataProject();
  }, []);

  //! effect
  useEffect(() => {
    if (id) {
      getData();
    }
  }, [id]);

  useEffect(() => {
    dispatch(changeLayoutName("/update-order-tasking"));
    dispatch(
      changeDataBreadcrumb([
        {
          title: (
            <span
              className="cursor-pointer"
              onClick={() => navigate("/order/management-order")}
            >
              {t("Existing Image Orders")}
            </span>
          ),
        },
        { title: `${t("Edit order")} ${dataItem?.name}` },
      ])
    );
    dispatch(resetAllDrawer());
    return () => {
      dispatch(changeLayoutName(""));
      dispatch(changeDataBreadcrumb([]));
    };
  }, [id]);

  const renderOrderRequest = (item: any, values: any) => {
    let a = {};
    switch (item?.provider?.name?.toLowerCase()) {
      case "planet":
        a = {
          ...item?.request_order,
          products:
            values?.dataPlanet?.products &&
            Object.keys(values?.dataPlanet?.products)?.length > 0
              ? Object.entries(values?.dataPlanet?.products).map(
                  ([key, details]: any) => ({
                    product_bundle: details?.productBundle,
                    item_type: key,
                    image_ids: details?.imageId ?? [],
                  })
                )
              : [],
        };

        break;
      case "capella":
        a = {
          ...item?.request_order,
        };

        break;
      case "oneatlas":
        a = {
          ...item?.request_order,
          optionsPerProductType: [
            {
              options: values?.oneatlas
                ? Object?.keys(values?.oneatlas)
                    ?.filter((key) => key !== "product_type_id")
                    ?.map((key) => ({
                      key: key,
                      value: values?.oneatlas[key],
                      label: key,
                    }))
                : {},
              product_type_id: values?.oneatlas?.product_type_id,
            },
          ],
        };
        break;
    }

    return a;
  };

  //! render
  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={async (values: any) => {
        let details: any = [];

        data?.order_details?.forEach((itemM: any) => {
          details.push({
            order_detai_id: itemM?.id,
            request_order: renderOrderRequest(itemM, values),
          });
        });

        const { dataPlanet, oneatlas, capella, ...restOfFormData } = values;

        let pay = {
          ...restOfFormData,
          order_details: details,
        };

        let res;

        if (roleAdmin?.includes(ERoleAccessControl.admin)) {
          // @ts-ignore
          res = await dispatch(
            // @ts-ignore
            updateArchiveOrderByAdminAsync({ ...pay, orderId: id })
          ).unwrap();
        } else {
          // @ts-ignore
          res = await dispatch(
            // @ts-ignore
            updateArchiveOrderAsync({ ...pay, orderId: id })
          ).unwrap();
        }

        if (res?.status === 200 || res?.status === 201) {
          showSuccess(t("Update successfully"));

          navigate(`/order/management-order/${id}`);
        } else {
          showError(t("Update failed"));
        }
      }}
    >
      <ContentLayoutManagement
        header={
          <div className="flex gap-2 justify-between mr-[30px] w-full">
            <CommonComponents.ButtonUI
              commonType="standard"
              className="btn-cancel hover:bg-[#3E4353] !text-xs !h-7"
              onClick={() => navigate("/order/management-order")}
              icon={<img src={BackSvg} />}
            ></CommonComponents.ButtonUI>
            <CommonComponents.ButtonUI
              className="btn-header !text-xs !h-[27px]  hover:!bg-common-primary500"
              htmlType="submit"
            >
              {t("Save")}
            </CommonComponents.ButtonUI>
          </div>
        }
      >
        <div className="text-white overflow-hidden div-page-default">
          <UpdateArchiveOrder
            form={form}
            data={data}
            priority={dataPriority}
            project={arrProjects}
          />
        </div>
      </ContentLayoutManagement>
    </Form>
  );
};

export default UpdateArchiveOrderPage;
