import { ReactNode } from "react";
import { useDispatch, useSelector } from "react-redux";
import BookingImage from "../components/BookingImage";
import CommonComponents from "../components/Commons";
import CartDrawerComp from "../components/Commons/Cart/CartDrawer";
import FooterManagementComp from "../components/Commons/FooterManagement";
import DrawerInfoDataComp from "../components/ManageData/MyData/DrawerInfoData";
import DrawerListImages from "../components/ManageOrder/UnapprovalOrder.tsx/PendingOrder/DrawerListImages";
import ImageInformation from "../components/MapTasking/ImageInformation";
import SettingBasemap from "../components/Map/SettingBase";
import { onMouseEnterItem, onMouseLeaveItem } from "../components/Map/ultis";
import DrawerFilterSearchComp from "../components/MapTasking/DrawerFilterSearch";
import DrawerHistorySearch from "../components/MapTasking/DrawerHistorySearch";
import DrawerListImagesSeacrhComp from "../components/MapTasking/DrawerListImagesSeacrh";
import InfoPolygonComp from "../components/MapTasking/InfoPolygon";
import PolygonListTaskingComp from "../components/MapTasking/PolygonList";
import DrawPolygonComp from "../components/OrderTasking/DrawPolygon";
import FormOrderUserComp from "../components/OrderTasking/FormOrderUser";
import { setLoadingGlobal } from "../store/reducers/appCommonSlice";
import {
  changeFlagShowInfoImageInCart,
  changeLeftDrawerType,
  changeShowDrawer,
} from "../store/reducers/drawerSlice";
import {
  changeSelectedImage,
  setSelected,
} from "../store/reducers/mapTaskingSlice";
import { RootState } from "../store/store";
import Notification from "../components/Notification";
import DrawerFilterArchiveOrder from "../components/ManageOrder/DrawerFilterArchiveOrder";
import { LocalStorageKey } from "../constants/localStorageKeys";

interface IProps {
  header?: ReactNode;
  footer?: ReactNode;
  children: ReactNode;
  zIndex?: number;
  headerZIndex?: number;
  bodyZIndex?: number;
  footerZIndex?: number;
  pageName?: any;
  overlay?: boolean;
}

export default function ContentLayoutManagement({
  header,
  footer,
  children,
  zIndex = 20,
  headerZIndex = 20,
  bodyZIndex = 10,
  footerZIndex = 20,
  pageName,
  overlay,
}: IProps) {
  const loadingGlobal = useSelector(
    (state: RootState) => state.appCommonSlice.loadingGlobal
  );

  const layoutName = useSelector(
    (state: RootState) => state.layoutSlice.layoutName
  );

  const { colorConfig } = useSelector(
    (state: RootState) => state.appCommonSlice
  );

  const _header = (
    <div
      className={`h-14 py-2  w-full flex flex-row items-center`}
      style={{ background: colorConfig.mainColor }}
    >
      {loadingGlobal &&
        layoutName !== "/explore-archive" &&
        layoutName !== "/explore-tasking" && (
          <svg
            className="animate-spin mr-6"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <circle
              cx="12"
              cy="12"
              r="10"
              transform="rotate(45 12 12)"
              stroke="#565656"
              stroke-width="3"
            />
            <path
              d="M19.0708 19.0711C15.1656 22.9763 8.83395 22.9763 4.92871 19.0711C1.02347 15.1658 1.02347 8.8342 4.92871 4.92896"
              stroke="#60CDFF"
              stroke-width="3"
              stroke-linecap="round"
            />
          </svg>
        )}

      {header}
    </div>
  );

  const _footer = <>{footer}</>;

  const dispatch = useDispatch();

  const { expanded } = useSelector((state: RootState) => state.layoutSlice);
  const {
    showDrawer,
    showBottomDrawer,
    leftDrawerType,
    flagShowInfoImageInCart,
    bottomDrawerType,
    leftDrawerTypeBefore,
  } = useSelector((state: RootState) => state.drawerSlice);

  const showOrderTasking = useSelector(
    (state: RootState) => state.appCommonSlice.showOrderTasking
  );

  const { showDrawerListImages } = useSelector(
    (state: RootState) => state.orderSlice
  );

  const { showDrawerInfomages } = useSelector(
    (state: RootState) => state.manageDataSlice
  );

  const { selectedImage, sourceId, selected, maplibreGL } = useSelector(
    (state: RootState) => state.mapTasking
  );

  const onShowInMap = (e: any, item: any) => {
    dispatch(setLoadingGlobal(true));
    e?.stopPropagation();
    let selectedCopy: Array<any> =
      selected && selected.length ? selected.slice() : [];
    let index = selectedCopy?.findIndex((x) => x.id === item?.id);
    if (index < 0) selectedCopy.push(item);
    else selectedCopy.splice(index, 1);

    dispatch(setSelected(selectedCopy));
    dispatch(setLoadingGlobal(false));
  };

  const renderDrawer = () => {
    if (showDrawerInfomages) {
      return <DrawerInfoDataComp />;
    } else if (showDrawer) {
      switch (leftDrawerType) {
        case "Search results":
          return <DrawerListImagesSeacrhComp />;

        case "Filter":
          return <DrawerFilterSearchComp />;

        case "cart":
          return (
            <CartDrawerComp
              selectedIds={
                selected && selected.length
                  ? selected.map((x: any) => x?.id)
                  : []
              }
              hidden={leftDrawerType !== "cart"}
              onClose={() => {
                if (leftDrawerTypeBefore !== "") {
                  dispatch(changeLeftDrawerType(leftDrawerTypeBefore));
                  dispatch(changeShowDrawer(true));
                } else {
                  dispatch(changeLeftDrawerType(""));
                  dispatch(changeShowDrawer(false));
                }
              }}
              onShowInMap={(e: any, item: any) => onShowInMap(e, item)}
              onMouseEnterItem={(item: any) =>
                onMouseEnterItem(item, maplibreGL, sourceId)
              }
              onMouseLeaveItem={(item: any) =>
                onMouseLeaveItem(item, maplibreGL, sourceId)
              }
            />
          );

        case "info":
          return (
            <ImageInformation
              hidden={leftDrawerType !== "info"}
              item={selectedImage}
              onClose={() => {
                dispatch(changeSelectedImage({}));

                if (flagShowInfoImageInCart) {
                  dispatch(changeFlagShowInfoImageInCart(false));
                  dispatch(changeLeftDrawerType("cart"));
                } else {
                  dispatch(changeShowDrawer(false));
                  dispatch(changeLeftDrawerType(""));
                }
              }}
            />
          );

        case "BookingImage":
          return (
            <BookingImage
              hidden={leftDrawerType !== "BookingImage"}
              onClose={() => {
                dispatch(changeShowDrawer(false));
                dispatch(changeLeftDrawerType(""));
              }}
              dataPriority={[]}
              datProject={[]}
            />
          );

        case "Info AOI":
          return <InfoPolygonComp hidden={leftDrawerType !== "Info AOI"} />;

        case "OrderTaskingUser":
          return <FormOrderUserComp />;

        case "Setting basemap":
          return (
            <SettingBasemap hidden={leftDrawerType !== "Setting basemap"} />
          );

        case "notification":
          return <Notification />;

        case "Filter archive oder by admin":
          return <DrawerFilterArchiveOrder />;

        default:
          break;
      }
    } else if (showOrderTasking) {
      return <DrawPolygonComp />;
    }
  };

  const renderBottomDrawer = () => {
    switch (bottomDrawerType) {
      case "History list":
        return <DrawerHistorySearch />;
      case "Polygon template list tasking":
        return (
          <PolygonListTaskingComp
            hidden={bottomDrawerType !== "Polygon template list tasking"}
          />
        );

      default:
        break;
    }
  };

  console.log(colorConfig, "colorConfig");

  return (
    <div
      className="absolute w-full h-full left-0 top-0 bg-common-white flex flex-col"
      style={{ zIndex: zIndex }}
    >
      <div
        className={`flex justify-between items-center h-[56px]`}
        style={{ zIndex: headerZIndex, background: colorConfig.mainColor }}
      >
        {_header}
      </div>

      <div
        className={`grow overflow-auto`}
        style={{
          zIndex: bodyZIndex,
          height: "calc(100vh - 56px - 56px)",
          width: expanded ? "calc(100vw - 268px)" : "calc(100vw - 64px)",
          background: colorConfig.contentColor,
        }}
      >
        <>
          <div
            style={{
              width: showDrawer ? "calc(100% - 460px)" : "100%",
              height: "100%",
            }}
            // className="transition-all duration-500"
          >
            {children}
          </div>
          {showDrawer && (
            <CommonComponents.DrawerUI
              open={showDrawer}
              placement="right"
              style={{ height: "calc(100vh - 56px - 56px)", top: "56px" }}
            >
              {renderDrawer()}
            </CommonComponents.DrawerUI>
          )}

          <div
            className="absolute bottom-[56px] h-fit z-20 transition-all duration-300"
            style={{
              width: showDrawer ? "calc(100% - 460px)" : "100%",
            }}
          >
            <CommonComponents.DrawerUI
              open={showBottomDrawer}
              placement="bottom"
            >
              {renderBottomDrawer()}
            </CommonComponents.DrawerUI>
          </div>
        </>
      </div>

      <div className="flex-none h-fit" style={{ zIndex: footerZIndex }}>
        {pageName === "Map" ? _footer : <FooterManagementComp />}
      </div>
    </div>
  );
}
