import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DismissSvg from "../../assets/svg/dismiss.svg";
import FilterSvg from "../../assets/svg/filter.svg";
import RefreshSvg from "../../assets/svg/refresh.svg";
import { setPageNumber } from "../../store/reducers/appCommonSlice";
import {
  changeLeftDrawerType,
  changeShowDrawer,
} from "../../store/reducers/drawerSlice";
import { changeFilterArchive } from "../../store/reducers/orderSlice";
import { RootState } from "../../store/store";
import CommonComponents from "../Commons";
import ButtonIconComp from "../MapTasking/ButtonIconComp";
import moment from "moment";
import dayjs from "dayjs";
import useDebounce from "../../hooks/useDebounce";
import { t } from "i18next";
import { CheckRoleAccessControlAdmin } from "../../helpers/roleAccessControl";
import { ERoleAccessControl } from "../../enums";

const DrawerFilterArchiveOrder = () => {
  //! state
  const dispatch = useDispatch();

  const metaKey = useSelector(
    (state: RootState) => state.appCommonSlice.metaKey
  );

  const filterValue = useSelector(
    (state: RootState) => state.orderSlice.filterDrawer
  );

  const [statusChangeOption, setStatusChangeOption] = useState(false);

  const [emailSearch, setEmailSearch] = useState("");
  const [filter, setFilter] = useState<any>({
    status: filterValue?.status,
    sortBy: "desc",
    start_date: null,
    end_date: null,
    email: null,
  });
  useDebounce(
    () => {
      if (statusChangeOption) {
        handleChangeFilter("email", emailSearch);
      }
    },
    [emailSearch],
    1000
  );

  //! fuction
  const handleRefresh = () => {
    dispatch(
      // @ts-ignore
      changeFilterArchive({
        ...filterValue,
        status: null,
        sortBy: "desc",
        start_date: null,
        end_date: null,
        email: null,
      })
    );

    setFilter({
      status: null,
      sortBy: "desc",
      start_date: null,
      end_date: null,
      email: null,
    });
    setEmailSearch("");
  };

  const handleChangeFilter = (key: string, value: any) => {
    setFilter({
      ...filter,
      [key]: value,
    });
  };

  //! effect
  useEffect(() => {
    if (statusChangeOption) {
      dispatch(
        // @ts-ignore
        changeFilterArchive({
          ...filterValue,
          status: filter.status,
          sortBy: filter.sortBy,
          start_date: filter.start_date,
          end_date: filter.end_date,
          email: filter.email,
        })
      );

      dispatch(setPageNumber(0));
      setStatusChangeOption(false);
    }
  }, [filter]);

  const { colorConfig } = useSelector(
    (state: RootState) => state.appCommonSlice
  );

  const roleAdmin = CheckRoleAccessControlAdmin();
  //! render
  return (
    <div className="h-full p-3 w-[460px]">
      <div
        className={`sticky -top-3 z-10  `}
        style={{ background: colorConfig.mainColor }}
      >
        <div className="flex justify-between items-center py-1">
          <div className="flex items-center gap-2 text-sm font-normal text-common-white">
            <img src={FilterSvg} className="!w-4" />
            <div>{t("Filter")}</div>
          </div>

          <div className="flex items-center gap-2 justify-end">
            {/* <ButtonIconComp
              item={{
                description: "Apply",
                icon: ApplySvg,
                title: "Apply",
              }}
              onClick={() => {
                handleFilter();
              }}
              className="!h-7  !w-[80px] !min-w-[80px] !rounded !bg-[#272D3C] div-one-center"
            /> */}
            <ButtonIconComp
              item={{
                description: t("Refresh"),
                icon: RefreshSvg,
              }}
              onClick={handleRefresh}
              className="!h-7 !w-[30px] !min-w-[30px] !rounded !bg-[#272D3C] div-one-center"
            />
            <ButtonIconComp
              item={{
                description: t("Close"),
                icon: DismissSvg,
              }}
              onClick={() => {
                dispatch(changeShowDrawer(false));
                dispatch(changeLeftDrawerType(""));
              }}
              className="!h-7 !w-[30px] !min-w-[30px] !rounded !bg-[#272D3C] div-one-center"
            />
          </div>
        </div>
      </div>

      <div
        className="flex flex-col gap-4 overflow-x-auto"
        style={{ height: "calc(100% - 100px)" }}
      >
        <div>
          <CommonComponents.SelectUI
            className="div-dropdown"
            label={t("Status")}
            value={filter.status}
            options={[
              { value: null, label: t("All") },
              ...metaKey
                ?.filter((itemF: any) => itemF?.key === "ORDER_STATUS")
                ?.sort((a: any, b: any) => a.priority_view - b.priority_view)
                ?.map((itemM: any) => ({
                  value: itemM?.id,
                  label: t(itemM?.value),
                })),
            ]}
            onChange={(val: any) => {
              handleChangeFilter("status", val);

              setStatusChangeOption(true);
            }}
          />
        </div>

        <div>
          <CommonComponents.SelectUI
            className="div-dropdown"
            label={t("Sort by (Time)")}
            value={filter.sortBy}
            options={[
              {
                value: "asc",
                label: t("Ascending"),
              },
              {
                value: "desc",
                label: t("Descending"),
              },
            ]}
            onChange={(val) => {
              handleChangeFilter("sortBy", val);
              setStatusChangeOption(true);
            }}
          />
        </div>

        {roleAdmin?.includes(ERoleAccessControl.admin) && (
          <div>
            <CommonComponents.InputUI
              label="Email"
              placeholder={t("Enter email to search")}
              allowClear
              onChange={(event: any) => {
                setEmailSearch(event.target.value);
                setStatusChangeOption(true);
              }}
              value={emailSearch}
            />
          </div>
        )}

        <div className="flex gap-3">
          <div className="basis-1/2">
            <CommonComponents.DatePickerUI
              label={t("Start time")}
              placeholder={t("Select start time")}
              allowClear={false}
              className="w-full"
              value={
                !filter.start_date ? null : dayjs(filter.start_date * 1000)
              }
              onChange={(val: any, dateString: string | string[]) => {
                const timestamp =
                  moment(dateString, "DD/MM/YYYY HH:mm:ss")
                    .startOf("day")
                    .valueOf() / 1000;

                handleChangeFilter("start_date", timestamp);
                setStatusChangeOption(true);
              }}
            />
          </div>
          <div className="basis-1/2">
            <CommonComponents.DatePickerUI
              label={t("End time")}
              placeholder={t("Select end time")}
              allowClear={false}
              className="w-full"
              value={!filter.end_date ? null : dayjs(filter.end_date * 1000)}
              onChange={(val: any, dateString: string | string[]) => {
                const timestamp = Math.floor(
                  moment(dateString, "DD/MM/YYYY HH:mm:ss")
                    .endOf("day")
                    .valueOf() / 1000
                );
                handleChangeFilter("end_date", timestamp);
                setStatusChangeOption(true);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default DrawerFilterArchiveOrder;
