import { Collapse, CollapseProps, Divider, Form, Spin } from "antd";
import { useEffect, useState, useTransition } from "react";
import { useSelector } from "react-redux";
import { capitalizeFirstLetter } from "../../helpers/common";
import { RootState } from "../../store/store";

import CommonIcons from "../../assets/icons";
import { EProviderName } from "../../enums";
import MapTaskingPlugin from "../MapTasking/Map";
import { RenderInfoPolygon } from "../MapTasking/ultis";
import Step1 from "./Step1";
import Step3 from "./Step3";
import Step4 from "./Step4";
import { BuyerInfomation, OrderInfomation } from "./utils/BuyerInfomation";
import Step2 from "./Step2";
import LoadingUI from "../Commons/Loading";
import { useTranslation } from "react-i18next";

const UpdateArchiveOrder = (props: any) => {
  const { t } = useTranslation();

  const whoami = useSelector((state: RootState) => state.appCommonSlice.whoami);

  const { providerConfigStore } = useSelector(
    (state: RootState) => state.appCommonSlice
  );

  const maplibreGLConfirmOrder = useSelector(
    (state: RootState) => state.mapTasking.maplibreGLConfirmOrder
  );

  const showOrderTasking = useSelector(
    (state: RootState) => state.orderSlice.showOrderTasking
  );

  const selectedPolygon = useSelector(
    (state: RootState) => state.mapTasking.activeFeature
  );

  const valueForm = useSelector(
    (state: RootState) => state.orderSlice.valuesForm
  );

  const loadingGlobal = useSelector(
    (state: RootState) => state.appCommonSlice.loadingGlobal
  );

  let map = useSelector((state: RootState) => state.mapTasking.maplibreGL);
  let sourceId = useSelector((state: RootState) => state.mapTasking.sourceId);
  const [itemsImages, setItemsImages] = useState<any>([]);
  const itemsInfoOder: CollapseProps["items"] = [
    // archive order
    {
      key: "1",
      label: t("Order Information"),
      children: (
        <Step1
          form={props?.form}
          dataPriority={props?.priority ?? []}
          datProject={props?.project ?? []}
          initialValues={props?.data}
        />
      ),
    },
  ];

  const items: CollapseProps["items"] = [
    {
      key: "1",
      label: t("Buyer Information"),
      children: <BuyerInfomation whoami={whoami} t={t} />,
    },
  ];

  const itemsValueOrder: CollapseProps["items"] = [
    {
      key: "1",
      label: t("Order Information"),
      children: <OrderInfomation valueForm={valueForm} />,
    },
  ];

  const itemsPolygonPoint: CollapseProps["items"] = [
    {
      key: "1",
      label: t("Polygon points"),
      children: RenderInfoPolygon(selectedPolygon),
    },
  ];

  const [arrAOI, setAOI] = useState<any>([]);

  useEffect(() => {
    let a: any = [];

    if (props?.data?.order_details?.length > 0 && map) {
      props?.data?.order_details
        ?.filter((itemF: any) => itemF?.images?.length > 0)
        ?.map((item: any, index: any) => {
          a = a.concat({
            key: index,
            label: (
              <span>
                {capitalizeFirstLetter(item?.provider?.name)} (
                {item?.images?.length})
                {item?.status?.name ? (
                  <span
                    style={{ color: item?.status?.description ?? "#EFB700" }}
                  >
                    {" - "}
                    {item?.status?.name}
                  </span>
                ) : (
                  ""
                )}
              </span>
            ),
            children: (
              <div className="w-full flex gap-3">
                <div className="w-full">
                  {item?.provider?.name.toLowerCase() === "planet" ? (
                    <Step3
                      form={props?.form}
                      dataImages={item?.images}
                      map={map}
                      sourceId={sourceId}
                      initialValues={item?.request_order?.products}
                      status={item?.status}
                      onClick={(idImage: any) => {
                        setAOI((prevAOI: any) => {
                          const exists = prevAOI.some(
                            (itemF: any) => itemF.id === idImage
                          );
                          if (exists) {
                            return prevAOI.filter(
                              (itemF: any) => itemF.id !== idImage
                            );
                          } else {
                            const newItem = item?.images?.find(
                              (itemF: any) => itemF.id === idImage
                            );
                            return newItem ? [...prevAOI, newItem] : prevAOI;
                          }
                        });
                      }}
                    />
                  ) : item?.provider?.name.toLowerCase() === "oneatlas" ? (
                    <Step4
                      showCardImages={true}
                      dataImages={item?.images}
                      total={item?.images?.length}
                      dataProvider={
                        providerConfigStore?.content_page?.filter(
                          (item: any) =>
                            item?.name.toLowerCase() === EProviderName.oneatlas
                        )?.[0]?.configs
                      }
                      map={map}
                      sourceId={sourceId}
                      form={props?.form}
                      initialValues={item?.request_order?.optionsPerProductType?.[0]?.options.concat(
                        {
                          key: "product_type_id",
                          value:
                            item?.request_order?.optionsPerProductType?.[0]
                              ?.productTypeId ??
                            item?.request_order?.optionsPerProductType?.[0]
                              ?.product_type_id,
                        }
                      )}
                      status={item?.status}
                      onClick={(idImage: any) => {
                        setAOI((prevAOI: any) => {
                          const exists = prevAOI.some(
                            (itemF: any) => itemF.id === idImage
                          );
                          if (exists) {
                            return prevAOI.filter(
                              (itemF: any) => itemF.id !== idImage
                            );
                          } else {
                            const newItem = item?.images?.find(
                              (itemF: any) => itemF.id === idImage
                            );
                            return newItem ? [...prevAOI, newItem] : prevAOI;
                          }
                        });
                      }}
                    />
                  ) : item?.provider?.name.toLowerCase() === "capella" ? (
                    <Step2
                      items={item?.images}
                      dataProvider={
                        providerConfigStore?.content_page?.filter(
                          (item: any) =>
                            item?.name.toLowerCase() === EProviderName.capella
                        )?.[0]?.configs
                      }
                      map={map}
                      sourceId={sourceId}
                      readOnly
                      status={item?.status}
                      onClick={(idImage: any) => {
                        setAOI((prevAOI: any) => {
                          const exists = prevAOI.some(
                            (itemF: any) => itemF.id === idImage
                          );
                          if (exists) {
                            return prevAOI.filter(
                              (itemF: any) => itemF.id !== idImage
                            );
                          } else {
                            const newItem = item?.images?.find(
                              (itemF: any) => itemF.id === idImage
                            );
                            return newItem ? [...prevAOI, newItem] : prevAOI;
                          }
                        });
                      }}
                    />
                  ) : null}
                </div>
              </div>
            ),
          });
        });

      const allImages = props?.data?.order_details
        .map((item: any) => item.images)
        .flat();

      setAOI(allImages);
    }

    props?.form.setFieldValue("name", props?.data?.name);
    props?.form.setFieldValue("description", props?.data?.description);
    props?.form.setFieldValue("priority_id", props?.data?.priority?.id);
    props?.form.setFieldValue("reason", props?.data?.reason);

    setItemsImages(a);
  }, [props?.data, maplibreGLConfirmOrder, map]);

  return (
    // <div className="flex w-full gap-3 h-full overflow-hidden card-archive-order">
    <div className="flex !flex-row gap-3 h-full">
      <div
        className="w-1/2 div-info-confirm-order text-white h-full overflow-auto pr-2  spin-order"
        style={{ minHeight: "calc(100vh - 56px - 56px - 90px)" }}
      >
        <Spin
          spinning={loadingGlobal}
          style={{
            minHeight: "calc(100vh - 56px - 56px - 90px)",
            height: "100%",
          }}
          indicator={<LoadingUI />}
        >
          <div className="div-collapse-pol-point-cus">
            <Collapse
              bordered={false}
              expandIconPosition="end"
              items={items}
              // defaultActiveKey={["1"]}
              onChange={() => {}}
              className="!p-0"
              expandIcon={({ isActive }) => (
                <div style={{ rotate: isActive ? "0deg" : "180deg" }}>
                  <CommonIcons.ChevronUpIcon />
                </div>
              )}
            />
          </div>

          {!showOrderTasking ? (
            <>
              <div className="div-collapse-pol-point-cus">
                <Collapse
                  bordered={false}
                  expandIconPosition="end"
                  items={itemsInfoOder}
                  defaultActiveKey={["1"]}
                  onChange={() => {}}
                  expandIcon={({ isActive }) => (
                    <div style={{ rotate: isActive ? "0deg" : "180deg" }}>
                      <CommonIcons.ChevronUpIcon />
                    </div>
                  )}
                />
              </div>
              <Divider style={{ background: "#353B4F", margin: "10px 0" }} />
              <p className="text-base mb-[10px] mt-1 font-normal">
                {t("Images in order")}
              </p>
              <div className="card-archive-order">
                <Collapse
                  bordered={false}
                  expandIconPosition="end"
                  items={itemsImages}
                  onChange={() => {}}
                  defaultActiveKey={["0", "1", "2", "3"]}
                  expandIcon={({ isActive }) => (
                    <div style={{ rotate: isActive ? "0deg" : "180deg" }}>
                      <CommonIcons.ChevronUpIcon />
                    </div>
                  )}
                />
              </div>
            </>
          ) : (
            <>
              {/* for tasking */}
              <div className="div-collapse-pol-point-cus">
                <Collapse
                  bordered={false}
                  expandIconPosition="end"
                  items={itemsValueOrder}
                  defaultActiveKey={["1"]}
                  onChange={() => {}}
                  className="!p-0"
                  expandIcon={({ isActive }) => (
                    <div style={{ rotate: isActive ? "0deg" : "180deg" }}>
                      <CommonIcons.ChevronUpIcon />
                    </div>
                  )}
                />
              </div>
              <div className="div-collapse-pol-point-cus">
                <Collapse
                  bordered={false}
                  expandIconPosition="end"
                  items={itemsPolygonPoint}
                  defaultActiveKey={["1"]}
                  onChange={() => {}}
                  expandIcon={({ isActive }) => (
                    <div style={{ rotate: isActive ? "0deg" : "180deg" }}>
                      <CommonIcons.ChevronUpIcon />
                    </div>
                  )}
                />
              </div>
            </>
          )}
        </Spin>
      </div>
      <div className="w-1/2 ">
        <MapTaskingPlugin
          showOnConfirm={true}
          activePolygon={showOrderTasking ? selectedPolygon?.geometry : {}}
          type={showOrderTasking ? "map-info-tasking" : "map-info-archive"}
          arrPolygon={arrAOI}
        />
      </div>
    </div>
  );
};

export const renderInfoOrder = (props: any, t: any) => {
  return (
    <>
      <p
        className="flex justify-between"
        style={{ fontSize: "14px", color: "white" }}
      >
        <span className="text-des">{t("Order name")}:</span>
        <span className="text-right">{props?.data?.name}</span>
      </p>

      <p
        className="flex justify-between"
        style={{ fontSize: "14px", color: "white" }}
      >
        <span className="text-des">{t("Priority")}:</span>{" "}
        <span
          className="text-right"
          style={{ color: props?.data?.priority?.description }}
        >
          {props?.data?.priority?.name}
        </span>
      </p>

      {props?.data?.currentStatus?.value && (
        <p
          className="flex justify-between"
          style={{ fontSize: "14px", color: "white" }}
        >
          <span className="text-des">{t("Status")}:</span>{" "}
          <span
            className="text-right"
            style={{ color: props?.data?.currentStatus?.description }}
          >
            {props?.data?.currentStatus?.value}
          </span>
        </p>
      )}

      {props?.data?.project && (
        <p
          className="flex justify-between"
          style={{ fontSize: "14px", color: "white" }}
        >
          <span className="text-des">{t("Project")}:</span>{" "}
          <span className="text-right">
            {props?.data?.project?.name ?? props?.data?.project?.id}
          </span>
        </p>
      )}

      {props?.data?.description && (
        <p
          className="flex justify-between"
          style={{ fontSize: "14px", color: "white" }}
        >
          <span className="text-des">{t("Description")}:</span>{" "}
          <span className="text-right">{props?.data?.description}</span>
        </p>
      )}
    </>
  );
};

export default UpdateArchiveOrder;
