import * as React from "react";

import {
  readGeoJson,
  readKml,
  kmlToGeoJSON,
  readZip,
  shpToGeoJSON,
} from "./components/FileUtils";
import { useTranslation } from "react-i18next";

import { useSelector, useDispatch } from "react-redux";

import ShapeUpload from "./components/Shape";
import GeojsonUpload from "./components/Geojson";
import KmlUpload from "./components/Kml";
import { RootState } from "../../store/store";
import { showError } from "../../helpers/toast";
import {
  addFeature,
  changeActiveFeature,
} from "../../store/reducers/mapTaskingSlice";
import { Button, Tabs, TabsProps } from "antd";
import ButtonIconComp from "./ButtonIconComp";

export default function FormUpload(props: any) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const map = useSelector((state: RootState) => state.mapTasking?.maplibreGL);

  let draw: any = useSelector((state: RootState) => state.mapTasking?.draw);

  const [selectedValue, setSelectedValue] = React.useState<any>("geojson");

  const [shapeFile, setShapeFile] = React.useState<any>(null);
  const [geojsonFile, setGeojsonFile] = React.useState<any>(null);
  const [kmlFile, setKmlFile] = React.useState<any>(null);

  const onClose = () => {
    props.toggleUpload();
  };

  const onChangeShapeFile = (file: File) => {
    setShapeFile(file);
  };

  const onChangeGeojsonFile = (file: File) => {
    setGeojsonFile(file);
  };

  const onChangeKmlFile = (file: File) => {
    setKmlFile(file);
  };

  const readFile = () => {
    switch (selectedValue) {
      case "shape":
        readShapeFile(shapeFile);
        break;
      case "geojson":
        readGeojsonFile(geojsonFile);
        break;
      case "kml":
        readKmlFile(kmlFile);
        break;
    }
    onClose();
  };

  const readShapeFile = async (file: File) => {
    try {
      let zipContent = await readZip(file);
      if (zipContent) {
        //@ts-ignore
        let geojson = await shp(zipContent);
        if (geojson && geojson.features) {
          //@ts-ignore
          let feature = geojson?.features.length ? geojson?.features[0] : null;
          if (feature) {
            feature.id = selectedValue + "_" + new Date().getTime();
            addGeojson2Map(feature);
          }
        }
      }
    } catch (ex: any) {
      showError(ex);
    }
  };
  const readGeojsonFile = async (file: File) => {
    try {
      let geojson = await readGeoJson(file);

      if (geojson?.geoJSON?.features) {
        let feature = geojson?.geoJSON?.features.length
          ? geojson?.geoJSON?.features[0]
          : null;

        if (feature) {
          feature.id = selectedValue + "_" + new Date().getTime();

          addGeojson2Map(feature);
        }
      }
    } catch (ex: any) {
      showError(ex);
    }
  };
  const readKmlFile = async (file: File) => {
    try {
      let kmlContent = await readKml(file);
      if (kmlContent) {
        let geojson: any[] = kmlToGeoJSON(kmlContent);
        if (geojson && geojson.length) {
          //@ts-ignore
          let feature = geojson[0]?.features.length
            ? geojson[0]?.features[0]
            : null;
          if (feature) {
            feature.id = selectedValue + "_" + new Date().getTime();
            addGeojson2Map(feature);
          }
        }
      }
    } catch (ex: any) {
      showError(ex);
    }
  };

  const addGeojson2Map = (feature: any) => {
    if (draw) {
      try {
        draw.add(feature);
        dispatch(addFeature(feature));
        dispatch(changeActiveFeature(feature));

        FlyToPosition(feature?.geometry?.coordinates[0]);
      } catch (ex: any) {
        showError(ex);
      }
    }
  };

  const renderTabs = () => {
    const items: TabsProps["items"] = [
      {
        key: "geojson",
        label: "Geojson",
        children: (
          <GeojsonUpload
            file={geojsonFile}
            onChange={(file: File) => onChangeGeojsonFile(file)}
          />
        ),
      },
      // {
      //   key: "shape",
      //   label: "Shape",
      //   children: (
      //     <ShapeUpload
      //       file={shapeFile}
      //       onChange={(file: File) => onChangeShapeFile(file)}
      //     />
      //   ),
      // },

      {
        key: "kml",
        label: "Kml",
        children: (
          <KmlUpload
            file={kmlFile}
            onChange={(file: File) => onChangeKmlFile(file)}
          />
        ),
      },
    ];
    return (
      <>
        <Tabs
          defaultActiveKey="geojson"
          items={items}
          onChange={(e: any) => {
            setSelectedValue(e);
          }}
        />
      </>
    );
  };

  const FlyToPosition = (defaultPosition: any) => {
    if (map && defaultPosition?.length > 0) {
      const coordinates = defaultPosition;
      const bounds = coordinates?.reduce(
        (acc: any, coord: any) => {
          acc[0][0] = Math.min(acc[0][0], coord[0]);
          acc[0][1] = Math.min(acc[0][1], coord[1]);
          acc[1][0] = Math.max(acc[1][0], coord[0]);
          acc[1][1] = Math.max(acc[1][1], coord[1]);
          return acc;
        },
        [
          [Infinity, Infinity],
          [-Infinity, -Infinity],
        ]
      );

      // const maxZoomLevel = 12;
      const padding = 20;
      map.fitBounds(bounds, {
        padding: padding,
        maxZoom: 12,
      });
    }
  };

  return (
    <div>
      {renderTabs()}

      <div className="flex gap-2 justify-end">
        <Button
          ghost
          className="h-8 w-[100px] hover:!text-white rounded !text-white !border !border-solid !border-gray-500"
          onClick={() => {
            props?.toggleUpload();
          }}
        >
          {t("Cancel")}
        </Button>
        <Button
          className="h-8 w-[100px] !bg-common-activeAction !text-white border-none hover:!text-white hover:!bg-common-activeAction rounded"
          onClick={() => {
            readFile();
          }}
        >
          {t("Yes")}
        </Button>
      </div>
    </div>
  );
}
