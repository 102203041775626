import { Form, Slider } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { CheckRoleAccessControlAdmin } from "../../../helpers/roleAccessControl";
import {
  getProductTypeIdAsync,
  getProductionAsync,
} from "../../../store/reducers/orderSlice";
import CommonComponents from "../../Commons";
import { onMouseEnterItem, onMouseLeaveItem } from "../../MapTasking/ultis";
import CardItemConfirmComp from "../CardItemConfirm";
import { useTranslation } from "react-i18next";

const Step4 = (props: any) => {
  // for oneAtlas
  const dispatch = useDispatch();

  const roleAdmin = CheckRoleAccessControlAdmin();

  const [imageSelected, setImagesSelected] = useState<any>([]);

  const [productTypes, setProductTypes] = useState<any>([]);
  const [dataProduction, setDataProduction] = useState<any>([]);
  const _elevationValue = Form.useWatch(["oneatlas", "elevation"], props?.form);

  const getDataProductTypeId = async () => {
    // @ts-ignore
    let res = await dispatch(
      // @ts-ignore
      getProductTypeIdAsync({ type: "archive" })
    ).unwrap();

    setProductTypes(res?.data?.data?.productTypes ?? []);
  };

  useEffect(() => {
    if (props.form && dataProduction?.length > 0) {
      if (props?.initialValues) {
        const initialValuesObject = props.initialValues.reduce(
          (acc: any, item: any) => {
            if (item?.value) {
              acc[item.key] =
                item.key !== "elevation" ? item.value : parseInt(item.value);
            } else {
              let a = dataProduction?.filter(
                (itemF: any) => itemF?.name === item.key
              )?.[0]?.defaultValue;
              acc[item.key] = item.key !== "elevation" ? a : parseInt(a);
            }

            return acc;
          },
          {}
        );

        props.form.setFieldsValue({
          oneatlas: initialValuesObject,
        });
      } else {
        const initialValuesObject = dataProduction.reduce(
          (acc: any, item: any) => {
            if (item?.defaultValue !== undefined) {
              acc[item.name] =
                item.name !== "elevation"
                  ? item.defaultValue
                  : parseInt(item.defaultValue, 10);
            } else {
              if (item?.name === "elevation") {
                acc[item.name] = item?.range?.[0].toString();
              } else {
                acc[item.name] = null;
              }
            }
            return acc;
          },
          {}
        );

        props.form.setFieldsValue({
          oneatlas: {
            ...initialValuesObject,
            product_type_id: productTypes?.[0]?.productTypeId,
          },
        });
      }
    }
  }, [props?.initialValues, dataProduction, props.form, productTypes]);

  useEffect(() => {
    getDataProductTypeId();
  }, []);

  useEffect(() => {
    let a = props?.dataImages?.map((itemM: any) => itemM?.id);
    setImagesSelected(a);
  }, [props?.dataImages]);

  const getProduction = async () => {
    // @ts-ignore
    let res = await dispatch(getProductionAsync()).unwrap();

    setDataProduction(
      res?.data?.data?.availableOptions?.filter(
        (item: any) => item?.category === "production_option"
      ) ?? []
    );
  };

  useEffect(() => {
    getProduction();
  }, []);

  const parseOptions = useCallback((data: any) => {
    if (!data) {
      return [];
    }
    return data?.map((item: any) => {
      return {
        label: item?.label,
        value: item?.id,
      };
    });
  }, []);
  const parseOptionProductype = useCallback(() => {
    if (!productTypes) {
      return [];
    }
    return productTypes?.map((item: any) => {
      return {
        label: item?.productTypeLabel,
        value: item?.productTypeId,
      };
    });
  }, [productTypes]);

  const { t } = useTranslation();

  return (
    <div className="div-form-step-4">
      <div
        className="grid grid-cols-3 !gap-x-[20px] !gap-y-[32px] mb-4"
        style={{ color: "white" }}
      >
        {dataProduction?.map((item: any) => {
          if (!!item?.range) {
            return (
              <CommonComponents.FormFieldUI
                name={["oneatlas", item?.name]}
                component={
                  <div>
                    <p className="mb-1 text-white text-sm">
                      {t(item?.label)}
                      <span className="text-blue-500">
                        {` (${Number(_elevationValue) ?? 0})`}
                      </span>
                      {item?.mandatory === "true" && (
                        <span className="text-common-red500">{` *`}</span>
                      )}
                    </p>
                    <Slider
                      defaultValue={
                        parseInt(
                          props?.initialValues?.filter(
                            (itemF: any) => itemF?.key === "elevation"
                          )?.[0]?.value ?? item?.range?.[0]
                        ) ?? 0
                      }
                      tooltip={{
                        trigger: "hover",
                        defaultOpen: false,
                      }}
                      min={item?.range[0]}
                      max={item?.range[1]}
                      onChangeComplete={(value) => {
                        props.form.setFieldsValue({
                          oneatlas: {
                            [`${item?.name}`]: String(value),
                          },
                        });
                      }}
                      disabled={props?.readOnly}
                    />
                  </div>
                }
              />
            );
          }
          return (
            <CommonComponents.FormFieldUI
              name={["oneatlas", item?.name]}
              rules={[
                {
                  required: item?.mandatory === "true" ? true : false,
                  message: "Please select here!",
                },
              ]}
              component={
                <>
                  <CommonComponents.SelectUI
                    label={t(item?.label)}
                    // required={item?.mandatory === "true" ? true : false}
                    data={parseOptions(item?.values)}
                    disabled={props?.readOnly}
                    defaultValue={item?.defaultValue}
                  />
                </>
              }
            />
          );
        })}
        {productTypes && (
          <CommonComponents.FormFieldUI
            name={["oneatlas", "product_type_id"]}
            initialValue={productTypes?.[0]?.productTypeId}
            component={
              <CommonComponents.SelectUI
                label={t("Product Type ID")}
                placeholder={t("Product Type ID")}
                data={parseOptionProductype()}
                value={productTypes?.[0]?.productTypeId}
                disabled={props?.readOnly}
                // required

                // nameValue={"productTypeId"}
                // nameLabel="productTypeLabel"
              />
            }
            rules={[{ required: true, message: t("Please select here!") }]}
          />
        )}
      </div>

      {props?.showCardImages && (
        <>
          {props?.dataImages?.map((item: any) => (
            <CardItemConfirmComp
              item={item}
              onMouseLeaveItem={() =>
                onMouseLeaveItem(item?.feature, props.map, props.sourceId)
              }
              onMouseEnterItem={() =>
                onMouseEnterItem(item?.feature, props.map, props.sourceId)
              }
              selected={imageSelected?.includes(item?.id)}
              onClick={() => {
                props?.onClick(item?.id);
                if (imageSelected?.includes(item?.id)) {
                  setImagesSelected(
                    imageSelected?.filter((itemF: any) => itemF !== item?.id)
                  );
                } else {
                  let a = imageSelected;
                  setImagesSelected(a?.concat(item?.id));
                }
              }}
              style={{ background: "#3C4152" }}
            />
          ))}
        </>
      )}
    </div>
  );
};

export default Step4;
