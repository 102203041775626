import { Form, Switch, message } from "antd";
import { useEffect, useState } from "react";
import { HexColorPicker } from "react-colorful";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import RefreshSvg from "../../../assets/svg/refresh.svg";
import CommonComponents from "../../../components/Commons";
import { ESttausCode } from "../../../enums";
import { getErrorMsg } from "../../../helpers/common";
import { showError, showSuccess } from "../../../helpers/toast";
import useFormValidation from "../../../hooks/useFormValidation";
import ContentLayoutManagement from "../../../layouts/ContentLayoutManagement";
import { baseRoutes } from "../../../router/RouterPage";
import useGetDetailProviderConfig from "../../../services/providerConfigs/hooks/useGetDetailProviderConfig";
import providerConfigService from "../../../services/providerConfigs/providerConfigService";
import { changeDataBreadcrumb } from "../../../store/reducers/layoutSlice";
import {
  getProviderAsync,
  setArrAllProviders,
  setArrProviders,
  setProviderId,
} from "../../../store/reducers/mapTaskingSlice";
import { RootState } from "../../../store/store";
import BackSvg from "../../../assets/svg/back-arrow.svg";
import { useTranslation } from "react-i18next";
import { t } from "i18next";

interface IInitialValues {
  name: string;
  description: string;
  actived: boolean;
  type_token: string;
  enabled_tasking: boolean;
  enabled_archive: boolean;
  // is_auto_order_mode: boolean;
}
export const typeToken = [
  {
    label: "Bearer",
    value: "Bearer",
  },
  {
    label: "Basic",
    value: "Basic",
  },
];

const ProviderBranchUpdate = () => {
  const { t } = useTranslation();
  //! state
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  let typeImage = useSelector((state: RootState) => state.mapTasking.typeImage);
  const rule = searchParams.get("rule");
  const { data, isLoading } = useGetDetailProviderConfig(Number(id));
  const dataProvider = data?.data;
  const [form] = Form.useForm();
  const { provider } = useFormValidation();
  const [imageUrl, setImageUrl] = useState<string>();
  const [color, setColor] = useState<string>();
  const initialValues: IInitialValues = {
    name: dataProvider?.name ?? "",
    description: dataProvider?.description ?? "",
    actived: dataProvider?.actived ?? false,
    type_token: dataProvider?.type_token ?? "",
    enabled_tasking: dataProvider?.enabled_tasking ?? false,
    enabled_archive: dataProvider?.enabled_archive ?? false,
    // is_auto_order_mode: dataProvider?.is_auto_order_mode ?? false,
  };

  const listSwitch = [
    { label: t("Active"), name: "actived" },
    { label: t("Enable Tasking"), name: "enabled_tasking" },
    { label: t("Enable Archive"), name: "enabled_archive" },
    // { label: "Enable auto", name: "is_auto_order_mode" },
  ];
  //! function
  const checkFileSize = (file: File) => {
    const isLt2M = file?.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isLt2M;
  };

  const uploadFile = async (event: any) => {
    const reader = new FileReader();
    const file = event.target.files[0];
    checkFileSize(file);
    reader.readAsDataURL(file);
    reader.onload = () => {
      setImageUrl(reader.result as any);
    };
  };
  const handleCancel = () => {
    navigate(baseRoutes.providerBranch);
  };

  const handleSubmit = async () => {
    let values: IInitialValues = form.getFieldsValue();
    try {
      const metaDataClone = dataProvider?.metadatas
        ? [...dataProvider?.metadatas]
        : [];
      let metaDataBody: any = [];
      let indexLogo = metaDataClone.findIndex((item) => item.key === "LOGO");
      let indexColor = metaDataClone.findIndex(
        (item) => item.key === "COLOR_NAME"
      );
      if (indexColor > -1) {
        metaDataClone[indexColor].value = String(color);
        metaDataBody = metaDataClone;
      } else {
        metaDataClone.push({
          key: "COLOR_NAME",
          value: String(color),
          name: "COLOR_NAME",
          label: "This is color name",
          type: "string",
          description: "string",
        });
        metaDataBody = metaDataClone;
      }
      if (indexLogo > -1) {
        metaDataClone[indexLogo].value = String(imageUrl);
        metaDataBody = metaDataClone;
      } else {
        metaDataClone.push({
          key: "LOGO",
          value: String(imageUrl),
          name: "string",
          label: "string",
          type: "string",
          description: "string",
        });
        metaDataBody = metaDataClone;
      }

      const body = {
        name: values.name,
        description: values.description,
        actived: values.actived,
        type_token: values.type_token,
        metadatas: metaDataBody,
        enabled_tasking: values.enabled_tasking,
        enabled_archive: values.enabled_archive,
        // is_auto_order_mode: values.is_auto_order_mode,
      };

      const result = await providerConfigService.updateProvider(
        Number(id),
        body
      );
      if (
        result.status === ESttausCode.OK ||
        result.status === ESttausCode.CREATED
      ) {
        showSuccess(t("Edit provider successfully"));

        // @ts-ignore
        let resProvider = await dispatch(getProviderAsync()).unwrap();

        dispatch(
          setArrAllProviders(
            resProvider?.data?.data?.content_page?.filter(
              (item: any) => item?.actived
            )
          )
        );

        let providers = resProvider?.data?.data?.content_page
          ?.filter((item: any) => item?.actived)
          ?.filter((itemF: any) =>
            itemF?.metadatas?.some(
              (itemMF: any) =>
                itemMF?.name === "IMAGE_TYPE_SEARCH" &&
                itemMF?.value === typeImage
            )
          );

        dispatch(setArrProviders(providers ?? []));

        dispatch(setProviderId(providers?.[0]?.id));
        navigate(baseRoutes.providerBranch);
      }
    } catch (error) {
      showError(t(getErrorMsg(error)));
    }
  };

  //! effect
  useEffect(() => {
    form.setFieldsValue(initialValues);

    if (dataProvider?.metadatas.find((item) => item.key === "LOGO")) {
      const imgUrl = dataProvider?.metadatas.find(
        (item) => item.key === "LOGO"
      )?.value;
      setImageUrl(imgUrl);
    }

    if (dataProvider?.metadatas.find((item) => item.key === "COLOR_NAME")) {
      const colorString = dataProvider?.metadatas.find(
        (item) => item.key === "COLOR_NAME"
      )?.value;
      setColor(colorString);
    }
  }, [isLoading]);

  useEffect(() => {
    dispatch(
      changeDataBreadcrumb([
        { title: t("Providers"), path: baseRoutes.providerBranch },
        { title: rule !== "edit" ? t("Information") : t("Edit") },
      ])
    );
    return () => {
      dispatch(changeDataBreadcrumb([]));
    };
  }, []);
  //! render
  const renderListSwitch = () => {
    return listSwitch.map((item, index) => {
      return (
        <div
          className="w-full h-24 border border-gray-700 rounded-md px-2 pt-1 pb-4"
          key={index}
        >
          <div className="text-white">{item.label}</div>
          <div className="flex justify-center items-center">
            <CommonComponents.FormFieldUI
              className="mt-3"
              name={item.name}
              valuePropName="checked"
              component={<Switch disabled={rule !== "edit"} />}
            />
          </div>
        </div>
      );
    });
  };
  return (
    <ContentLayoutManagement
      header={
        <div hidden={rule !== "edit"} className="!pr-2 w-full">
          <div className="flex justify-between items-center w-full">
            <CommonComponents.ButtonUI
              className="btn-cancel "
              onClick={handleCancel}
              icon={<img src={BackSvg} alt="BackIcon" />}
            ></CommonComponents.ButtonUI>

            <CommonComponents.ButtonUI
              htmlType="submit"
              className="btn-save "
              onClick={handleSubmit}
            >
              {t("Save")}
            </CommonComponents.ButtonUI>
          </div>
        </div>
      }
    >
      <Form
        initialValues={initialValues}
        form={form}
        // onFinish={handleSubmit}
        layout="vertical"
        className="div-form-small"
      >
        <CommonComponents.FormFieldUI
          name="name"
          rules={provider.name()}
          component={
            <CommonComponents.InputUI
              required
              label={t("Provider name")}
              placeholder={t("Provider name")}
              disabled={rule !== "edit"}
            />
          }
        />

        <CommonComponents.FormFieldUI
          name="description"
          component={
            <CommonComponents.TextAreaUI
              label={t("Description")}
              placeholder={t("Description")}
              disabled={rule !== "edit"}
            />
          }
        />

        <CommonComponents.FormFieldUI
          name="type_token"
          component={
            <CommonComponents.SelectUI
              options={typeToken}
              label={t("Type Token")}
              placeholder={t("Select type token")}
              disabled={rule === "edit"}
            />
          }
        />
        <div className="flex w-full gap-8">
          <div className="w-1/5 border border-gray-700 rounded-md flex flex-col justify-center items-center">
            {imageUrl && (
              <img
                src={imageUrl}
                alt="change avatar"
                className="size-[100px] object-contain"
              />
            )}

            <div className="w-[100px]">
              <label
                htmlFor="file-upload"
                className="custom-file-upload div-btn-upload !flex !flex-col !items-center !justify-center !text-center"
              >
                {t("Upload")}
              </label>
              <input
                id="file-upload"
                type="file"
                name="file"
                onChange={uploadFile}
                accept="image/png, image/jpeg"
                disabled={rule !== "edit"}
              />
            </div>
          </div>
          <div className="w-4/5">
            <CommonComponents.FormFieldUI
              noStyle
              name="primary_color"
              component={
                <>
                  <HexColorPicker
                    className="!w-full"
                    color={color}
                    onChange={(val: any) => {
                      setColor(val);
                      form.setFieldValue("primary_color", val);
                    }}
                  />
                  <CommonComponents.InputUI
                    className="mt-2"
                    value={color}
                    suffix={<img src={RefreshSvg} />}
                    disabled={rule !== "edit"}
                  />
                </>
              }
            />
          </div>
        </div>
        <div className="flex gap-3 mt-4">{renderListSwitch()}</div>

        {/* <CommonComponents.FormAntdDebugs /> */}
      </Form>
    </ContentLayoutManagement>
  );
};

export default ProviderBranchUpdate;
