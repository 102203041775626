import { useCallback, useEffect, useState } from "react";
import CommonComponents from "../Commons";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { setRoleUsing } from "../../store/reducers/appCommonSlice";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const SwitchRole = ({ toggle }: { toggle: () => void }) => {
  //! state
  const dispatch = useDispatch();
  const roleUsing = useSelector(
    (state: RootState) => state.appCommonSlice.roleUsing
  );
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [roleChoosing, setRoleChoosing] = useState("");
  //! function
  const handleChangeRole = () => {
    dispatch(setRoleUsing([roleChoosing]));
    toggle();
    navigate("/");
  };
  //! effect
  useEffect(() => {
    if (roleUsing) {
      setRoleChoosing(roleUsing[0]);
    }
  }, [roleUsing]);
  //!render
  const renderRoleSwitch = useCallback(() => {
    const groupSwitch = ["admin", "user"];
    return groupSwitch?.map((role, index) => {
      const active = roleChoosing === role;
      return (
        <div
          className={`text-center leading-[40px] p-2 border border-common-ink300 rounded min-h-10 cursor-pointer uppercase ${
            active ? "border-common-primary300" : ""
          }`}
          key={index}
          onClick={() => {
            setRoleChoosing(role);
          }}
        >
          {t(role)}
        </div>
      );
    });
  }, [roleUsing, roleChoosing]);

  return (
    <div>
      <div className="grid grid-cols-2 gap-6">{renderRoleSwitch()}</div>

      <div className="flex justify-end items-center gap-3 mt-7">
        <CommonComponents.ButtonUI
          ghost
          className="h-8 w-[100px] hover:!text-white rounded !text-white !border !border-solid !border-gray-500"
          onClick={toggle}
        >
          {t("No")}
        </CommonComponents.ButtonUI>
        <CommonComponents.ButtonUI
          onClick={handleChangeRole}
          className="h-8 w-[100px] !bg-common-activeAction !text-white border-none hover:!text-white hover:!bg-common-activeAction rounded"
        >
          {t("Yes")}
        </CommonComponents.ButtonUI>
      </div>
    </div>
  );
};

export default SwitchRole;
