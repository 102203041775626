import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSegmentsByIdOrderAsync } from "../../../store/reducers/orderSlice";
import { RootState } from "../../../store/store";
import CommonComponents from "../../Commons";
import { useParams, useSearchParams } from "react-router-dom";
import moment from "moment";
import { t } from "i18next";

const Step1 = (props: any) => {
  const selectedPolygon = useSelector(
    (state: RootState) => state.mapTasking.activeFeature
  );
  const [searchParams] = useSearchParams();
  const statusUrgent = searchParams?.get("urgent");
  const dispatch = useDispatch();

  const [valueSensor, setValueSensor] = useState("Pléiades");
  const [dataSegments, setDataSegments] = useState<any>([]);
  const [heightSelect, setHeightSelect] = useState<any>("32px");

  const [valStartDate, setValueStartData] = useState<any>(undefined);
  const [valEndDate, setValueEndData] = useState<any>(undefined);
  const [valuePlan, setValuePlan] = useState("");
  const valuesForm = useSelector(
    (state: RootState) => state.orderSlice.valuesForm
  );

  const showOrderTasking = useSelector(
    (state: RootState) => state.orderSlice.showOrderTasking
  );

  const getSegments = async (pay: any) => {
    // @ts-ignore
    let res = await dispatch(getSegmentsByIdOrderAsync(pay)).unwrap();

    setDataSegments(
      res?.data?.data?.progCapacities?.[0]?.progTypes?.[0]?.segments ?? []
    );
  };
  const initialValueReason = () => {
    if (statusUrgent) {
      return "Order urgent now";
    }
    if (pathHref === "/tasking-order") {
      return "Order Tasking";
    } else {
      return "Order Archive";
    }
  };

  useEffect(() => {
    setValuePlan(valuesForm?.plan);
    setValueSensor(valuesForm?.sensor ?? "Pléiades");
    setValueEndData(valuesForm?.end_date_request);
    setValueStartData(valuesForm?.start_date_request);

    setHeightSelect(valuesForm?.production?.acquisition ? "100px" : "32px");
  }, [valuesForm]);

  useEffect(() => {
    if (valStartDate || valEndDate) {
      getSegments({
        progTypeNames: valuePlan,
        missions: valueSensor,
        acquisitionDate: {
          startDate: valStartDate,
          endDate: valEndDate,
        },
        optical: {
          acquisitionMode: "MONO",
          maxCloudCover: 25,
          maxIncidenceAngle: 30,
        },
        aoi: selectedPolygon?.geometry,
      });
    }
  }, [valStartDate, valuePlan]);

  const pathHref = window.location.pathname;

  console.log(
    props?.dataPriority?.sort(
      (a: any, b: any) => a.priority_view - b.priority_view
    ),
    "dataPriority"
  );
  return (
    <div className={props?.className}>
      <CommonComponents.FormFieldUI
        name="name"
        initialValue={
          statusUrgent
            ? `Order urgent ${moment().format("HH:mm DD/MM/YYYY")}`
            : `${
                pathHref === "/tasking-order"
                  ? "Order tasking"
                  : "Order Archive"
              } ${moment().format("HH:mm DD/MM/YYYY")}`
        }
        component={
          <CommonComponents.InputUI
            label={t("Order name")}
            placeholder={t("Order name")}
            // required
          />
        }
        rules={[{ required: true, message: t("Please input here!") }]}
      />

      <div className="grid grid-cols-1 gap-2">
        {props?.dataPriority?.length > 0 && (
          <CommonComponents.FormFieldUI
            name={"priority_id"}
            initialValue={
              statusUrgent
                ? props?.dataPriority?.filter(
                    (itemF: any) => itemF?.value?.toLowerCase() === "urgent"
                  )?.[0]?.id
                : props?.dataPriority?.[0]?.id
            }
            component={
              <CommonComponents.SelectUI
                label={t("Priority")}
                placeholder={t("Priority")}
                disabled={Boolean(statusUrgent)}
                defaultValue={
                  statusUrgent
                    ? props?.dataPriority?.filter(
                        (itemF: any) => itemF?.value?.toLowerCase() === "urgent"
                      )?.[0]?.id
                    : props?.dataPriority?.[0]?.id
                }
                // required
                data={
                  props?.dataPriority?.sort(
                    (a: any, b: any) => a.priority_view - b.priority_view
                  ) || []
                }
                nameValue="id"
              />
            }
            rules={[{ required: true, message: t("Please input here!") }]}
          />
        )}

        {/* <CommonComponents.FormFieldUI
          name={"project_id"}
          component={
            <CommonComponents.SelectUI
              label={t("Project")}
              placeholder="Select project"
              data={props?.datProject || []}
              nameValue="id"
            />
          }
        /> */}
      </div>

      <div className="grid grid-cols-2 gap-2">
        <CommonComponents.FormFieldUI
          name="reason"
          rules={[{ required: true, message: t("Please input here!") }]}
          initialValue={initialValueReason()}
          component={
            <CommonComponents.TextAreaUI
              label={t("Reason")}
              placeholder={t("Reason")}
              // required
              rows={3}
            />
          }
          noStyle
        />

        <CommonComponents.FormFieldUI
          name="description"
          component={
            <CommonComponents.TextAreaUI
              label={t("Description")}
              placeholder={t("Description")}
              rows={3}
            />
          }
          noStyle
        />
      </div>
    </div>
  );
};

export default Step1;
