import { Form, Spin } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import BackSvg from "../../assets/svg/back-arrow.svg";
import EmptySvg from "../../assets/svg/empty.svg";
import ConfirmOrderComp from "../../components/BookingImage/ConfirmOrder";
import CommonComponents from "../../components/Commons";
import { ESttausCode } from "../../enums";
import { getErrorMsg } from "../../helpers/common";
import { showError, showSuccess } from "../../helpers/toast";
import useToggle from "../../hooks/useToggle";
import ContentLayoutManagement from "../../layouts/ContentLayoutManagement";
import {
  getImgsAsync,
  setArrIdImage,
  setSelectedImages,
  setSelectedImagesConfirm,
} from "../../store/reducers/cartSlice";
import {
  resetAllDrawer,
  resetLeftDrawer,
} from "../../store/reducers/drawerSlice";
import {
  changeDataBreadcrumb,
  changeLayoutName,
} from "../../store/reducers/layoutSlice";
import {
  createOrderAsync,
  getPriorityAsync,
} from "../../store/reducers/orderSlice";
import { getMyProjectsAsync } from "../../store/reducers/projectSlice";
import { RootState } from "../../store/store";

const ConfirmArchiveOrderPage = (props: any) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedImages = useSelector(
    (state: RootState) => state.cartSlice.selectedImagesConfirm
  );

  const { t } = useTranslation();
  const {
    open: openPolicy,
    toggle: togglePolicy,
    shouldRender: shouldRenderPolicy,
  } = useToggle();

  const [dataPriority, setDataPriority] = useState<any>([]);
  const [arrProjects, setArrProjects] = useState<any>([]);
  const [flagConfirm, setflagConfirm] = useState<any>(false);
  const [policy, setPolicy] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  useEffect(() => {
    dispatch(changeLayoutName("/confirm-order-archive"));
    dispatch(
      changeDataBreadcrumb([
        {
          title: (
            <span
              className="cursor-pointer"
              onClick={() => navigate("/order/management-order")}
            >
              {t("Existing Image Orders")}
            </span>
          ),
        },
      ])
    );
    dispatch(resetAllDrawer());
    return () => {
      dispatch(changeLayoutName(""));
      dispatch(changeDataBreadcrumb([]));
    };
  }, []);

  useEffect(() => {
    if (flagConfirm) {
      dispatch(
        changeDataBreadcrumb([
          {
            title: (
              <span
                className="cursor-pointer"
                onClick={() => {
                  setflagConfirm(false);
                  navigate("/order/management-order");
                }}
              >
                {t("Existing Image Orders")}
              </span>
            ),
          },
          { title: t("Confirm") },
        ])
      );
    } else if (!flagConfirm && selectedImages?.length > 0) {
      dispatch(
        changeDataBreadcrumb([
          {
            title: (
              <span
                className="cursor-pointer"
                onClick={() => {
                  setflagConfirm(false);
                  navigate("/order/management-order");
                }}
              >
                {t("Existing Image Orders")}
              </span>
            ),
          },
          { title: t("Information") },
        ])
      );
    }
  }, [flagConfirm, selectedImages]);

  useEffect(() => {
    handleGetPriority();
    getDataProject();
  }, []);

  const handleGetPriority = async () => {
    // @ts-ignore
    const res = await dispatch(getPriorityAsync()).unwrap();

    setDataPriority(
      res?.data?.data?.content_page?.filter(
        (itemF: any) => itemF?.key === "PRIORITY_LEVEL"
      ) ?? []
    );
  };

  const getDataProject = async () => {
    // @ts-ignore
    let res = await dispatch(
      // @ts-ignore
      getMyProjectsAsync({ size: 1000, page: 0 })
    ).unwrap();

    setArrProjects(res?.data?.data?.content_page ?? []);
  };

  const getCart = async () => {
    // @ts-ignore
    const res = await dispatch(getImgsAsync({})).unwrap();

    if (res?.status === ESttausCode.OK) {
      let images = res?.data?.data?.images ?? [];

      let arr: any = [];

      images?.forEach((itemM: any) => {
        const updatedFeatures = itemM?.images?.map((feature: any) => ({
          ...feature,
          provider_id: itemM.provider_id,
          provider_name: itemM.provider_name,
          color_provider_name: itemM?.color_provider_name ?? "",
        }));

        arr = arr.concat(updatedFeatures);
      });

      dispatch(
        setArrIdImage(
          arr.map(
            (itemM: any) =>
              itemM?.feature?.properties[
                `${itemM.provider_name.toLowerCase()}:id`
              ]
          )
        )
      );
    }
  };

  const handleSubmit = async () => {
    try {
      setLoadingSubmit(true);
      togglePolicy();
      let values = form.getFieldsValue();

      const uuidOneatlat = uuidv4();
      const imageOneatlas = selectedImages?.filter(
        (item: any) => item?.provider_name === "Oneatlas"
      );
      const idIamgeOneatlas = imageOneatlas?.map((item: any) => {
        return item?.image_id;
      });

      // return;huyềnmèo
      let pay = {
        name: values?.name,
        description: values?.description,
        priority_id: values?.priority_id,
        reason: values?.reason,

        capella: {
          items:
            values?.capella && Object.keys(values?.capella)?.length > 0
              ? Object.entries(values?.capella).map(
                  ([image_id, details]: any) => ({
                    collectionId: details?.collection_id,
                    granuleId: image_id,
                  })
                )
              : [],
        },

        planet: {
          products:
            values?.dataPlanet?.products &&
            Object.keys(values?.dataPlanet?.products)?.length > 0
              ? Object.entries(values?.dataPlanet?.products).map(
                  ([key, details]: any) => ({
                    product_bundle: details?.productBundle,
                    item_type: key,
                    item_ids: details?.imageId ?? [],
                  })
                )
              : [],
        },
        oneatlas:
          imageOneatlas.length > 0
            ? {
                aoi: [
                  {
                    geometry: imageOneatlas[0]?.aoi_searching?.[0],
                    id: uuidOneatlat,
                    name: "string",
                  },
                ],
                items: [
                  {
                    aoiId: uuidOneatlat,
                    id: idIamgeOneatlas,
                    productTypeId: values?.oneatlas?.product_type_id,
                  },
                ],
                optionsPerProductType: [
                  {
                    options: values?.oneatlas
                      ? Object?.keys(values?.oneatlas)
                          ?.filter((key) => key !== "product_type_id")
                          ?.map((key) => ({
                            key: key,
                            value: values?.oneatlas[key],
                            // label: key,
                          }))
                      : {},

                    productTypeId: values?.oneatlas?.product_type_id,
                  },
                ],

                // product_type_id: values?.oneatlas?.product_type_id,
                // image_ids: selectedImages
                //   ?.filter((item: any) => item?.provider?.name === "oneatlas")
                //   ?.map((itemM: any) => itemM?.image_id),
              }
            : undefined,
      };

      // @ts-ignore
      const res = await dispatch(createOrderAsync(pay)).unwrap();

      if (
        res?.status === ESttausCode.OK ||
        res?.status === ESttausCode.CREATED
      ) {
        dispatch(setSelectedImagesConfirm([]));
        dispatch(setSelectedImages([]));
        getCart();
        showSuccess(t("Order successfully"));

        dispatch(resetLeftDrawer());
        navigate(`/order/management-order/${res?.data?.data?.id}`);
      } else {
        showError(res?.message ?? t("Order failed"));
      }
    } catch (error) {
      showError(t(getErrorMsg(error)));
    } finally {
      setLoadingSubmit(false);
    }
  };

  const handleVerifyPolicy = () => {
    togglePolicy();
  };

  return (
    <Form
      initialValues={{ dataPlanet: null }}
      form={form}
      className="w-full"
      onFinish={handleVerifyPolicy}
    >
      <ContentLayoutManagement
        header={
          selectedImages?.length > 0 ? (
            <div className="flex justify-between w-full pr-6">
              <CommonComponents.ButtonUI
                commonType="standard"
                className="btn-cancel hover:bg-[#3E4353] !text-xs !h-7"
                onClick={() => {
                  setflagConfirm(false);
                  navigate(-1);
                }}
                icon={<img src={BackSvg} alt="backSvg" />}
              ></CommonComponents.ButtonUI>
              <CommonComponents.ButtonUI
                className="!text-xs !h-[27px] btn-save"
                // onClick={() => handleSubmit()}
                htmlType="submit"
                disabled={loadingSubmit}
              >
                {t("Create order")}
              </CommonComponents.ButtonUI>
            </div>
          ) : null
        }
      >
        {selectedImages?.length > 0 ? (
          <Spin
            spinning={loadingSubmit}
            style={{
              minHeight: "calc(100vh - 56px - 56px - 90px)",
              height: "100%",
            }}
          >
            <div
              className={
                "text-white overflow-hidden div-page-default !justify-between"
              }
              style={{
                minHeight: "calc(100% - 48px)",
              }}
            >
              <ConfirmOrderComp
                form={form}
                data={{
                  name: "",
                  priority: dataPriority,
                  project: [], // here get from api
                  description: "",
                }}
                onCancel={() => setflagConfirm(false)}
              />
            </div>
          </Spin>
        ) : (
          <div className="div-one-center h-full">
            <div className="div-center-col">
              <img src={EmptySvg} />
              <p className="text-white">
                {t("No selected image in your cart")}
              </p>
            </div>
          </div>
        )}

        {shouldRenderPolicy && (
          <CommonComponents.ModalUI
            open={openPolicy}
            toggle={togglePolicy}
            title={t("Privacy Policy & Term")}
            handleConfirm={handleSubmit}
            disabled={!policy}
            loading={loadingSubmit}
          >
            <div>
              <CommonComponents.CheckboxUI
                checked={policy}
                onChange={() => {
                  setPolicy(!policy);
                }}
                className="text-white"
              >
                <span>
                  {t("Check here to indicate you have read and agree to the")}{" "}
                </span>
                <Link
                  to={`https://geohub.ai/dieu-khoan-va-dieu-kien-su-dung-dich-vu`}
                  target={"_blank"}
                  className="underline underline-offset-4 cursor-pointer text-common-blue400 hover:!text-common-blue500 hover:!underline"
                >
                  {t("Terms and Conditions of Geohub")}
                </Link>
              </CommonComponents.CheckboxUI>
            </div>
          </CommonComponents.ModalUI>
        )}
      </ContentLayoutManagement>
    </Form>
  );
};

export default ConfirmArchiveOrderPage;
