import { Editor } from "@monaco-editor/react";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import CommonComponents from "../../components/Commons";
import LoadingUI from "../../components/Commons/Loading";
import { ESttausCode } from "../../enums";
import { getErrorMsg } from "../../helpers/common";
import { showError, showSuccess } from "../../helpers/toast";
import ContentLayoutManagement from "../../layouts/ContentLayoutManagement";
import { baseRoutes } from "../../router/RouterPage";
import basemapService from "../../services/basemap/basemapService";
import useGetBasemap from "../../services/basemap/hooks/useGetBasemap";
import { changeDataBreadcrumb } from "../../store/reducers/layoutSlice";
import BackSvg from "../../assets/svg/back-arrow.svg";
import { useTranslation } from "react-i18next";

const SettingDataBaseMap = () => {
  //! state
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const editorRef = useRef<any>(null);
  const { data: dataBaseMap, isLoading } = useGetBasemap();
  const [loadingSave, setLoadingSave] = useState(false);
  //! function
  function handleEditorDidMount(editor: any, monaco: any) {
    editorRef.current = editor;
    // editor.getAction("editor.action.formatDocument").run();
  }

  const { t } = useTranslation();

  const handleSave = async () => {
    if (editorRef.current) {
      const body = editorRef?.current.getValue();

      try {
        JSON.parse(body);
      } catch (e) {
        showError(t("Format invalid JSON"));

        return;
      }

      try {
        setLoadingSave(true);
        const results = await basemapService.PostBasemap({
          configs: JSON.parse(body),
        });
        if (results.status == ESttausCode.OK || ESttausCode.CREATED) {
          showSuccess(t("Update baseMap successfully"));
        }
      } catch (error) {
        showError(t(getErrorMsg(error)));
      } finally {
        setLoadingSave(false);
      }
    }
  };
  const handleCancel = () => {
    navigate(baseRoutes.basemapSetting);
  };
  //! effect

  // useEffect(() => {
  //   if (dataBaseMap?.data?.configs) {
  //     setTimeout(() => {
  //       editorRef?.current.getAction("editor.action.formatDocument").run();
  //     }, 300);
  //   }
  // }, [isLoading]);
  useEffect(() => {
    dispatch(
      changeDataBreadcrumb([
        { title: t("BaseMap"), path: baseRoutes.basemapSetting },
        { title: t("Edit") },
      ])
    );
    return () => {
      dispatch(changeDataBreadcrumb([]));
    };
  }, []);

  //! render
  const renderBodyUI = () => {
    if (isLoading) {
      return <LoadingUI />;
    }
    return (
      <Editor
        height="80vh"
        defaultLanguage="json"
        value={JSON.stringify(dataBaseMap?.data?.configs, null, 2)}
        // defaultValue={JSON.stringify(dataBaseMap?.data?.configs)}
        onMount={handleEditorDidMount}
      />
    );
  };
  return (
    <ContentLayoutManagement
      header={
        <div className="!pr-2 w-full">
          <div className="flex justify-between w-full gap-2">
            <CommonComponents.ButtonUI
              commonType="default"
              className="btn-cancel "
              onClick={handleCancel}
              disabled={loadingSave}
              loading={loadingSave}
              icon={<img src={BackSvg} alt="BackIcon" />}
            ></CommonComponents.ButtonUI>
            <CommonComponents.ButtonUI
              htmlType="submit"
              className="h-[28px] btn-save"
              disabled={loadingSave}
              loading={loadingSave}
              onClick={handleSave}
            >
              {t("Save")}
            </CommonComponents.ButtonUI>
          </div>
        </div>
      }
    >
      <div className="relative w-full h-full p-6 bg-content-default">
        {renderBodyUI()}
      </div>
    </ContentLayoutManagement>
  );
};

export default SettingDataBaseMap;
