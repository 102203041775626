import { ReactNode } from "react";
import MenuBar from "../components/MenuBar";

import { CheckRoleAccessControlAdmin } from "../helpers/roleAccessControl";

import LoadingUI from "../components/Commons/Loading";
import ListAppComp from "../components/ListApp.tsx";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store/store";
import { changeVisibleListApp } from "../store/reducers/appCommonSlice";

export default function PageLayout({ children }: { children: ReactNode }) {
  const dispatch = useDispatch();

  const roleUsing = CheckRoleAccessControlAdmin();
  if (roleUsing && roleUsing?.length === 0) {
    return <LoadingUI />;
  }

  return (
    <div className=" w-[100vw] h-[100vh] top-0 left-0  fixed div-layout">
      <div className="flex content">
        <ListAppComp />

        <div className="flex-none w-fit opacity-[0.9]">
          <MenuBar roleUsing={roleUsing} />
        </div>

        <div
          className="grow relative opacity-[0.9]"
          // onClick={() => dispatch(changeVisibleListApp(false))}
        >
          {children}
        </div>
      </div>
    </div>
  );
}
