import { Button } from "antd";
import BackSvg from "../../../assets/svg/back.svg";
import FilterSvg from "../../../assets/svg/filter.svg";
import NextSvg from "../../../assets/svg/next.svg";
import SortSvg from "../../../assets/svg/sort.svg";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { setPageNumber } from "../../../store/reducers/appCommonSlice";
import ButtonIconComp from "../../MapTasking/components/ButtonIcon";
import {
  changeLeftDrawerType,
  changeShowDrawer,
} from "../../../store/reducers/drawerSlice";
import { useTranslation } from "react-i18next";

const PagingComp = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const showPaging = useSelector(
    (state: RootState) => state.appCommonSlice.showPaging
  );

  const showFilter = useSelector(
    (state: RootState) => state.appCommonSlice.showFilter
  );

  const showSort = useSelector(
    (state: RootState) => state.appCommonSlice.showSort
  );

  const totalPage = useSelector(
    (state: RootState) => state.appCommonSlice.totalPage
  );

  const pageNumber = useSelector(
    (state: RootState) => state.appCommonSlice.pageNumber
  );

  const showDrawer = useSelector(
    (state: RootState) => state.drawerSlice.showDrawer
  );

  const typeLeftDrawer = useSelector(
    (state: RootState) => state.drawerSlice.leftDrawerType
  );
  return (
    <div className="flex items-center gap-2 justify-end">
      {showPaging && (
        <div className="flex justify-end items-center">
          <ButtonIconComp
            item={{
              description: t("Previous"),
              icon: BackSvg,
            }}
            className="!h-7 !w-[30px] !min-w-[30px] !rounded !bg-[#272D3C] "
            disabled={pageNumber === 0}
            onClick={() => {
              dispatch(setPageNumber(pageNumber - 1));
            }}
          />

          <span className="m-2">
            {pageNumber + 1} / {totalPage}
          </span>

          <ButtonIconComp
            item={{
              description: t("Next"),
              icon: NextSvg,
            }}
            className="!h-7 !w-[30px] !min-w-[30px] !rounded !bg-[#272D3C]"
            disabled={pageNumber + 1 >= totalPage}
            onClick={() => {
              dispatch(setPageNumber(pageNumber + 1));
            }}
          />
        </div>
      )}

      {showFilter && (
        <ButtonIconComp
          item={{
            description: t("Filter"),
            icon: FilterSvg,
          }}
          classNameImage="!w-4"
          className={
            `!h-7 !w-[30px] !min-w-[30px] !rounded !bg-[#272D3C] ` +
            (showDrawer && typeLeftDrawer === "Filter archive oder by admin"
              ? "active"
              : "")
          }
          onClick={() => {
            if (
              showDrawer &&
              typeLeftDrawer === "Filter archive oder by admin"
            ) {
              dispatch(changeShowDrawer(false));
              dispatch(changeLeftDrawerType(""));
            } else {
              dispatch(changeShowDrawer(true));
              dispatch(changeLeftDrawerType("Filter archive oder by admin"));
            }
          }}
        />
      )}

      {showSort && (
        <Button className="btn-action-icon" icon={<img src={SortSvg} />} />
      )}
    </div>
  );
};

export default PagingComp;
