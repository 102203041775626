import React, { useEffect, useState } from "react";
import { Checkbox } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import CommonIcons from "../../../assets/icons";
import CommonComponents from "..";
import CommonImages from "../../../assets/images";
import dayjs from "dayjs";
import { ShoppingCartOutlined } from "@ant-design/icons";
import { DefaultFormatDateTime } from "../../../enums";
import DefaultImg from "../../../assets/images/default.png";
import { ConvertTimeStamp } from "../../../helpers/common";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";

interface IProps {
  showCheckBox?: boolean;
  defaultChecked?: boolean;
  checked?: boolean;
  onSelect?: any;
  img?: string;
  imgObject?: any;
  onShowInMap?: any;
  status?: boolean; // for check show in map
  onMouseLeaveItem?: (item: any) => void;
  onMouseEnterItem?: (item: any) => void;
  panToAndExtend?: (e: any, item: any) => void;
  onClickItem?: (item?: any, event?: any) => void;
  handleAddCart?: any;
  handleDelCart?: any;
  flagInCart?: boolean;
}

export default function SearchResultItemUI({
  showCheckBox = false,
  defaultChecked = false,
  checked = false,
  onSelect,
  img,
  imgObject,
  onShowInMap,
  status,
  onMouseLeaveItem,
  onMouseEnterItem,
  panToAndExtend,
  onClickItem,
  handleAddCart,
  handleDelCart,
  flagInCart,
}: IProps) {
  const date_time_format =
    DefaultFormatDateTime.DATE_FORMAT +
    " " +
    DefaultFormatDateTime.TIME_FORMAT +
    " " +
    "UTC";
  const [_checked, setChecked] = useState<boolean>(defaultChecked || checked);

  useEffect(() => {
    if (defaultChecked === undefined) {
      setChecked(checked);
    }
  }, [checked]);

  const onChange = (e: CheckboxChangeEvent) => {
    e.stopPropagation();
    setChecked(e.target.checked);

    if (onSelect) {
      onSelect(e, e.target.checked);
    }
  };

  const downloadImage = (url?: any) => {
    if (url) {
      const imageUrl = url;
      const link = document.createElement("a");
      link.href = imageUrl;
      link.download = "downloaded_image.jpg"; // Tên file khi tải xuống
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const { maplibreGL } = useSelector((state: RootState) => state.mapSlice);

  return (
    <div
      className="w-full flex items-center p-3 bg-common-background gap-3 !h-[128px] !rounded-[3px] cursor-pointer"
      onMouseEnter={(e) => {
        // @ts-ignore
        onMouseEnterItem(imgObject);
      }}
      onMouseLeave={(e) => {
        // @ts-ignore
        onMouseLeaveItem([imgObject]);
      }}
      // @ts-ignore
      onClick={(event) => onClickItem(imgObject, event)}
    >
      {showCheckBox ? (
        <div
          className="flex-none w-fit"
          onClick={(e) => {
            e?.stopPropagation();
          }}
        >
          <Checkbox checked={_checked} onChange={(e: any) => onChange(e)} />
        </div>
      ) : null}

      <div className="grow flex items-center gap-3">
        <div
          className="flex-none !w-[100px] !h-[100px] bg-black rounded-[3px]"
          style={{ border: "1px solid #FFFFFF33" }}
        >
          <img
            src={img}
            alt=""
            className="!w-[100px] !h-[100px] object-contain"
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = DefaultImg;
            }}
          />
        </div>

        <div className="grow h-full flex flex-col">
          <div className="text-base font-semibold text-common-white break-words break-all !mb-1 line-clamp-1">
            {imgObject?.id}
          </div>

          <div className="flex justify-between items-center text-sm font-normal text-common-white">
            <div className="text-one-line">
              <CommonComponents.TooltipUI
                title={
                  imgObject?.properties?.datetime
                    ? imgObject?.properties?.datetime
                    : ""
                }
              >
                {ConvertTimeStamp(
                  imgObject?.properties?.datetime
                    ? imgObject?.properties?.datetime
                    : ""
                )}
                {imgObject?.collection && (
                  <span> | {imgObject?.collection}</span>
                )}
              </CommonComponents.TooltipUI>
            </div>

            <div className="flex items-center gap-1">
              {imgObject?.properties["eo:cloud_cover"]
                ? Number(imgObject?.properties["eo:cloud_cover"] * 100).toFixed(
                    0
                  )
                : 0}
              % <CommonIcons.CloudIcon />
            </div>
          </div>

          <div className="flex items-center gap-2 mt-3 justify-end">
            <CommonComponents.ButtonUI
              commonType="standard"
              icon={<CommonIcons.FootIcon />}
              className={
                "!text-xs !h-7 " + (status ? "!bg-[#4CC2FF]" : "!bg-[#353A4B]")
              }
              onClick={(e: any) => onShowInMap(e, imgObject)}
            ></CommonComponents.ButtonUI>

            {/* <CommonComponents.ButtonUI
              commonType="standard"
              icon={<CommonIcons.MapIcon />}
              className="!text-xs !h-7"
            ></CommonComponents.ButtonUI> */}

            {/* <CommonComponents.ButtonUI
              commonType="standard"
              icon={<CommonIcons.DownloadIcon />}
              className="!text-xs !h-7"
              onClick={() => downloadImage(img)}
            ></CommonComponents.ButtonUI> */}

            <CommonComponents.ButtonUI
              onClick={(e) => {
                !flagInCart ? handleAddCart(e) : handleDelCart(e);
              }}
              commonType="standard"
              icon={<ShoppingCartOutlined size={16} />}
              className={
                "!text-xs !h-7 " +
                (flagInCart ? "!bg-[#4CC2FF]" : "!bg-[#353A4B]")
              }
            ></CommonComponents.ButtonUI>

            <CommonComponents.ButtonUI
              commonType="standard"
              icon={<CommonIcons.RadaIcon />}
              className="!text-xs !h-7"
              onClick={(e: any) => {
                // @ts-ignore
                panToAndExtend(e, imgObject);
              }}
            ></CommonComponents.ButtonUI>
          </div>
        </div>
      </div>
    </div>
  );
}
