import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { Form, Spin, Steps } from "antd";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import BackSvg from "../../../assets/svg/back-arrow.svg";
import CommonComponents from "../../../components/Commons";
import CheckInfoOrderComp from "../../../components/ManageOrder/AdminOrderToProvider/CheckInfoOrder";
import ConfirmOrderComp from "../../../components/ManageOrder/AdminOrderToProvider/ConfirmOrder";
import DeliveryComp from "../../../components/ManageOrder/AdminOrderToProvider/Delivery";
import {
  convertDynamicData,
  convertNotificationsToObject,
  convertToNestedFormatTasking,
} from "../../../components/ManageOrder/AdminOrderToProvider/ultis";
import MapTaskingPlugin from "../../../components/MapTasking/Map";
import { ESttausCode } from "../../../enums";
import { getErrorMsg } from "../../../helpers/common";
import { showError, showSuccess } from "../../../helpers/toast";
import useToggle from "../../../hooks/useToggle";
import ContentLayoutManagement from "../../../layouts/ContentLayoutManagement";
import { resetAllDrawer } from "../../../store/reducers/drawerSlice";
import {
  changeDataBreadcrumb,
  changeLayoutName,
} from "../../../store/reducers/layoutSlice";
import {
  clearFeatures,
  setArrFeatures,
} from "../../../store/reducers/mapTaskingSlice";
import {
  getDetailOrderTaskingByAdminAsync,
  getTaskingSchemaAsync,
  postNotiOrderAsync,
  sendToProviderAsync,
  sendToProviderCapellaAsync,
} from "../../../store/reducers/orderSlice";
import { RootState } from "../../../store/store";

const AdminOrderTaskingToProviderPage = () => {
  const navigate = useNavigate();
  const { idOrder } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const [form] = Form.useForm();

  const [current, setCurrent] = useState(0);
  const [dataCheckInfo, setDataCheckInfo] = useState<any>(undefined);
  const [policy, setPolicy] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [schemaDelivery, setSchemaDelivery] = useState(undefined);
  const [fieldRequireStep2, setFieldRequireStep2] = useState<any>([]);
  // "wait" | "process" | "finish" | "error"
  const [statusStep, setStatusStep] = useState({
    step1: undefined,
    step2: undefined,
    step3: undefined,
  });
  const [loading, setLoading] = useState(false);

  const {
    open: openPolicy,
    toggle: togglePolicy,
    shouldRender: shouldRenderPolicy,
  } = useToggle();

  const {
    open: openPolicySend,
    toggle: togglePolicySend,
    shouldRender: shouldRenderPolicySend,
  } = useToggle();

  const dataStatusOrder = useSelector(
    (state: RootState) => state.appCommonSlice.dataStatusOrder
  );

  const onChange = (value: number) => {
    setCurrent(value);
  };
  const handleStep = (type: "next" | "prev") => {
    if (type === "next") {
      setCurrent(current + 1);
    } else {
      setCurrent(current - 1);
    }
  };

  const getData = async () => {
    setLoading(true);
    // @ts-ignore

    let res = await dispatch(
      // @ts-ignore
      getDetailOrderTaskingByAdminAsync(idOrder)
    ).unwrap();
    setDataCheckInfo(res?.data?.data);

    if (
      res?.data?.data?.order_details?.[0]?.orderMetadata &&
      res?.data?.data?.order_details?.[0]?.orderMetadata?.length > 0
    ) {
      let newArrPolygonImages =
        res?.data?.data?.order_details?.[0]?.orderMetadata?.map(
          (itemM: any, index: any) => ({
            ...itemM,
            id: index,
            name: `Polygon ${index}`,
          })
        );

      dispatch(setArrFeatures(newArrPolygonImages));
    }

    if (
      res?.data?.data?.order_details?.[0]?.provider?.name?.toLowerCase() ===
      "oneatlas"
    ) {
      // @ts-ignore
      let resDelivery = await dispatch(
        // @ts-ignore
        getTaskingSchemaAsync({
          idProvider: res?.data?.data?.order_details?.[0]?.provider?.id,
          type: "ORDER_TASKING_ONEATLAS_NOTIFICATIONS",
        })
      ).unwrap();

      setSchemaDelivery(resDelivery?.data?.data?.ui_schema);

      if (res?.data?.data?.order_details?.[0]?.is_submited) {
        form.setFieldsValue({
          properties: {
            // @ts-ignore
            ...convertNotificationsToObject({
              ...convertToNestedFormatTasking(
                res?.data?.data?.order_details?.[0]?.request_order
              ),
            }),
          },
          price: res?.data?.data?.order_details?.[0]?.actual_price?.total_price,
          unit: res?.data?.data?.order_details?.[0]?.actual_price?.unit,
        });
      }
    }

    setLoading(false);
  };

  const handleSubmit = async () => {
    try {
      setLoadingSubmit(true);
      togglePolicy();

      form.submit();
      const status2 = fieldRequireStep2?.every((item: any) => !!item);
      if (!status2) {
        setStatusStep((prev: any) => ({
          ...prev,
          step2: status2 ? "finish" : "error",
        }));
        showError(t("Please fill in all required fields"));
        return;
      }
      let values = form.getFieldsValue();
      let dataNoti = convertDynamicData(values?.properties);

      // @ts-ignore
      const res = await dispatch(
        // @ts-ignore
        postNotiOrderAsync({
          idDetailOrder: dataCheckInfo?.order_details?.[0]?.id,
          ...dataNoti,
        })
      ).unwrap();

      if (
        res?.status === ESttausCode.OK ||
        res?.status === ESttausCode.CREATED
      ) {
        showSuccess(t("Order successfully"));

        getData();
        setCurrent(2);

        navigate(`/order/management-tasking/${dataCheckInfo?.id}`);
      } else {
        showError(res?.message ?? t("Order failed"));
      }
    } catch (error) {
      showError(t(getErrorMsg(error)));
    } finally {
      setLoadingSubmit(false);
    }
  };

  const handleSend = async () => {
    try {
      setLoadingSubmit(true);
      setLoading(true);
      togglePolicySend();
      let values = form.getFieldsValue();
      let resSendProvider;
      if (
        dataCheckInfo?.order_details?.[0]?.provider?.name.toLowerCase() ===
        "capella"
      ) {
        // @ts-ignore
        resSendProvider = await dispatch(
          // @ts-ignore
          sendToProviderCapellaAsync({
            idDetailOrder: dataCheckInfo?.order_details?.[0]?.id,
            idStatus: dataStatusOrder?.filter(
              (itemF: any) => itemF?.value === "APPROVED"
            )?.[0]?.id,
          })
        ).unwrap();
      } else if (
        dataCheckInfo?.order_details?.[0]?.provider?.name.toLowerCase() ===
        "oneatlas"
      ) {
        // @ts-ignore
        resSendProvider = await dispatch(
          // @ts-ignore
          sendToProviderAsync({
            idDetailOrder: dataCheckInfo?.order_details?.[0]?.id,
            idStatus: dataStatusOrder?.filter(
              (itemF: any) => itemF?.value === "COMPLETED"
            )?.[0]?.id,
          })
        ).unwrap();
      }

      if (
        resSendProvider?.status === ESttausCode.OK ||
        resSendProvider?.status === ESttausCode.CREATED
      ) {
        showSuccess(t("Order successfully"));

        return navigate(`/order/management-tasking/${idOrder}`);
        // getData(); //
        // setCurrent(0);
      } else {
        showError(resSendProvider?.message ?? t("Order failed"));
      }
    } catch (error) {
      showError(t(getErrorMsg(error)));
    } finally {
      setLoadingSubmit(false);
      setLoading(false);
    }
  };

  useEffect(() => {
    dispatch(changeLayoutName("/AdminOrderTaskingToProviderPage"));
    dispatch(
      changeDataBreadcrumb([
        {
          title: t("Tasking Orders"),
          path: "order/management-tasking",
        },
        {
          title: `${location?.state?.data?.name}`,
          path: `${location?.state?.data?.id}`,
        },
        {
          title: `${
            location?.state?.data?.order_details[0].provider.name?.toLowerCase() ===
            "capella"
              ? t("Send order")
              : t("Request quotation")
          } `,
        },
      ])
    );
    dispatch(resetAllDrawer());
    return () => {
      dispatch(changeLayoutName(""));
      dispatch(changeDataBreadcrumb([]));
      dispatch(clearFeatures());
    };
  }, []);

  useEffect(() => {
    getData();
  }, [idOrder]);

  useEffect(() => {
    if (!!statusStep.step2) {
      const status2 = fieldRequireStep2?.every((item: any) => !!item);
      setStatusStep((prev: any) => ({
        ...prev,
        step2: status2 ? "finish" : "error",
      }));
    }
  }, [fieldRequireStep2]);

  return (
    <ContentLayoutManagement
      header={
        <div className="flex gap-2 justify-between mr-[30px] w-full">
          <CommonComponents.ButtonUI
            commonType="standard"
            className="btn-cancel hover:bg-[#3E4353] !text-xs !h-7"
            onClick={() => navigate("/order/management-tasking")}
            icon={<img src={BackSvg} alt="BackSVG" />}
          ></CommonComponents.ButtonUI>

          <div className="flex items-center gap-[14px]">
            {!dataCheckInfo?.order_details?.[0]?.is_submited &&
              dataCheckInfo?.status?.name === "ORDER_REVIEWED" && (
                <CommonComponents.ButtonUI
                  // commonType="standard"
                  className="!text-xs !h-[27px] btn-save"
                  onClick={() => togglePolicy()}
                  disabled={loading || loadingSubmit}
                >
                  {t("Send request quotation")}
                </CommonComponents.ButtonUI>
              )}
            {dataCheckInfo?.order_details?.[0]?.is_submited &&
              !dataCheckInfo?.order_details?.[0]?.is_sent && (
                <CommonComponents.ButtonUI
                  // commonType="standard"
                  className="!text-xs !h-[27px] btn-save"
                  onClick={() => togglePolicySend()}
                  disabled={loading || loadingSubmit}
                >
                  {t("Send")}
                </CommonComponents.ButtonUI>
              )}
          </div>
        </div>
      }
    >
      <Spin spinning={loading}>
        <div
          className="flex !flex-row w-full gap-3 h-full overflow-hidden card-archive-order div-page-default "
          style={{ height: "calc(100vh - 56px - 56px)" }}
        >
          <div className="w-2/3 div-info-confirm-order text-white h-full  pr-2 spin-order flex">
            <div className="flex flex-col w-full">
              <span className="mb-8">
                {t("Status")}:{" "}
                <span
                  style={{
                    color:
                      dataCheckInfo?.order_details?.[0]?.status?.description,
                  }}
                >
                  {dataCheckInfo?.order_details?.[0]?.status?.value}
                </span>
              </span>
              {location?.state?.data?.order_details[0].provider.name?.toLowerCase() !==
                "capella" && (
                <div className="flex items-center gap-3 mb-8">
                  <CommonComponents.ButtonUI
                    commonType="standard"
                    className="!text-xs !h-7 bg-transparent disabled:!bg-transparent"
                    icon={<ArrowLeftOutlined />}
                    iconPosition="start"
                    disabled={current === 0}
                    onClick={() => {
                      handleStep("prev");
                    }}
                  ></CommonComponents.ButtonUI>

                  <Steps
                    // className="mb-8"
                    current={current}
                    onChange={onChange}
                    items={
                      dataCheckInfo?.order_details[0].provider.name?.toLowerCase() ===
                      "oneatlas"
                        ? [
                            {
                              title: t("Production"),
                            },
                            {
                              title: t("Delivery"),
                              status: statusStep.step2,
                            },
                            {
                              title: t("Confirm"),
                            },
                          ]
                        : [
                            {
                              title: t("Production"),
                            },

                            {
                              title: t("Confirm"),
                            },
                          ]
                    }
                  />
                  <CommonComponents.ButtonUI
                    commonType="standard"
                    className="!text-xs !h-7 bg-transparent disabled:!bg-transparent"
                    icon={<ArrowRightOutlined />}
                    iconPosition="end"
                    disabled={
                      dataCheckInfo?.order_details[0].provider.name?.toLowerCase() ===
                      "oneatlas"
                        ? current >= 2
                        : current >= 1
                    }
                    onClick={() => {
                      handleStep("next");
                    }}
                  ></CommonComponents.ButtonUI>
                </div>
              )}

              <div className="overflow-y-scroll">
                <Form form={form}>
                  <CheckInfoOrderComp
                    style={{
                      display: current === 0 ? "initial" : "none",
                    }}
                    type="tasking"
                    data={dataCheckInfo}
                    form={form}
                  />

                  <DeliveryComp
                    style={{
                      display: current === 1 ? "initial" : "none",
                    }}
                    provider={
                      location?.state?.data?.order_details?.[0]?.provider
                    }
                    schema={schemaDelivery}
                    data={dataCheckInfo}
                    setFieldRequireStep2={setFieldRequireStep2}
                    form={form}
                    isSubmited={dataCheckInfo?.order_details?.[0]?.is_submited}
                  />

                  <ConfirmOrderComp
                    style={{
                      display: current === 2 ? "initial" : "none",
                    }}
                    data={dataCheckInfo}
                    form={form}
                  />
                </Form>
              </div>
            </div>
          </div>

          <div className="w-1/3 ">
            <MapTaskingPlugin
              showOnConfirm={true}
              activePolygon={
                dataCheckInfo?.order_details?.[0]?.request_order?.geometry
                  ? dataCheckInfo?.order_details?.[0]?.request_order?.geometry
                  : dataCheckInfo?.order_details?.[0]?.request_order?.aoi?.[0]
                      ?.geometry
                  ? dataCheckInfo?.order_details?.[0]?.request_order?.aoi?.[0]
                      ?.geometry
                  : {}
              }
            />
          </div>
        </div>

        {shouldRenderPolicy && (
          <CommonComponents.ModalUI
            open={openPolicy}
            toggle={togglePolicy}
            title={t("Privacy Policy & Term")}
            handleConfirm={handleSubmit}
            disabled={!policy}
            loading={loadingSubmit}
          >
            <div>
              <CommonComponents.CheckboxUI
                checked={policy}
                onChange={() => {
                  setPolicy(!policy);
                }}
                className="text-white"
              >
                <span>
                  {t("Check here to indicate you have read and agree to the")}{" "}
                </span>
                <Link
                  to={`https://geohub.ai/dieu-khoan-va-dieu-kien-su-dung-dich-vu`}
                  target={"_blank"}
                  className="underline underline-offset-4 cursor-pointer text-common-blue400 hover:!text-common-blue500 hover:!underline"
                >
                  {t("Terms and Conditions of Geohub")}
                </Link>
              </CommonComponents.CheckboxUI>
            </div>
          </CommonComponents.ModalUI>
        )}

        {shouldRenderPolicySend && (
          <CommonComponents.ModalUI
            open={openPolicySend}
            toggle={togglePolicySend}
            title={t("Send order")}
            handleConfirm={handleSend}
            disabled={!policy}
            loading={loadingSubmit}
          >
            <p>{t("Are you sure you want to send order to provider?")}</p>
          </CommonComponents.ModalUI>
        )}
      </Spin>
    </ContentLayoutManagement>
  );
};

export default AdminOrderTaskingToProviderPage;
