import { Collapse, Divider, Form, Space, Spin } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import CommonIcons from "../../../assets/icons";
import ApplySvg from "../../../assets/svg/apply-black.svg";
import RejectSvg from "../../../assets/svg/dismiss-black.svg";
import EditSvg from "../../../assets/svg/edit-black.svg";
import viewSvg from "../../../assets/svg/eye.svg";
import SendSvg from "../../../assets/svg/sendBlack.svg";
import Step2 from "../../../components/BookingImage/Step2";
import Step3 from "../../../components/BookingImage/Step3";
import Step4 from "../../../components/BookingImage/Step4";
import CommonComponents from "../../../components/Commons";
import MapTaskingPlugin from "../../../components/MapTasking/Map";
import { EProviderName, ERoleAccessControl, ESttausCode } from "../../../enums";
import { capitalizeFirstLetter, getErrorMsg } from "../../../helpers/common";
import { showError, showSuccess } from "../../../helpers/toast";
import useToggle from "../../../hooks/useToggle";
import ContentLayoutManagement from "../../../layouts/ContentLayoutManagement";
import orderService from "../../../services/order/order.service";
import { setLoadingGlobal } from "../../../store/reducers/appCommonSlice";
import { resetAllDrawer } from "../../../store/reducers/drawerSlice";
import {
  changeDataBreadcrumb,
  changeLayoutName,
} from "../../../store/reducers/layoutSlice";
import {
  getOrderByIdAsync,
  getOrderByIdUserAsync,
  sendQuotationToUserAsync,
  sendToProviderCapellaAsync,
  submitOrderAsync,
} from "../../../store/reducers/orderSlice";
import { RootState } from "../../../store/store";
import { BuyerInfomationTaskingOrderAdmin } from "../ManageTaskingOrder/[UpdateTaskingOrderAdmin]";
import { RenderHeader, renderInfoOrder } from "../ManageTaskingOrder/ultis";
import { checkImageUploadS3, checkManageData } from "./ultis";
import BackSvg from "../../../assets/svg/back-arrow.svg";
import { checkAutoOder } from "../../../components/ManageOrder/ultils";
import { useTranslation } from "react-i18next";
const optionsUnit = [
  {
    value: "VND",
    label: "VND",
  },
  {
    value: "USD",
    label: "USD",
  },
  {
    value: "EUR",
    label: "EUR",
  },
];

const InfoArchiveOrderPage = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  let map = useSelector((state: RootState) => state.mapTasking.maplibreGL);
  let sourceId = useSelector((state: RootState) => state.mapTasking.sourceId);

  const {
    open: openConfirmReject,
    shouldRender: shouldRenderConfirmReject,
    toggle: toggleConfirmReject,
  } = useToggle();

  const {
    open: openConfirmAccept,
    shouldRender: shouldRenderUpdateConfirmAccept,
    toggle: toggleUpdateConfirmAccept,
  } = useToggle();

  const {
    open: openReason,
    toggle: toggleReason,
    shouldRender: shouldRenderReason,
  } = useToggle();

  const {
    open: openSendQuotationToUser,
    toggle: toggleSendQuotationToUser,
    shouldRender: shouldRenderSendQuotationToUser,
  } = useToggle();

  const {
    open: openConfirmPrice,
    toggle: toggleConfirmPrice,
    shouldRender: shouldConfirmPrice,
  } = useToggle();

  const {
    open: openCloseOrder,
    toggle: toggleCloseOrder,
    shouldRender: shouldCloseOrder,
  } = useToggle();

  const {
    open: openSendOrderUser,
    toggle: toggleSendOrderUser,
    shouldRender: shouldSendOrderUser,
  } = useToggle();

  const {
    open: openRequestQuotation,
    toggle: toggleRequestQuotation,
    shouldRender: shouldRequestQuotation,
  } = useToggle();

  const {
    open: openSendProvider,
    toggle: toggleSendProvider,
    shouldRender: shouldSendProvider,
  } = useToggle();

  const dataItem = location?.state?.item;
  const [idReject, setIdReject] = useState<number | null>(null);
  const [data, setData] = useState<any>({});
  const [itemsImages, setItemsImages] = useState<any>([]);
  const [items, setItems] = useState<any>([]);
  const [itemsInfoOder, setItemsInfoOder] = useState<any>([]);

  const [reason, setReason] = useState<string>("");

  const maplibreGL = useSelector(
    (state: RootState) => state.mapTasking.maplibreGL
  );
  const loadingGlobal = useSelector(
    (state: RootState) => state.appCommonSlice.loadingGlobal
  );

  const dataStatusOrder = useSelector(
    (state: RootState) => state.appCommonSlice.dataStatusOrder
  );

  const { providerConfigStore } = useSelector(
    (state: RootState) => state.appCommonSlice
  );

  const roleAdmin = useSelector(
    (state: RootState) => state.appCommonSlice.roleUsing
  );

  const [arrAOI, setAOI] = useState<any>([]);

  const getData = async () => {
    dispatch(setLoadingGlobal(true));
    let res = !roleAdmin?.includes(ERoleAccessControl.admin)
      ? // @ts-ignore
        await dispatch(getOrderByIdUserAsync(id)).unwrap()
      : // @ts-ignore
        await dispatch(getOrderByIdAsync(id)).unwrap();
    if (res.status === ESttausCode.OK) {
      const buyerInfo = res?.data?.data?.buyer;

      setItems([
        {
          key: "1",
          label: t("Buyer Information"),
          children: (
            <BuyerInfomationTaskingOrderAdmin
              whoami={data.data}
              buyerInfo={buyerInfo}
              t={t}
            />
          ),
        },
      ]);
    }

    const allImages = res?.data?.data?.order_details
      .map((item: any) => item.images)
      .flat();

    setAOI(allImages);

    setItemsInfoOder([
      {
        key: "1",
        label: t("Order Information"),
        children: renderInfoOrder(res?.data?.data, roleAdmin, "archive", t),
      },
    ]);

    const newValues = {};
    form.setFieldsValue(newValues);

    setData(res?.data?.data ?? []);

    setTimeout(() => {
      dispatch(setLoadingGlobal(false));
    }, 500);
  };

  const handlSendCapella = async () => {
    try {
      toggleSendProvider();
      dispatch(setLoadingGlobal(true));
      // @ts-ignore
      let resSendProvider = await dispatch(
        // @ts-ignore
        sendToProviderCapellaAsync({
          idDetailOrder: data?.order_details?.filter(
            (item: any) => item?.provider?.name?.toLowerCase() === "capella"
          )?.[0]?.id,
          idStatus: dataStatusOrder?.filter(
            (itemF: any) => itemF?.value === "APPROVED"
          )?.[0]?.id,
        })
      ).unwrap();

      if (
        resSendProvider?.status === ESttausCode.OK ||
        resSendProvider?.status === ESttausCode.CREATED
      ) {
        showSuccess(t("Order successfully"));

        getData();
      } else {
        showError(resSendProvider?.message ?? "Order failed");
        getData();
      }
    } catch (error) {
      showError(t(getErrorMsg(error)));
    } finally {
      dispatch(setLoadingGlobal(false));
    }
  };

  useEffect(() => {
    let a: any = [];

    if (data?.order_details?.length > 0) {
      const initialValues: any = {};
      data?.order_details?.forEach((item: any) => {
        if (item?.actual_price) {
          initialValues[item?.id] = {
            price: item?.actual_price?.total_price ?? 0,
            unit: item?.actual_price?.unit ?? "",
          };
        }
      });

      // Đặt giá trị khởi tạo cho form
      form.setFieldsValue(initialValues);

      data?.order_details?.map((item: any, index: any) => {
        if (item?.images?.length !== 0)
          a = a.concat({
            key: index,
            label: (
              <div className="flex justify-between items-center">
                <span>
                  {capitalizeFirstLetter(item?.provider?.name)} (
                  {item?.images?.length})
                  {roleAdmin?.includes(ERoleAccessControl.admin) && (
                    <>
                      {item?.status?.value && (
                        <>
                          <Divider
                            style={{
                              background: "#61687E",
                              margin: " 0 10px ",
                            }}
                            type="vertical"
                          />
                          <span style={{ color: item?.status?.description }}>
                            {t(item?.status?.value)}
                          </span>
                        </>
                      )}

                      {roleAdmin?.includes(ERoleAccessControl.admin) &&
                        item?.order_cost?.total_price !== null && (
                          <>
                            <Divider
                              style={{
                                background: "#61687E",
                                margin: " 0 10px ",
                              }}
                              type="vertical"
                            />
                            <span>
                              {t("Total price")}:
                              <span className="text-common-primary500">
                                {` ${item?.order_cost?.total_price} ${item?.order_cost?.unit}`}
                              </span>
                            </span>
                          </>
                        )}
                    </>
                  )}
                  {item?.actual_price?.total_price !== null &&
                    data?.status?.name !== "ORDER_REVIEWED" &&
                    roleAdmin?.includes(ERoleAccessControl.admin) && (
                      <>
                        <Divider
                          style={{
                            background: "#61687E",
                            margin: " 0 10px ",
                          }}
                          type="vertical"
                        />
                        <span>
                          {t(`Vega's price`)}:
                          <span className="text-[#00FF00]">
                            {` ${item?.actual_price?.total_price} ${item?.actual_price?.unit}`}
                          </span>
                        </span>
                      </>
                    )}
                </span>

                <div className="flex gap-3 items-center">
                  {!roleAdmin?.includes(ERoleAccessControl.admin) &&
                    item?.actual_price?.total_price !== null && (
                      <>
                        <span>
                          {t("Total")}:
                          <span className="text-common-primary500">
                            {` ${item?.actual_price?.total_price} ${item?.actual_price?.unit}`}
                          </span>
                        </span>
                      </>
                    )}

                  {data?.status?.name === "ORDER_REVIEWED" &&
                    !item?.is_submited && // gui len hang lay gia
                    roleAdmin?.includes(ERoleAccessControl.admin) && (
                      <CommonComponents.ButtonUI
                        // commonType="standard"
                        className="!text-xs !h-7 hover:!bg-common-primary500 bg-common-primary500 !text-black"
                        onClick={(e) => {
                          e.stopPropagation();
                          if (
                            item?.provider?.name?.toLowerCase() === "capella"
                          ) {
                            toggleRequestQuotation();
                          } else if (
                            item?.provider?.name?.toLowerCase() === "oneatlas"
                          ) {
                            navigate(
                              `/order/management-order/${id}/${item?.id}`,
                              {
                                state: {
                                  data: data,
                                  item: item,
                                },
                              }
                            );
                          }
                        }}
                        icon={<img src={SendSvg} />}
                        iconPosition="end"
                      >
                        {t("Request quotation")}
                      </CommonComponents.ButtonUI>
                    )}

                  {data?.status?.name === "ORDER_ACCEPTED" &&
                    item?.status?.name === "ORDER_COMPLETED" &&
                    roleAdmin?.includes(ERoleAccessControl.admin) && (
                      <CommonComponents.ButtonUI
                        commonType="standard"
                        className="!text-xs !h-7"
                        onClick={() =>
                          navigate(
                            `/order/management-order/${id}/${item?.id}`,
                            {
                              state: {
                                data: data,
                                item: item,
                              },
                            }
                          )
                        }
                        icon={<img src={viewSvg} />}
                      >
                        {t("View order")}
                      </CommonComponents.ButtonUI>
                    )}

                  {data?.status?.name === "ORDER_REVIEWED" &&
                    roleAdmin?.includes(ERoleAccessControl.admin) &&
                    item?.is_submited &&
                    item?.actual_price?.total_price !== null && (
                      <div className="flex flex-row justify-between items-center">
                        <span className="text-white mr-2">
                          {t(`Vega's price`)}:{" "}
                        </span>
                        <span>
                          <CommonComponents.FormFieldUI
                            noStyle
                            name={[item?.id, "price"]}
                            // initialValues={item?.actual_price?.total_price}
                            component={
                              <Space.Compact
                                className="items-center "
                                onClick={(e) => {
                                  e.stopPropagation();
                                }}
                              >
                                <CommonComponents.InputUI
                                  placeholder={`Enter Vega's price`}
                                  required
                                  defaultValue={item?.actual_price?.total_price}
                                  rules={[
                                    {
                                      required: true,
                                      message: t("Please input here!"),
                                    },
                                  ]}
                                  className=" !h-8 !mb-0 !bg-[#3E4353] !border-b-0"
                                  classNameDiv="!mb-0"
                                  type="number"
                                />
                                <Divider
                                  type="vertical"
                                  style={{
                                    background: "#575F77",
                                    margin: "10px 0",
                                  }}
                                />
                                <CommonComponents.FormFieldUI
                                  name={[item?.id, "unit"]}
                                  noStyle
                                  component={
                                    <CommonComponents.SelectUI
                                      data={optionsUnit}
                                      className="!w-[80px] !bg-[#3E4353] !rounded-l-none custom-select-unit"
                                      // disabled={props?.data?.is_sent}
                                    />
                                  }
                                />
                              </Space.Compact>
                            }
                          />
                        </span>
                      </div>
                    )}
                  {(data?.status?.name === "ORDER_CONFIRMED_QUOTATION" ||
                    data?.status?.name === "ORDER_WAITING_FOR_RESULT_PUSH" ||
                    data?.status?.name === "ORDER_PROCESSING") &&
                    roleAdmin?.includes(ERoleAccessControl.admin) &&
                    item?.is_submited &&
                    !item?.is_sent && (
                      <CommonComponents.ButtonUI
                        className="!text-xs !h-7 hover:!bg-common-primary500 bg-common-primary500 !text-black btn-save"
                        onClick={(e) => {
                          e.stopPropagation();
                          if (
                            item?.provider?.name?.toLowerCase() === "oneatlas"
                          ) {
                            navigate(
                              `/order/management-order/${id}/${item?.id}`,
                              {
                                state: {
                                  data: data,
                                  item: item,
                                },
                              }
                            );
                          } else {
                            toggleSendProvider();
                          }
                        }}
                        icon={<img src={SendSvg} />}
                      >
                        {t("Send order to provider")}
                      </CommonComponents.ButtonUI>
                    )}
                </div>
              </div>
            ),
            children: (
              <>
                <Divider
                  style={{ background: "#545C77", margin: "0px 0px 20px" }}
                />
                <div className="w-full flex gap-3">
                  <div className="w-full">
                    {item?.provider?.name?.toLowerCase() === "planet" ? (
                      <Step3
                        form={form}
                        dataImages={item?.images}
                        map={map}
                        sourceId={sourceId}
                        initialValues={item?.request_order?.products}
                        readOnly
                        onClick={(idImage: any) => {
                          setAOI((prevAOI: any) => {
                            const exists = prevAOI.some(
                              (itemF: any) => itemF.id === idImage
                            );

                            if (exists) {
                              return prevAOI.filter(
                                (itemF: any) => itemF.id !== idImage
                              );
                            } else {
                              const newItem = item?.images?.find(
                                (itemF: any) => itemF.id === idImage
                              );
                              return newItem ? [...prevAOI, newItem] : prevAOI;
                            }
                          });
                        }}
                      />
                    ) : item?.provider?.name?.toLowerCase() === "oneatlas" ? (
                      <Step4
                        showCardImages={true}
                        form={form}
                        dataImages={item?.images}
                        total={item?.images?.length}
                        dataProvider={
                          providerConfigStore?.content_page?.filter(
                            (item: any) =>
                              item?.name.toLowerCase() ===
                              EProviderName.oneatlas
                          )?.[0]?.configs
                        }
                        map={map}
                        sourceId={sourceId}
                        initialValues={item?.request_order?.optionsPerProductType?.[0]?.options.concat(
                          {
                            key: "product_type_id",
                            value:
                              item?.request_order?.optionsPerProductType?.[0]
                                ?.productTypeId ??
                              item?.request_order?.optionsPerProductType?.[0]
                                ?.product_type_id,
                          }
                        )}
                        readOnly
                        onClick={(idImage: any) => {
                          setAOI((prevAOI: any) => {
                            const exists = prevAOI.some(
                              (itemF: any) => itemF.id === idImage
                            );
                            if (exists) {
                              return prevAOI.filter(
                                (itemF: any) => itemF.id !== idImage
                              );
                            } else {
                              const newItem = item?.images?.find(
                                (itemF: any) => itemF.id === idImage
                              );
                              return newItem ? [...prevAOI, newItem] : prevAOI;
                            }
                          });
                        }}
                        style={{ background: "#3C4152" }}
                      />
                    ) : (
                      <Step2
                        form={form}
                        items={item?.images}
                        dataProvider={
                          providerConfigStore?.content_page?.filter(
                            (item: any) =>
                              item?.name.toLowerCase() === EProviderName.capella
                          )?.[0]?.configs
                        }
                        map={map}
                        sourceId={sourceId}
                        onClick={(idImage: any) => {
                          setAOI((prevAOI: any) => {
                            const exists = prevAOI.some(
                              (itemF: any) => itemF.id === idImage
                            );
                            if (exists) {
                              return prevAOI.filter(
                                (itemF: any) => itemF.id !== idImage
                              );
                            } else {
                              const newItem = item?.images?.find(
                                (itemF: any) => itemF.id === idImage
                              );
                              return newItem ? [...prevAOI, newItem] : prevAOI;
                            }
                          });
                        }}
                      />
                    )}
                  </div>
                </div>
              </>
            ),
          });
      });
    }

    setItemsImages(a);
  }, [data, maplibreGL, providerConfigStore]);

  useEffect(() => {
    getData();
  }, [id]);

  useEffect(() => {
    dispatch(changeLayoutName("/view-order"));
    dispatch(
      changeDataBreadcrumb([
        {
          title: (
            <span
              className="cursor-pointer"
              onClick={() => navigate("/order/management-order")}
            >
              {t("managementOrder.listOrder")}
            </span>
          ),
        },
        { title: ` ${dataItem?.title ?? data?.name}` },
      ])
    );
    dispatch(resetAllDrawer());
    return () => {
      dispatch(changeLayoutName(""));
      dispatch(changeDataBreadcrumb([]));
    };
  }, [id, data]);

  const handleConfirmPrice = async () => {
    // admin confirm reviewed
    try {
      const result = await orderService.ChangeStatusOrderByAdmin(
        Number(id),
        dataStatusOrder?.filter(
          (itemF: any) => itemF?.name === "ORDER_CONFIRMED_QUOTATION"
        )?.[0]?.id,
        {
          note: "",
        }
      );
      if (result.status === ESttausCode.OK) {
        showSuccess(t("Successfully"));
        getData();
      }
    } catch (error) {
      showError(t(getErrorMsg(error)));
    } finally {
      toggleConfirmPrice();
      setIdReject(null);
    }
  };

  const formatValues = (values: Record<string, any>) => {
    return Object.entries(values)
      .filter(([key, _]) => key !== "oneatlas" && key !== "capella")
      .map(([key, value]) => ({
        order_detail_id: parseInt(key, 10),
        actual_total_price: parseFloat(value.price || 0),
        actual_unit: value.unit || "",
      }));
  };

  const handleSendQuotationToUser = async () => {
    // admin confirm reviewed
    try {
      // @ts-ignore
      let res = await dispatch(
        // @ts-ignore
        sendQuotationToUserAsync({
          orderId: id,
          data: formatValues(form.getFieldsValue()),
        })
      ).unwrap();
      if (res.status === ESttausCode.OK) {
        showSuccess(t("Successfully"));
        getData();
      }
    } catch (error) {
      showError(t(getErrorMsg(error)));
    } finally {
      toggleSendQuotationToUser();
      setIdReject(null);
    }
  };

  const handleCloseOrder = async () => {
    // admin confirm reviewed
    try {
      const result = await orderService.ChangeStatusOrderByAdmin(
        Number(id),
        dataStatusOrder?.filter(
          (itemF: any) => itemF?.name === "ORDER_CLOSED"
        )?.[0]?.id,
        {
          note: "",
        }
      );
      if (result.status === ESttausCode.OK) {
        showSuccess(t("Successfully"));
        getData();
      }
    } catch (error) {
      showError(t(getErrorMsg(error)));
    } finally {
      toggleCloseOrder();
      setIdReject(null);
    }
  };

  const handleSendOrderUser = async () => {
    // admin confirm reviewed
    try {
      const result = await orderService.ChangeStatusOrderByAdmin(
        Number(id),
        dataStatusOrder?.filter(
          (itemF: any) => itemF?.name === "ORDER_REVIEWING"
        )?.[0]?.id,
        {
          note: "",
        }
      );
      if (result.status === ESttausCode.OK) {
        showSuccess(t("Successfully"));
        getData();
      }
    } catch (error) {
      showError(t(getErrorMsg(error)));
    } finally {
      toggleSendOrderUser();
      setIdReject(null);
    }
  };

  const handleConfirmReject = async () => {
    try {
      let result;
      if (roleAdmin?.includes(ERoleAccessControl.admin)) {
        result = await orderService.ChangeStatusOrderByAdmin(
          Number(idReject),
          dataStatusOrder?.filter(
            (itemF: any) => itemF?.name === "ORDER_REJECTED"
          )?.[0]?.id,
          {
            note: reason,
          }
        );
      } else {
        result = await orderService.ChangeStatusOrder(
          Number(idReject),
          dataStatusOrder?.filter(
            (itemF: any) => itemF?.name === "ORDER_CANCELED"
          )?.[0]?.id,
          {
            note: reason,
          }
        );
      }
      if (result.status === ESttausCode.OK) {
        toggleReason();
        showSuccess(t("Successfully"));
        getData();
      }
    } catch (error) {
      showError(t(getErrorMsg(error)));
    } finally {
      toggleConfirmReject();
      setIdReject(null);
    }
  };

  const handleAcceptOrder = async () => {
    // admin confirm reviewed
    try {
      const result = await orderService.ChangeStatusOrderByAdmin(
        Number(idReject),
        dataStatusOrder?.filter(
          (itemF: any) => itemF?.name === "ORDER_REVIEWED"
        )?.[0]?.id,
        {
          note: "",
        }
      );
      if (result.status === ESttausCode.OK) {
        showSuccess(t("Successfully"));
        getData();
      }
    } catch (error) {
      showError(t(getErrorMsg(error)));
    } finally {
      toggleUpdateConfirmAccept();
      setIdReject(null);
    }
  };

  const handleRequestQuotation = async () => {
    try {
      toggleRequestQuotation();
      dispatch(setLoadingGlobal(true));

      // @ts-ignore
      let res = await dispatch(
        // @ts-ignore
        submitOrderAsync({
          order_detail_id: data?.order_details?.filter(
            (item: any) => item?.provider?.name?.toLowerCase() === "capella"
          )?.[0]?.id,
        })
      ).unwrap();

      if (res.status === ESttausCode.OK) {
        showSuccess(t("Successfully"));

        getData();
        dispatch(setLoadingGlobal(false));
      } else {
        showError(getErrorMsg(res?.message ?? "Error"));
        getData();
        dispatch(setLoadingGlobal(false));
      }
    } catch (error) {
      showError(t(getErrorMsg(error)));
      dispatch(setLoadingGlobal(false));
    }
  };

  return (
    <Form form={form}>
      <ContentLayoutManagement
        header={
          <div className="flex justify-between items-center mr-[28px] w-full">
            <div>
              <CommonComponents.ButtonUI
                commonType="standard"
                className="btn-cancel hover:bg-[#3E4353] !text-xs !h-7"
                onClick={() => navigate("/order/management-order")}
                icon={<img src={BackSvg} alt="backSvg" />}
              ></CommonComponents.ButtonUI>
            </div>

            <div className="flex gap-[14px] items-cente">
              {!checkAutoOder(data) && (
                <>
                  {roleAdmin?.includes(ERoleAccessControl.user) &&
                    data?.status?.name === "ORDER_CREATED" && (
                      <>
                        <CommonComponents.ButtonUI
                          className="!text-xs !h-[27px] !bg-[#FF6161]"
                          icon={<img src={RejectSvg} />}
                          onClick={(e) => {
                            e.stopPropagation();
                            setIdReject(Number(id));
                            // toggleConfirmReject();
                            toggleReason();
                          }}
                        >
                          {t("Cancel order")}
                        </CommonComponents.ButtonUI>

                        <CommonComponents.ButtonUI
                          className="!text-xs !h-[27px]"
                          onClick={(e) => {
                            e.stopPropagation();
                            navigate(`/order/management-order/${id}/update`, {
                              state: {
                                item: data,
                              },
                            });
                          }}
                          icon={<img src={EditSvg} />}
                        >
                          {t("Edit")}
                        </CommonComponents.ButtonUI>

                        <CommonComponents.ButtonUI
                          className="!text-xs !h-[27px]  hover:!bg-common-primary500"
                          onClick={(e) => {
                            e.stopPropagation();
                            toggleSendOrderUser();
                          }}
                          icon={<img src={SendSvg} />}
                        >
                          {t("Send order")}
                        </CommonComponents.ButtonUI>
                      </>
                    )}

                  {roleAdmin?.includes(ERoleAccessControl.user) && //confirm price
                    data?.status?.name === "WAIT_FOR_CONFIRMING_QUOTATION" && (
                      <>
                        <CommonComponents.ButtonUI
                          className="!text-xs !h-[27px]"
                          onClick={(e) => {
                            e.stopPropagation();
                            setIdReject(Number(id));
                            // toggleConfirmReject();
                            toggleConfirmPrice();
                          }}
                          icon={<img src={EditSvg} />}
                        >
                          {t("Confirm order's price")}
                        </CommonComponents.ButtonUI>
                      </>
                    )}

                  {roleAdmin?.includes(ERoleAccessControl.admin) &&
                    (data?.status?.value === "PROCESSING" ||
                      data?.status?.value === "CLOSED") &&
                    !checkManageData(data) && (
                      <>
                        <CommonComponents.ButtonUI
                          // commonType="standard"
                          className="!text-xs !h-[27px] btn-save"
                          onClick={async () => {
                            navigate(`/order/${id}/upload-data`, {
                              state: {
                                data: data,
                              },
                            });
                          }}
                        >
                          {t("Manage data")}
                        </CommonComponents.ButtonUI>
                      </>
                    )}

                  {roleAdmin?.includes(ERoleAccessControl.admin) &&
                    data?.status?.name === "ORDER_REVIEWING" && (
                      <>
                        <CommonComponents.ButtonUI
                          className="!text-xs !h-[27px] !bg-[#FF6161]"
                          icon={<img src={RejectSvg} />}
                          onClick={(e) => {
                            e.stopPropagation();
                            setIdReject(Number(id));
                            // toggleConfirmReject();
                            toggleReason();
                          }}
                        >
                          {t("Reject")}
                        </CommonComponents.ButtonUI>

                        <CommonComponents.ButtonUI
                          className="!text-xs !h-[27px]  hover:!bg-common-primary500"
                          onClick={(e) => {
                            e.stopPropagation();
                            navigate(`/order/management-order/${id}/update`, {
                              state: {
                                item: data,
                              },
                            });
                          }}
                          icon={<img src={EditSvg} />}
                        >
                          {t("Edit")}
                        </CommonComponents.ButtonUI>

                        <CommonComponents.ButtonUI
                          className="!text-xs !h-[27px]  hover:!bg-common-primary500"
                          icon={<img src={ApplySvg} />}
                          onClick={(e) => {
                            e.stopPropagation();
                            setIdReject(Number(id));
                            toggleUpdateConfirmAccept();
                          }}
                        >
                          {t("Confirm review")}
                        </CommonComponents.ButtonUI>
                      </>
                    )}

                  {roleAdmin?.includes(ERoleAccessControl.admin) &&
                    data?.status?.name === "ORDER_REVIEWED" &&
                    // data.order_details[0].actual_price.total_price !== null &&

                    data?.order_details?.filter(
                      (order: any) => order?.actual_price?.total_price === null
                    ).length === 0 && (
                      <>
                        <CommonComponents.ButtonUI
                          className="!text-xs !h-[27px]  hover:!bg-common-primary500"
                          icon={<img src={SendSvg} />}
                          onClick={(e) => {
                            e.stopPropagation();
                            // setIdReject(Number(id));
                            toggleSendQuotationToUser();
                          }}
                        >
                          {t("Send quotation to user")}
                        </CommonComponents.ButtonUI>
                      </>
                    )}

                  {roleAdmin?.includes(ERoleAccessControl.user) &&
                    data?.status?.name ===
                      "ORDER_WAIT_FOR_CONFIRMING_QUOTATION" && (
                      <>
                        <CommonComponents.ButtonUI
                          className="!text-xs !h-[27px] !bg-[#FF6161]"
                          icon={<img src={RejectSvg} />}
                          onClick={(e) => {
                            e.stopPropagation();
                            setIdReject(Number(id));
                            // toggleConfirmReject();
                            toggleReason();
                          }}
                        >
                          {t("Cancel quotation")}
                        </CommonComponents.ButtonUI>

                        <CommonComponents.ButtonUI
                          className="!text-xs !h-[27px]  hover:!bg-common-primary500"
                          icon={<img src={ApplySvg} />}
                          onClick={(e) => {
                            e.stopPropagation();
                            // setIdReject(Number(id));
                            toggleConfirmPrice();
                          }}
                        >
                          {t("Confirm quotation")}
                        </CommonComponents.ButtonUI>
                      </>
                    )}

                  {roleAdmin?.includes(ERoleAccessControl.admin) &&
                    data?.status?.name !== "ORDER_CLOSED" &&
                    !checkImageUploadS3(data) && (
                      <>
                        <CommonComponents.ButtonUI
                          className="!text-xs !h-7 !bg-[#FF6161] btn-save hover:!bg-[#FF6161] !text-black"
                          // icon={<img src={ApplySvg} />}
                          onClick={(e) => {
                            e.stopPropagation();
                            // setIdReject(Number(id));
                            toggleCloseOrder();
                          }}
                        >
                          {t("Close order")}
                        </CommonComponents.ButtonUI>
                      </>
                    )}
                </>
              )}
            </div>
          </div>
        }
      >
        <div className="flex !flex-row w-full gap-3 h-full overflow-hidden card-archive-order div-page-default ">
          <div
            className="w-1/2 div-info-confirm-order text-white h-full overflow-auto pr-2 spin-order"
            style={{ minHeight: "calc(100vh - 56px - 56px - 90px)" }}
          >
            <Spin
              spinning={loadingGlobal}
              style={{
                minHeight: "calc(100vh - 56px - 56px - 90px)",
                height: "100%",
              }}
            >
              {RenderHeader({
                data: data,
                roleAdmin: roleAdmin?.includes(ERoleAccessControl.admin),
                t: t,
              })}

              <div className="div-collapse-pol-point-cus my-4">
                <Collapse
                  bordered={false}
                  expandIconPosition="end"
                  items={items}
                  defaultActiveKey={["1"]}
                  onChange={() => {}}
                  className="!p-0"
                  expandIcon={({ isActive }) => (
                    <div style={{ rotate: isActive ? "0deg" : "180deg" }}>
                      <CommonIcons.ChevronUpIcon />
                    </div>
                  )}
                />
              </div>
              <div className="div-collapse-pol-point-cus">
                <Collapse
                  bordered={false}
                  expandIconPosition="end"
                  items={itemsInfoOder}
                  defaultActiveKey={["1"]}
                  onChange={() => {}}
                  className="!p-0"
                  expandIcon={({ isActive }) => (
                    <div style={{ rotate: isActive ? "0deg" : "180deg" }}>
                      <CommonIcons.ChevronUpIcon />
                    </div>
                  )}
                />
              </div>

              <p className="text-base mb-4 mt-8 font-normal">
                {t("Images in order")}
              </p>

              <div className="card-archive-order-cus">
                <Collapse
                  className="bg-[#2C3243]"
                  bordered={false}
                  expandIconPosition="end"
                  items={itemsImages}
                  onChange={() => {}}
                  defaultActiveKey={["0", "1", "2", "3"]}
                  expandIcon={({ isActive }) => (
                    <div style={{ rotate: isActive ? "0deg" : "180deg" }}>
                      <CommonIcons.ChevronUpIcon />
                    </div>
                  )}
                />
              </div>
            </Spin>
          </div>

          <div className="w-1/2 ">
            <MapTaskingPlugin
              type="map-info-archive"
              arrPolygon={arrAOI}
              showOnConfirm={true}
              activePolygon={{}}
            />
          </div>
        </div>

        {shouldRenderConfirmReject && (
          <CommonComponents.ModalUI
            toggle={toggleConfirmReject}
            title={t("Confirm")}
            open={openConfirmReject}
            handleConfirm={handleConfirmReject}
          >
            <p>
              {roleAdmin?.includes(ERoleAccessControl.user)
                ? t("Are you sure you want to cancel this order?")
                : t("Are you sure you want to reject this order?")}
            </p>
          </CommonComponents.ModalUI>
        )}

        {shouldRenderUpdateConfirmAccept && (
          <CommonComponents.ModalUI
            toggle={toggleUpdateConfirmAccept}
            title={t("Accept order")}
            open={openConfirmAccept}
            handleConfirm={handleAcceptOrder}
            // hiddenFooter={true}
          >
            <p>{t("Are you sure you want to accept this order?")}</p>
          </CommonComponents.ModalUI>
        )}

        {shouldRenderReason && (
          <CommonComponents.ModalUI
            title={
              roleAdmin?.includes(ERoleAccessControl.user)
                ? t("Cancel order")
                : t("Reason reject order")
            }
            open={openReason}
            toggle={toggleReason}
            handleConfirm={() => {
              if (reason?.trim().length > 0) {
                toggleConfirmReject();
              }
            }}
            disabled={reason?.trim().length === 0}
          >
            <ReasonCancelReject reason={reason} setReasson={setReason} />
          </CommonComponents.ModalUI>
        )}

        {shouldRenderSendQuotationToUser && (
          <CommonComponents.ModalUI
            title={t("Send quotation")}
            open={openSendQuotationToUser}
            toggle={toggleSendQuotationToUser}
            handleConfirm={handleSendQuotationToUser}
          >
            <p>
              {t(`Are you sure you want to send order's quotation to user?`)}
            </p>
          </CommonComponents.ModalUI>
        )}

        {shouldConfirmPrice && (
          <CommonComponents.ModalUI
            title={t("Confirm quotation")}
            open={openConfirmPrice}
            toggle={toggleConfirmPrice}
            handleConfirm={handleConfirmPrice}
          >
            <p>{t(`Are you sure you want to confirm price's this order?`)}</p>
          </CommonComponents.ModalUI>
        )}

        {shouldCloseOrder && (
          <CommonComponents.ModalUI
            title={t("Close order")}
            open={openCloseOrder}
            toggle={toggleCloseOrder}
            handleConfirm={handleCloseOrder}
          >
            <p>{t(`Are you sure you want to close this order?`)}</p>
          </CommonComponents.ModalUI>
        )}

        {shouldSendOrderUser && (
          <CommonComponents.ModalUI
            title={t("Send order")}
            open={openSendOrderUser}
            toggle={toggleSendOrderUser}
            handleConfirm={handleSendOrderUser}
          >
            <p>{t("Are you sure you want to send this order to admin?")}</p>
          </CommonComponents.ModalUI>
        )}

        {shouldRequestQuotation && ( // for capella
          <CommonComponents.ModalUI
            toggle={toggleRequestQuotation}
            title={t("Request Quotation")}
            open={openRequestQuotation}
            handleConfirm={handleRequestQuotation}
          >
            <p>{t("Are you sure you want to request quotation?")}</p>
          </CommonComponents.ModalUI>
        )}

        {shouldSendProvider && ( // for capella
          <CommonComponents.ModalUI
            toggle={toggleSendProvider}
            title={t("Send provider")}
            open={openSendProvider}
            handleConfirm={handlSendCapella}
          >
            <p>{t("Are you sure you want to send order to provider?")}</p>
          </CommonComponents.ModalUI>
        )}
      </ContentLayoutManagement>
    </Form>
  );
};

export default InfoArchiveOrderPage;

const ReasonCancelReject = ({
  setReasson,
  reason,
}: {
  setReasson: any;
  reason: string;
}) => {
  const [touched, setTouched] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    return () => {
      setReasson("");
    };
  }, []);
  return (
    <div>
      <CommonComponents.TextAreaUI
        onChange={(event: any) => {
          setReasson(event.target.value);
        }}
        label={t("Reason")}
        placeholder={t("Enter reason ....")}
        onBlur={() => {
          setTouched(true);
        }}
      />
      {touched && reason?.trim()?.length <= 0 && (
        <p className="text-red-500 text-xs mt-1">{t("Reason is required")}</p>
      )}
    </div>
  );
};
