import { Badge } from "antd";
import { ReactNode, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { IChildrenTooltip } from ".";
import CommonIcons from "../../assets/icons";
import { routes } from "../../router/RouterPage";
import { RootState } from "../../store/store";
import CommonComponents from "../Commons";

export interface IMenuItemProps {
  icon?: ReactNode;
  label: ReactNode;
  children?: ReactNode;
  defaultActive?: boolean;
  defaultExpand?: boolean;
  url?: string;
  baseOnUrl?: boolean;
  total?: any;
  tooltip?: IChildrenTooltip[];
  activeKey?: string | null;
  setActiveKey?: any;
  indexKey?: string;
  acrossUrl?: string;
  tooltipMain?: string;
  onClick?: () => void;
  className?: any;
}

export default function MenuItem({
  icon,
  label,
  defaultActive = false,
  children,
  defaultExpand = false,
  url,
  baseOnUrl = true,
  total,
  tooltip,
  activeKey,
  setActiveKey,
  indexKey,
  acrossUrl,
  tooltipMain,
  onClick,
  className,
}: IMenuItemProps) {
  const location = useLocation();
  const navigate = useNavigate();
  const { expanded } = useSelector((state: RootState) => state.layoutSlice);
  const [active, setActive] = useState<boolean>(defaultActive);
  const [isExpand, setIsExpand] = useState<boolean>(false);
  const [tooltipChildren, setTooltipChildren] = useState(false);
  const dispatch = useDispatch();

  const { showDrawer, leftDrawerType } = useSelector(
    (state: RootState) => state.drawerSlice
  );

  useEffect(() => {
    if (!baseOnUrl) {
      return;
    }

    if (!children) {
      setActive(url === location.pathname);
      return;
    }

    const parent = routes.find((r) => {
      return r.path === url;
    });

    const child = parent?.children?.find((r) => {
      return r.path === location.pathname;
    });

    setIsExpand(!!child);
  }, [location.pathname]);

  const onClickItem = () => {
    setActiveKey && setActiveKey();
    if (onClick) {
      onClick();
      return;
    }
    if (children && tooltip && !expanded) {
      setTooltipChildren(!tooltipChildren);
      return;
    }
    if (children) {
      setIsExpand(!isExpand);
      return;
    }
    if (acrossUrl) {
      window.open(acrossUrl, "_blank");
    }

    if (url) {
      setTooltipChildren(false);
      navigate(url);
    }
  };

  useEffect(() => {
    if (children && tooltip) {
      setTooltipChildren(false);
      return;
    }
  }, [expanded]);

  useEffect(() => {
    if (activeKey !== indexKey) {
      setTooltipChildren(false);
    }
  }, [activeKey]);

  const renderTooltipChildren = (listTooltip: IChildrenTooltip[]) => {
    return (
      <div className="bg-[#1a2032] text-sm  z-[1000] absolute left-12 top-20 w-[200px] rounded-lg">
        {listTooltip.map((item, index) => {
          return (
            <div
              className="py-2.5 pl-8 pr-3 !text-white hover:bg-[#3d3d3d] hover:rounded-lg block cursor-pointer"
              key={index}
              onClick={() => {
                navigate(item.url);
                setTooltipChildren(!tooltipChildren);
              }}
            >
              {item.label}
            </div>
          );
        })}
      </div>
    );
  };

  const rootClass = active ? " bg-common-ink300" : "";
  const innerClass = active
    ? " shadow-[inset_2px_0] shadow-common-primary500"
    : "";

  return (
    <div>
      <CommonComponents.TooltipUI title={tooltipMain ?? ""} placement="right">
        <div
          className={` rounded-[3px] p-2 py-[11px] !px-0 cursor-pointer hover:bg-common-ink400 flex justify-between items-center ${rootClass}`}
          onClick={onClickItem}
        >
          <div
            className={`flex items-center gap-3 w-full h-fit text-sm font-normal text-common-white${innerClass} w-full`}
          >
            <div className="w-full">
              <div className="flex justify-center items-center relative">
                {icon}
                <div className="absolute right-[6px] top-[2px]">
                  {total && total > 0 && total < 100 ? (
                    <Badge
                      color="#DD2C00"
                      size="small"
                      count={total}
                      className="w-6 border border-common-ink400 rounded-full"
                    />
                  ) : total && total > 99 ? (
                    <Badge
                      color="#DD2C00"
                      size="small"
                      className="w-6 border border-common-ink400 rounded-full"
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              display: children ? "block" : "none",
              marginLeft: "4px",
            }}
          >
            {expanded && (
              <div
                className="text-common-white"
                style={{ rotate: isExpand ? "0deg" : "180deg" }}
              >
                <CommonIcons.ChevronUpIcon />
              </div>
            )}
          </div>
        </div>
      </CommonComponents.TooltipUI>

      {expanded && (
        <div
          className="pl-6 overflow-hidden"
          style={{ height: children && isExpand ? "fit-content" : 0 }}
        >
          {children}
        </div>
      )}
      {activeKey === indexKey &&
        tooltipChildren &&
        tooltip &&
        renderTooltipChildren(tooltip)}
    </div>
  );
}
