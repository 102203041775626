import { Divider, Form } from "antd";
import CommonComponents from "../../Commons";
import CardItemConfirmComp from "../CardItemConfirm";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { useEffect, useState } from "react";
import { EProviderName } from "../../../enums";
import { onMouseEnterItem, onMouseLeaveItem } from "../../MapTasking/ultis";

const Step2 = (props: any) => {
  const groups = useSelector((state: RootState) => state.appCommonSlice.groups);

  const { providerConfigStore } = useSelector(
    (state: RootState) => state.appCommonSlice
  );

  const [imageSelected, setImagesSelected] = useState<any>([]);

  useEffect(() => {
    let a = props?.items?.map((itemM: any) => itemM?.id);
    setImagesSelected(a);
  }, [props?.items]);

  useEffect(() => {
    if (props?.initialValues && props.form) {
      const { initialValues } = props;

      const formValues: any = {
        capella: {},
      };

      initialValues.forEach((item: any) => {
        const imageId = item?.imageId[0];
        const collectionId = item?.options.find(
          (option: any) => option.key === "collection_id"
        )?.value;

        if (imageId) {
          formValues.capella[imageId] = {
            collection_id: collectionId ?? undefined,
          };
        }
      });

      props.form.setFieldsValue(formValues);
    }
  }, [props?.initialValues, props.form]);

  return (
    <div className="text-sm text-white flex flex-col gap-3">
      {props?.items?.map((item: any, ind: any) => {
        return (
          <>
            <div className="div-collection-step2">
              <CommonComponents.FormFieldUI
                name={["capella", item?.feature?.id, "collection_id"]}
                initialValue={item?.feature?.collection}
                component={
                  <CommonComponents.SelectUI
                    label="Collection"
                    placeholder="Collection"
                    data={
                      props?.dataProvider ??
                      providerConfigStore?.content_page?.filter(
                        (item: any) =>
                          item?.name.toLowerCase() === EProviderName.capella
                      )?.[0]?.configs
                    }
                    required={groups?.includes("admin")}
                    disabled
                  />
                }
                rules={
                  groups?.includes("admin")
                    ? [{ required: true, message: "Please select here!" }]
                    : []
                }
              />

              <CardItemConfirmComp
                style={{ background: "#3C4152" }}
                item={item}
                onMouseLeaveItem={() =>
                  onMouseLeaveItem(item?.feature, props.map, props.sourceId)
                }
                onMouseEnterItem={() =>
                  onMouseEnterItem(item?.feature, props.map, props.sourceId)
                }
                selected={imageSelected?.includes(item?.id)}
                onClick={() => {
                  props?.onClick(item?.id);
                  if (imageSelected?.includes(item?.id)) {
                    setImagesSelected(
                      imageSelected?.filter((itemF: any) => itemF !== item?.id)
                    );
                  } else {
                    let a = imageSelected;
                    setImagesSelected(a?.concat(item?.id));
                  }
                }}
              />
            </div>
            {ind < props?.items?.length - 1 && (
              <Divider style={{ background: "#353B4F", margin: "6px 0" }} />
            )}
          </>
        );
      })}
    </div>
  );
};

export default Step2;
