import axios from "axios";
import { apis } from "../constants/apis";
import httpService from "./httpService";

export async function approveOrder(payload: any) {
  const { idStatus, ...newPay } = payload;
  // admin submit to provider
  return httpService.patch(
    `/api/v1/orders/submit/approved/capella/${payload?.idStatus}`,
    newPay
  );
}

export async function submitOrder(payload: any) {
  // admin submit to provider
  return httpService.post(`/api/v1/orders/submit`, payload);
}

export async function createOrder(payload: any) {
  return httpService.post(`-flow/api/v1/order`, payload);
}

export async function getPriority() {
  return httpService.get(`${apis.order.getPriority}`);
}

export async function createTaskingOrder(payload: any) {
  return httpService.post(`${apis.order.createTasking}`, payload);
}

export async function getArchiveOrderById(id?: any) {
  return httpService.get(`${apis.order.getArchiveOrderById}/${id}`);
}

export async function getProductTypeId(payload: any) {
  return httpService.get(`${apis.order.getProductTypeId}/${payload?.type}`);
}

export async function getDetailOrderTaskingByUer(id: string) {
  return httpService.get(
    `${apis.orderTasking.getDetailOrderTaskingByUer}/${id}`
  );
}

export async function getDetailOrderTaskingByAdmin(id: string) {
  return httpService.get(
    `${apis.orderTasking.getDetailOrderTaskingByAdmin}/${id}/by-admin`
  );
}

export async function getOrderByIdUser(id: string) {
  return httpService.get(`${apis.order.getOrderByIdUser}/${id}`);
}

export async function getOrderById(id: string) {
  return httpService.get(`${apis.order.getOrderByIdAdmin}/${id}/by-admin`);
}

export async function getSegmentsByIdOrder(payload: any) {
  return httpService.post(`${apis.order.getSegmentByIdOrder}`, payload);
}

export async function getProduction() {
  return httpService.get(`${apis.order.getProduction}`);
}

export async function confirmOrderTaskingAdmin(payload: any) {
  const { id, ...newPay } = payload;
  return httpService.put(
    `${apis.order.confirmOrderTaskingAdmin}/${payload?.id}`,
    newPay
  );
}

export async function getMetakeys(payload: any) {
  return httpService.get(`${apis.metaKey.default}`, payload);
}

export async function updateStatusTaskingOrder(payload: any) {
  let newPay = { note: payload?.note };
  return httpService.put(
    `${apis.orderTasking.updateStatus}/${payload?.id}/${payload?.statusId}`,
    newPay
  );
}

export async function approveArchiveOrder(payload: any) {
  const { id, ...newPay } = payload;

  return httpService.put(
    `${apis.order.approveArchiveOrder}/${payload?.id}`,
    newPay
  );
}

export async function getTaskingSchema(payload: any) {
  return httpService.get(
    `-flow/api/v1/schema/${payload?.idProvider}/${payload?.type}`
  );
}

export async function updateArchiveOrder(payload: any) {
  const { orderId, ...newPay } = payload;

  return httpService.put(`/api/v1/orders/${payload?.orderId}`, newPay);
}

export async function updateArchiveOrderByAdmin(payload: any) {
  const { orderId, ...newPay } = payload;

  return httpService.put(`/api/v1/orders/${payload?.orderId}/by-admin`, newPay);
}

export async function getOrderDetailByAdmin(payload: any) {
  return httpService.get(
    `/api/v1/orders/detail/${payload?.idDetailOrder}/by-admin`
  );
}

export async function getOrderDetailByUser(payload: any) {
  return httpService.get(`/api/v1/orders/detail/${payload?.idDetailOrder}`);
}

export async function postNotiOrder(payload: any) {
  const { idDetailOrder, ...newPay } = payload;
  // using for delivery step
  return httpService.post(
    `-flow/api/v1/order/oneatlas/notifications/${payload?.idDetailOrder}`,
    newPay
  );
}

export async function sendPriceToUser(payload: any) {
  const { idDetailOrder, ...newPay } = payload;
  // using for delivery step
  return httpService.put(
    `/api/v1/orders/update/actual-price/${payload?.idDetailOrder}/by-admin`,
    newPay
  );
}

export async function sendQuotationToUser(payload: any) {
  // for admin

  const { orderId, ...newPay } = payload;

  return httpService.put(
    `/api/v1/orders/update/actual-price/${payload?.orderId}/by-admin`,
    payload?.data
  );
}

export async function sendToProvider(payload: any) {
  // for admin
  // using for delivery step
  return httpService.post(`/api/v1/orders/sent/oneatlas`, {
    order_detail_id: payload?.idDetailOrder,
  });
}

export async function sendToProviderCapella(payload: any) {
  // for admin
  // using for delivery step
  return httpService.post(
    `/api/v1/orders/submit/approved/capella/${payload?.idStatus}`,
    {
      order_detail_id: payload?.idDetailOrder,
    }
  );
}

export async function uploadData(payload: any) {
  // using for delivery step
  return httpService.post(`/api/v1/access/upload`, payload?.formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    params: {
      order_detail_id: payload?.idDetailOrder,
    },
  });
}

export async function getDataUpload(payload: any) {
  return httpService.get(`/api/v1/access/download/${payload?.orderdetailId}`);
}

export async function resendEmail(id: any) {
  return httpService.get(`/api/v1/access/send-mail-get-links/${id}`, {});
}
