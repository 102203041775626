import { DrawerProps } from "antd";
import React, { ReactNode } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";

interface IProps {
  open?: boolean;
  placement?: "top" | "right" | "bottom" | "left";
  children?: ReactNode;
  zIndex?: number;
  width?: string | number;
  height?: string | number;
  style?: any;
  classNames?: string;
}

export default function DrawerUI({
  open = false,
  placement = "left",
  zIndex = 20,
  children,
  width = 460,
  height = 250,
  style,
  classNames,
}: IProps) {
  const { colorConfig } = useSelector(
    (state: RootState) => state.appCommonSlice
  );
  let className = `absolute overflow-hidden   `;

  switch (placement) {
    case "top":
      className += "w-full top-0";
      style = { ...style, zIndex: zIndex, top: open ? 0 : -height, height };
      break;

    case "bottom":
      className += "w-full bottom-0";
      style = { ...style, zIndex: zIndex, bottom: open ? 0 : -height, height };
      break;

    case "left":
      className += "h-full bottom-0 left-0";
      style = { ...style, zIndex: zIndex, left: open ? 0 : -width };
      break;

    case "right":
      className += "h-full bottom-0 right-0 ";
      style = {
        ...style,
        zIndex: zIndex,
        right: open ? 0 : -width,
      };
      break;

    default:
      break;
  }

  return (
    <div
      className={`${className}` + classNames}
      style={{ ...style, background: colorConfig.mainColor }}
    >
      {children}
    </div>
  );
}
