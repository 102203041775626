import { Checkbox, Empty, MenuProps } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CommonComponents from "..";
import CommonIcons from "../../../assets/icons";
import ApplySvg from "../../../assets/svg/apply.svg";
import DeleteSvg from "../../../assets/svg/delete.svg";
import DismissSvg from "../../../assets/svg/dismiss.svg";
import { showError, showSuccess } from "../../../helpers/toast";
import useToggle from "../../../hooks/useToggle";
import { setLoadingGlobal } from "../../../store/reducers/appCommonSlice";
import {
  delImgIntoCartAsync,
  getImgsAsync,
  setArrIdImage,
  setSelectedImages,
  setSelectedImagesConfirm,
} from "../../../store/reducers/cartSlice";
import {
  changeLeftDrawerType,
  changeShowDrawer,
} from "../../../store/reducers/drawerSlice";
import { setSourceData } from "../../../store/reducers/mapSlice";
import { RootState } from "../../../store/store";
import ButtonIconComp from "../../MapTasking/ButtonIconComp";
import DropdownUI from "../Dropdown";
import LoadingGlobalComp from "../Loading/LoadingGlobal";
import CardItemInCartComp from "./CardItem";
import { useTranslation } from "react-i18next";
import NoData from "../../NoData";

interface IProps {
  hidden?: boolean;
  onClose?: () => void;
  selectedIds?: any;
  onShowInMap?: any;
  onMouseEnterItem?: any;
  onMouseLeaveItem?: any;
}

export default function CartDrawerComp({
  hidden = true,
  selectedIds,
  onClose,
  onShowInMap,
  onMouseEnterItem,
  onMouseLeaveItem,
}: IProps) {
  //! state
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const leftDrawerType = useSelector(
    (state: RootState) => state.drawerSlice.leftDrawerType
  );

  let { arrIdImage, selectedImages } = useSelector(
    (state: RootState) => state.cartSlice
  );
  const { sourceData } = useSelector((state: RootState) => state.mapSlice);
  const imagesRecentSelected = useSelector(
    (state: RootState) => state.cartSlice.imagesRecentSelected
  );

  let allProviders = useSelector(
    (state: RootState) => state.mapTasking.arrAllProviders
  );
  const [data, setData] = useState([]);

  const [dataAll, setDataAll] = useState([]);
  // @ts-ignore
  const [type, setType] = useState<any>(t("All"));

  const [loading, setLoading] = useState<boolean>(false);
  const [idDelete, setIdDelete] = useState<null | string>();
  const {
    open: openConfirmDelete,
    shouldRender: shouldRenderConfirmDelete,
    toggle: toggleConfirmDelete,
  } = useToggle();
  //! function
  const getCart = async () => {
    dispatch(setLoadingGlobal(true));
    setLoading(true);
    // @ts-ignore
    const res = await dispatch(getImgsAsync({})).unwrap();

    if (res?.status === 200) {
      let images = res?.data?.data?.images;

      let arr: any = [];

      images?.forEach((itemM: any) => {
        const updatedFeatures = itemM?.images?.map((feature: any) => ({
          ...feature,
          provider_id: itemM.provider_id,
          provider_name: itemM.provider_name,
          color_provider_name: itemM?.color_provider_name ?? "",
        }));

        arr = arr.concat(updatedFeatures);
      });

      setData(arr);
      setDataAll(arr);
    }

    dispatch(setLoadingGlobal(false));
    setLoading(false);
  };

  const onSelectImage = (image: any) => {
    const _imgs = JSON.parse(JSON.stringify(selectedImages)) as string[];

    if (
      !image?.feature?.properties[`${image?.provider_name.toLowerCase()}:id`]
    ) {
      return;
    }
    if (
      _imgs?.filter(
        (item: any) =>
          item?.feature?.properties[
            `${item?.provider_name.toLowerCase()}:id`
          ] ===
          image?.feature?.properties[`${image?.provider_name.toLowerCase()}:id`]
      )?.length > 0
    ) {
      let arr = _imgs.filter((img: any) => {
        return (
          img?.feature?.properties[`${img?.provider_name.toLowerCase()}:id`] !==
          image?.feature?.properties[`${image?.provider_name.toLowerCase()}:id`]
        );
      });

      dispatch(setSelectedImages(arr));
    } else {
      _imgs.push(image);

      dispatch(setSelectedImages(_imgs));
    }
  };

  const onSelectAll = (e: CheckboxChangeEvent) => {
    const value = e.target.checked;
    // setSelectAll(value);

    if (value) {
      dispatch(
        setSelectedImages(
          // data?.map(
          //   (itemM: any) =>
          //     itemM?.properties[`${itemM?.provider_name.toLowerCase()}:id`]
          // )
          data
        )
      );
    } else {
      dispatch(setSelectedImages([]));
    }
  };

  const getDropdownSortItems = () => {
    const items: MenuProps["items"] = [];

    let a = allProviders?.map((itemM: any) => itemM?.name);
    // @ts-ignore
    let arr = [t("All")].concat(a);

    arr.forEach((item, index) => {
      items.push({
        key: index,
        label: item,
        onClick: () => {
          setType(item);
          onUpdateSorting(item);
        },
      });
    });

    return items;
  };

  const onUpdateSorting = (type: string) => {
    dispatch(setLoadingGlobal(true));
    setLoading(true);
    // @ts-ignore
    if (type === t("All")) {
      setData(dataAll);
    } else {
      let a = dataAll?.filter((item: any) => item?.provider_name === type);

      setData(a);
    }
    dispatch(setLoadingGlobal(false));
    setLoading(false);
  };

  const deleteMultiImages = async (arr: any) => {
    dispatch(setLoadingGlobal(true));
    setLoading(true);
    // @ts-ignore
    const res = await dispatch(
      // @ts-ignore
      delImgIntoCartAsync({
        // imageIds: arr.map((itemM: any) => itemM.id),
        image_ids: arr,
      })
    ).unwrap();

    if (res?.status === 200) {
      showSuccess(t("Delete image from cart successfully"));

      setIdDelete(null);
      toggleConfirmDelete();

      let arrIdImageClone = arrIdImage;

      let result = arrIdImageClone.filter(
        (itemFilter: any) => !arr.some((image: any) => image === itemFilter)
      );

      dispatch(setArrIdImage(result));
      const updatedData = data.filter(
        (itemFilter: any) =>
          !arr.some(
            (image: any) =>
              image ===
              itemFilter?.feature?.properties[
                `${itemFilter?.provider_name.toLowerCase()}:id`
              ]
          )
      );

      setData(updatedData);

      let a = dataAll.filter(
        (itemFilter: any) =>
          !arr.some(
            (image: any) =>
              image ===
              itemFilter?.feature?.properties[
                `${itemFilter?.provider_name.toLowerCase()}:id`
              ]
          )
      );

      setDataAll(a);

      let newSourceData = sourceData?.features?.filter(
        (itemFilter: any) => !arr.includes(itemFilter.properties?.id)
      );
      dispatch(setSourceData({ ...sourceData, features: newSourceData }));

      dispatch(setSelectedImages([]));
    } else {
      showError(t("Delete image from cart failed"));
    }

    dispatch(setLoadingGlobal(false));
    setLoading(false);
  };
  const handleDeleteOne = (id: string) => {
    setIdDelete(id);
    toggleConfirmDelete();
  };
  //! effect
  useEffect(() => {
    if (leftDrawerType === "cart") {
      getCart();
    }
  }, [leftDrawerType]);

  useEffect(() => {
    if (imagesRecentSelected?.length > 0) {
      let imagesMatchRecent: any = [];
      data?.map((itemM: any) => {
        if (
          imagesRecentSelected?.includes(
            itemM?.feature?.properties[
              `${itemM?.provider_name.toLowerCase()}:id`
            ]
          )
        ) {
          return imagesMatchRecent.push(itemM);
        }
      });
      dispatch(setSelectedImages(imagesMatchRecent));
    }
  }, [imagesRecentSelected, data]);

  const { colorConfig } = useSelector(
    (state: RootState) => state.appCommonSlice
  );

  //! render

  return (
    <div hidden={hidden} className="h-full p-3 w-[460px]">
      <div
        className={`sticky -top-3 z-10 `}
        style={{ background: colorConfig.mainColor }}
      >
        <div className="flex justify-between items-center py-1">
          <div className="flex items-center gap-2 text-sm font-normal text-common-white">
            <div className="scale-125">
              <CommonIcons.CartMenuIcon />
            </div>
            <div>
              {/* @ts-ignore */}
              {t("Cart")} ({data?.length})
            </div>
          </div>

          <div className="flex items-center gap-2 justify-end">
            <ButtonIconComp
              item={{
                description: t("Delete image"),
                icon: DeleteSvg,
              }}
              className="!h-7 !w-[30px] !min-w-[30px] !rounded !bg-[#272D3C] div-one-center"
              disabled={selectedImages?.length === 0}
              onClick={() => {
                toggleConfirmDelete();
                // deleteMultiImages(selectedImages)
              }}
            />

            <ButtonIconComp
              item={{
                description: t("Order Image"),
                icon: ApplySvg,
                title: t("Order"),
              }}
              classNameImage="!w-[14px]"
              className="!h-7 !min-w-[30px] !rounded !bg-[#272D3C] !px-3 !py-1"
              disabled={selectedImages?.length === 0}
              onClick={() => {
                dispatch(changeShowDrawer(false));
                dispatch(changeLeftDrawerType(""));
                navigate("/archive-order");

                dispatch(setSelectedImagesConfirm(selectedImages));
              }}
            />

            <ButtonIconComp
              item={{
                description: t("Close"),
                icon: DismissSvg,
              }}
              onClick={onClose}
              className="!h-7 !w-[30px] !min-w-[30px] !rounded !bg-[#272D3C] div-one-center"
            />
            {/* <CommonComponents.ButtonUI
              icon={<CommonIcons.DismissIcon />}
              commonType="standard"
              className="!text-xs !h-7"
              onClick={onClose}
            ></CommonComponents.ButtonUI> */}
          </div>
        </div>

        <div className="flex justify-between items-center my-3">
          <Checkbox
            className="text-sm text-common-white font-normal w-fit"
            indeterminate={
              selectedImages.length > 0 && selectedImages.length < data.length
            }
            checked={
              selectedImages.length > 0 && selectedImages.length === data.length
            }
            onChange={onSelectAll}
          >
            {t("All")}
          </Checkbox>

          <DropdownUI
            className="!h-[32px] max-w-[167px] !w-[155px] hover:!bg-[#272D3C] !bg-[#272D3C] hover:shadow-[0px_0.5px_1px_0px_#FFFFFF1A_inset]"
            menu={{ items: getDropdownSortItems() }}
            buttonlabel={type}
          ></DropdownUI>
        </div>
      </div>

      <div
        className="flex flex-col gap-2 overflow-x-auto"
        style={{ height: "calc(100% - 100px)" }}
      >
        {data?.map((item: any, ind: number) => (
          <CardItemInCartComp
            data={item}
            checked={
              selectedImages.filter(
                (itemF: any) =>
                  itemF?.feature?.properties[
                    `${itemF?.provider_name.toLowerCase()}:id`
                  ] ===
                  item?.feature?.properties[
                    `${item?.provider_name.toLowerCase()}:id`
                  ]
              )?.length > 0
            } // flag check image choosen
            onSelect={(value: any) => onSelectImage(item)}
            onShowInMap={(e: any, data: any) => onShowInMap(e, data)}
            deleteImage={() =>
              handleDeleteOne(
                item?.feature?.properties[
                  `${item?.provider_name?.toLowerCase()}:id`
                ]
              )
            }
            onMouseEnterItem={() => {
              onMouseEnterItem(item);
            }}
            onMouseLeaveItem={() => {
              onMouseLeaveItem([item]);
            }}
            toggleConfirmDelete={toggleConfirmDelete}
          />
        ))}

        {data?.length === 0 && !loading && <NoData />}

        {loading && <LoadingGlobalComp />}
      </div>

      {shouldRenderConfirmDelete && (
        <CommonComponents.ModalUI
          open={openConfirmDelete}
          title={t("Images Cart")}
          toggle={() => {
            setIdDelete(null);
            toggleConfirmDelete();
          }}
          handleConfirm={() => {
            if (idDelete) {
              deleteMultiImages([idDelete]);
              return;
            }
            deleteMultiImages(
              selectedImages?.map(
                (itemM: any) =>
                  itemM?.feature?.properties[
                    `${itemM?.provider_name.toLowerCase()}:id`
                  ]
              )
            );
          }}
          loading={loading}
        >
          <p>{t("Do you want delete images?")}</p>
        </CommonComponents.ModalUI>
      )}
    </div>
  );
}
