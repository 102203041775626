import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import LogoSvg from "./../../assets/svg/logo.svg";
import { Tooltip } from "antd";

const ListAppComp = () => {
  const data = [
    {
      name: "VMRS",
      logo: LogoSvg,
    },
    {
      name: "EOM",
      logo: LogoSvg,
    },
    {
      name: "Account",
      logo: LogoSvg,
    },
    {
      name: "Admin",
      logo: LogoSvg,
    },
    {
      name: "E-learning",
      logo: LogoSvg,
    },
    {
      name: "Gishub",
      logo: LogoSvg,
    },
    {
      name: "FM",
      logo: LogoSvg,
    },
    {
      name: "Tera3d",
      logo: LogoSvg,
    },
  ];
  const visibleListApp = useSelector(
    (state: RootState) => state.appCommonSlice.visibleListApp
  );

  return (
    <div
      className="absolute list-app"
      style={{ display: visibleListApp ? "block" : "none" }}
    >
      <div className="grid grid-cols-3 gap-4">
        {data?.map((item: any, ind: any) => (
          <div
            key={ind}
            className="flex items-center flex-col hover:bg-[#244159] rounded-md cursor-pointer h-[76px] w-[62px] px-1 py-2"
          >
            <div className="bg-[#D9D9D9] !min-h-8 h-8 w-8 rounded-full flex justify-center items-center flex-col">
              <img src={item?.logo} />
            </div>
            <Tooltip title={item?.name}>
              <p style={{ fontSize: "12px" }} className="mt-[12px]">
                {item?.name?.length > 7
                  ? item?.name?.slice(0, 7) + "..."
                  : item?.name}
              </p>
            </Tooltip>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ListAppComp;
