import { CloseOutlined } from "@ant-design/icons";
import { Button, Modal, ModalProps, Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

interface IProps extends ModalProps {
  title: React.ReactNode | string;
  open: boolean;
  toggle: () => void;
  handleConfirm: () => void;
  children?: React.ReactNode | string;
  hiddenFooter?: boolean;
  disabled?: boolean;
}
const ModalUI = (props: IProps) => {
  //! state
  const {
    open,
    title,
    toggle,
    handleConfirm,
    hiddenFooter,
    children,
    disabled,
    ...res
  } = props;

  const { t } = useTranslation();
  return (
    <Modal
      title={
        <Typography.Title level={4} className="!text-white">
          {title ?? "Notification"}
        </Typography.Title>
      }
      open={open}
      centered
      onCancel={toggle}
      closeIcon={
        <div onClick={toggle}>
          <CloseOutlined className="text-white" />
        </div>
      }
      maskClosable={false}
      styles={{
        body: { backgroundColor: "#1A1F32" },
        header: { backgroundColor: "#1A1F32" },
        content: { backgroundColor: "#1A1F32" },
      }}
      footer={() =>
        hiddenFooter ? null : (
          <>
            <Button
              ghost
              className="h-8 w-[100px] hover:!text-white rounded !text-white !border !border-solid !border-gray-500"
              onClick={toggle}
            >
              {t("No")}
            </Button>
            <Button
              className="h-8 w-[100px] !bg-common-activeAction !text-white border-none hover:!text-white hover:!bg-common-activeAction rounded"
              onClick={handleConfirm}
              loading={res?.loading}
              disabled={disabled}
            >
              {t("Yes")}
            </Button>
          </>
        )
      }
      className="rounded-lg"
      {...res}
    >
      <div className="text-white py-3">{children}</div>
    </Modal>
  );
};

export default ModalUI;
