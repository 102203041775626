import { Divider, Select, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { resetLeftDrawer } from "../../store/reducers/drawerSlice";

import { useEffect, useState } from "react";
import CloseSvg from "../../assets/svg/dismiss.svg";
import TaskingSvg from "../../assets/svg/tasking.svg";
import {
  changeValueCheckAOI,
  getProviderAsync,
} from "../../store/reducers/mapTaskingSlice";
import {
  setNameProviderSelected,
  setOrderedProvider,
} from "../../store/reducers/orderSlice";
import { RootState } from "../../store/store";
import ButtonIconComp from "./components/ButtonIcon";
import { RenderInfoPolygon } from "./ultis";
import CommonComponents from "../Commons";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

const InfoPolygonComp = (props: any) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const orderedProvider = useSelector(
    (state: RootState) => state.orderSlice.orderedProvider
  );

  const selectedPolygon = useSelector(
    (state: RootState) => state.mapTasking.activeFeature
  );

  const nameProviderSelected = useSelector(
    (state: RootState) => state.orderSlice.nameProviderSelected
  );
  const valueCheckAOI = useSelector(
    (state: RootState) => state.mapTasking.valueCheckAOI
  );
  const [loading, setLoading] = useState(false);

  const [allProviders, setAllProviders] = useState<any>([]);

  const [checkedList, setCheckedList] = useState<any>([]);

  const getProviders = async () => {
    // @ts-ignore
    let resProvider = await dispatch(getProviderAsync()).unwrap();

    let a = resProvider?.data?.data?.content_page
      ?.filter((item: any) => item?.actived && !!item?.enabled_tasking)
      .map((item: any) => ({
        label: item?.name,
        value: item?.id,
      }));

    setAllProviders(a ?? []);
  };

  const handleChangeProvider = (value: number) => {
    setCheckedList([value]);
    dispatch(setOrderedProvider([value] as any));
  };

  useEffect(() => {
    if (window.location.pathname === "/explore-tasking") {
      getProviders();
    }
    return () => {
      setLoading(false);
    };
  }, []);

  useEffect(() => {
    if (allProviders?.length > 0) {
      setCheckedList([allProviders?.[0]?.value]);
      dispatch(setOrderedProvider([allProviders?.[0]?.value] as any));
    }
  }, [allProviders]);

  useEffect(() => {
    if (orderedProvider) {
      dispatch(
        setNameProviderSelected(
          allProviders
            ?.filter((item: any) => item?.value === orderedProvider?.[0])?.[0]
            ?.label?.toLowerCase()
        )
      );
    }
  }, [orderedProvider]);

  //! render
  const renderSelectProvider = () => {
    return allProviders?.map((item: any, ind: any) => {
      let imageProvider = "";
      switch (item.label.toLowerCase()) {
        case "planet":
          imageProvider = require("../../assets/svg/Planet.png");
          break;

        case "oneatlas":
          imageProvider = require("../../assets/svg/oneAtlas.png");
          break;

        default:
          imageProvider = require("../../assets/svg/Capella.png");
          break;
      }
      return (
        <div
          key={ind}
          className={
            "div-one-center !h-[120px] !w-[102px] rounded px-2 py-1 div-type cursor-pointer" +
            (item?.value === checkedList[0] ? " active" : "")
          }
          onClick={() => {
            handleChangeProvider(item?.value);
          }}
        >
          <div className="div-type-image-search div-one-center !justify-center">
            <img
              src={imageProvider}
              style={{ width: "50px", height: "50px", borderRadius: "5px" }}
            />
          </div>

          <p style={{ color: "white", fontSize: "14px" }}>{t(item?.label)}</p>
        </div>
      );
    });
  };

  const renderFormCheckAOI = () => {
    return (
      <div className="!text-white mt-4">
        <div className="flex gap-4 mb-4 w-full">
          <div className="w-full">
            <CommonComponents.SelectUI
              value={valueCheckAOI?.missions?.[0]}
              className="w-full"
              label={t("Missions")}
              data={[{ value: "PLEIADESNEO", label: "Pleiades Neo" }]}
              onChange={(val: any) => handleChangeFilter("missions", val)}
            />
          </div>
          <div className="w-full">
            <CommonComponents.SelectUI
              value={valueCheckAOI?.progTypeNames?.[0]}
              className="w-full"
              label={t("Sensors")}
              onChange={(val: any) => handleChangeFilter("progTypeNames", val)}
              data={[
                {
                  label: "One Now",
                  value: "ONENOWATTEMPTS",
                },
                {
                  label: "One Day",
                  value: "ONEDAY",
                },
              ]}
            />
          </div>
        </div>

        <div className="flex gap-4 mb-4 w-full">
          <div className="w-full">
            <CommonComponents.SelectUI
              value={valueCheckAOI?.optical?.maxIncidenceAngle}
              className="w-full"
              label={t("Incidence angle")}
              data={[
                //160.34°
                { value: "20", label: "20°" },
                { value: "30", label: "30°" },
                { value: "50", label: "50°" },
              ]}
              onChange={(val: any) =>
                handleChangeFilter("maxIncidenceAngle", val)
              }
            />
          </div>

          <div className="w-full">
            <CommonComponents.SelectUI
              className="w-full"
              label={t("Cloud cover")}
              value={valueCheckAOI?.optical?.maxCloudCover}
              data={[
                { value: "5", label: "5%" },
                { value: "10", label: "10%" },
                { value: "20", label: "20%" },
                { value: "30", label: "30%" },
              ]}
              onChange={(val: any) => {
                handleChangeFilter("maxCloudCover", val);
              }}
            />
          </div>
        </div>

        <div className="mb-4">
          <CommonComponents.SelectUI
            className="w-full"
            label={t("Acquisition mode")}
            value={valueCheckAOI?.optical?.acquisitionMode}
            data={[
              { value: "MONO", label: "MONO" },
              { value: "STEREO", label: "Strereo (custom)" },
              { value: "TRISTEREO", label: "Tristereo (custom)" },
            ]}
            onChange={(val: any) => handleChangeFilter("acquisitionMode", val)}
          />
        </div>

        <div className="flex gap-4 mb-4 w-full">
          <div className="w-full">
            <CommonComponents.DatePickerUI
              label={t("Start time")}
              placeholder={t("Select start date")}
              allowClear={false}
              className="w-full"
              value={valueCheckAOI?.acquisitionDate?.startDate}
              disabledDate={(current) => {
                return current && current < dayjs().startOf("day");
              }}
              onChange={(val: any, dateString: string | string[]) => {
                handleChangeFilter("startDate", val);
              }}
            />
          </div>

          <div className="w-full">
            <CommonComponents.DatePickerUI
              label={t("End time")}
              placeholder={t("Select start date")}
              allowClear={false}
              className="w-full"
              value={valueCheckAOI?.acquisitionDate?.endDate}
              disabledDate={(current) => {
                const startDate = valueCheckAOI?.acquisitionDate?.startDate;
                return (
                  current &&
                  (current < dayjs().startOf("day") || current < startDate)
                );
              }}
              onChange={(val: any, dateString: string | string[]) => {
                handleChangeFilter("endDate", val);
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  const handleChangeFilter = (key: string, value: any) => {
    if (key === "startDate" || key === "endDate") {
      let updatedStartDate = valueCheckAOI?.acquisitionDate?.startDate;
      let updatedEndDate = valueCheckAOI?.acquisitionDate?.endDate;

      if (key === "startDate") {
        updatedStartDate = value.startOf("day");
        if (updatedStartDate.isAfter(updatedEndDate)) {
          updatedEndDate = updatedStartDate.add(3, "days").endOf("day");
        }
      } else if (key === "endDate") {
        updatedEndDate = value.endOf("day");
      }

      dispatch(
        changeValueCheckAOI({
          ...valueCheckAOI,
          acquisitionDate: {
            startDate: updatedStartDate,
            endDate: updatedEndDate,
          },
        })
      );
    } else if (key === "progTypeNames" || key === "missions") {
      dispatch(
        changeValueCheckAOI({
          ...valueCheckAOI,
          [key]: [value],
        })
      );
    } else if (
      key === "acquisitionMode" ||
      key === "maxCloudCover" ||
      key === "maxIncidenceAngle"
    ) {
      dispatch(
        changeValueCheckAOI({
          ...valueCheckAOI,
          optical: {
            ...valueCheckAOI?.optical,
            [key]: value,
          },
        })
      );
    }
  };

  const { colorConfig } = useSelector(
    (state: RootState) => state.appCommonSlice
  );
  return (
    <div
      key={1}
      hidden={props?.hidden}
      className="w-[460px] h-full p-3 overflow-x-auto"
    >
      <Spin className="div-spin !max-h-full" spinning={loading}>
        <div
          className={`flex justify-between items-center py-1 sticky -top-3 z-10 `}
          style={{ background: colorConfig.mainColor }}
        >
          <div className="flex items-center gap-2 text-sm font-normal text-common-white">
            <img src={TaskingSvg} />

            <div>{selectedPolygon?.name}</div>
          </div>

          <div className="flex items-center gap-2 justify-end">
            <ButtonIconComp
              item={{ des: "Close", icon: CloseSvg }}
              onClick={() => dispatch(resetLeftDrawer())}
              className="!text-xs !h-7"
            />
          </div>
        </div>

        <Divider className="border-common-line !mt-1 !mb-2" />
        {window.location.pathname === "/explore-tasking" && (
          <>
            <div className="flex gap-3 my-4" style={{ color: "white" }}>
              {renderSelectProvider()}
            </div>

            {nameProviderSelected === "oneatlas" && renderFormCheckAOI()}

            <Divider className="border-common-line !my-8" />
          </>
        )}

        <div className="mt-10 text-white">
          <p className="mb-3">{t("Polygon information")}</p>
          {RenderInfoPolygon(selectedPolygon, t)}
          {/* <Collapse
            bordered={false}
            expandIconPosition="end"
            // defaultActiveKey={["1"]}
            items={[
              {
                key: "1",
                label: "Polygon information",
                children: RenderInfoPolygon(selectedPolygon),
              },
            ]}
            defaultActiveKey={["1"]}
            onChange={() => {}}
            className="!p-0 bg-transparent"
            expandIcon={({ isActive }) => (
              <div style={{ rotate: isActive ? "0deg" : "180deg" }}>
                <CommonIcons.ChevronUpIcon />
              </div>
            )}
          /> */}
        </div>
      </Spin>
    </div>
  );
};

export default InfoPolygonComp;
