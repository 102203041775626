import React, { useEffect, useState } from "react";
import CommonComponents from "../../Commons";
import { Form, Radio, Slider } from "antd";
import { DefaultFormatDateTime } from "../../../enums";
import useGetProviderMe from "../../../services/providerConfigs/hooks/useGetProviderMe";
import { useTranslation } from "react-i18next";

interface DeliveryCompProps {
  schema: any;
  provider: any;
  data?: any; // intinials data for schema
  setFieldRequireStep2?: any;
  form?: any;
  style?: any;
  isSubmited?: any;
}
const DeliveryComp: React.FC<DeliveryCompProps> = ({
  schema,
  provider,
  setFieldRequireStep2,
  form,
  style,
  isSubmited,
  data,
}) => {
  //! state
  const propertiesValue = Form.useWatch("properties", {
    form,
    preserve: true,
  });

  const { t } = useTranslation();
  let fieldArrayRequired: any = [];
  const typeDisable = isSubmited ? "edit" : "";
  const {
    data: dataProviderMe,
    isLoading: loadingProviderMe,
    error: errorProviderMe,
  } = useGetProviderMe(provider?.id, !!provider && provider?.id === 4);
  //! effect

  useEffect(() => {
    const fieldValues = fieldArrayRequired?.map((fieldPath: any) => {
      return form.getFieldValue(fieldPath);
    });
    setFieldRequireStep2(fieldValues);
  }, [JSON.stringify(fieldArrayRequired), propertiesValue]);
  useEffect(() => {
    if (!isSubmited) {
      fieldArrayRequired?.map((path: any) => {
        if (path.includes("orderGroup")) {
          form.setFieldValue(path, "Vega Group");
        }
        if (path.includes("endUserName")) {
          form.setFieldValue(
            path,
            dataProviderMe?.data?.provider_me?.contract?.name
          );
        }
        if (path.includes("country")) {
          form.setFieldValue(path, "VietNam");
        }
        if (path.includes("name")) {
          form.setFieldValue(path, dataProviderMe?.data?.identity?.email);
        }
        if (path.includes("email")) {
          form.setFieldValue(path, dataProviderMe?.data?.identity?.email);
        }
        if (path.includes("to")) {
          form.setFieldValue(path, dataProviderMe?.data?.identity?.email);
        }
        if (path.includes("daysBeforeEnd")) {
          form.setFieldValue(path, 3);
        }
      });
    } else {
      isSubmited &&
        form.setFieldValue(
          [
            "properties",
            "notifications",
            "notifications",
            "filter",
            "events",
            "events",
            "event",
          ],
          data?.order_details?.[0]?.request_order?.notifications?.[0]
            ?.filter?.[0]?.[0]?.event ??
            data?.notifications?.[0]?.filter?.[0]?.[0]?.event
        );
    }
  }, [loadingProviderMe]);

  //! render
  if (!schema || typeof schema !== "object") {
    return <div>No schema provided</div>;
  }

  const RenderField = () => {
    const renderWithType = (
      item: any,
      nameForm?: any,
      fieldRequired?: any,
      type?: any
    ) => {
      const [key, value] = item;
      if (key !== "required") {
        if (
          (value?.type === "string" || value?.type === "number") &&
          (value as any)?.enum?.length > 0
        ) {
          return (
            <CommonComponents.FormFieldUI
              name={nameForm?.concat([key])}
              initialValue={value?.enum?.[0]?.value ?? value?.enum?.[0]}
              component={
                <CommonComponents.SelectUI
                  label={t(value?.label)}
                  placeholder={key}
                  // required={fieldRequired?.includes(key)}
                  data={value?.enum?.map((itemM: any) => ({
                    label: itemM?.label ?? itemM,
                    value: itemM?.value ?? itemM,
                  }))}
                  disabled={type === "edit"}
                />
              }
              rules={[
                {
                  required: fieldRequired?.includes(key),
                  message: t("Please input here!"),
                },
              ]}
            />
          );
        }
        if (
          value?.type === "string" ||
          (value?.type === "number" && !value?.enum?.length)
        ) {
          if (key !== "cc") {
            fieldArrayRequired.push(nameForm?.concat([key]));
          }

          return (
            <CommonComponents.FormFieldUI
              name={nameForm?.concat([key])}
              component={
                <CommonComponents.InputUI
                  label={t(value?.label)}
                  placeholder={key}
                  loading={loadingProviderMe}
                  // required={fieldRequired?.includes(key)}
                  disabled={type === "edit"}
                  type={value?.type === "number" ? "number" : "text"}
                  onChange={(e: any) => {
                    if (value?.type === "number") {
                      const { value: inputValue } = e.target;
                      // Chỉ cho phép nhập số nguyên dương
                      const parsedValue = parseInt(inputValue, 10);
                      if (
                        !isNaN(parsedValue) &&
                        parsedValue >= 0 &&
                        parsedValue <= 365
                      ) {
                        e.target.value = parsedValue; // Cập nhật giá trị hợp lệ
                      } else {
                        return;
                      }
                    }
                  }}
                />
              }
              rules={
                value?.type === "number"
                  ? [
                      {
                        required: fieldRequired?.includes(key),
                        message: t("Please input here!"),
                      },
                      {
                        validator: (_, inputValue) => {
                          if (inputValue < 0) {
                            return Promise.reject(
                              new Error("Please enter a positive integer!")
                            );
                          }
                          if (inputValue > 365) {
                            return Promise.reject(
                              new Error(
                                "Value must be less than or equal to 365!"
                              )
                            );
                          }

                          return Promise.resolve();
                        },
                      },
                    ]
                  : [
                      {
                        required: fieldRequired?.includes(key) || key === "to",
                        message: t("Please input here!"),
                      },
                    ]
              }
            />
          );
        }
        if (value?.type === "object") {
          const fieldRequired = value?.required || [];

          return (
            <React.Fragment key={key}>
              {/* <h3>{value?.label}</h3> */}
              {Object.entries(value.properties || {}).map((child) =>
                renderWithType(
                  child,
                  nameForm?.concat([key]),
                  fieldRequired,
                  typeDisable
                )
              )}
            </React.Fragment>
          );
        }
        if (value?.type === "array" && value.items) {
          return (
            <React.Fragment key={key}>
              {renderWithType(
                [key, value.items],
                nameForm?.concat([key]),
                [],
                typeDisable
              )}
            </React.Fragment>
          );
        }

        if (
          value?.type === "number" &&
          typeof value?.minimum !== "undefined" &&
          typeof value?.maximum !== "undefined"
        ) {
          return (
            <>
              <CommonComponents.FormFieldUI
                label={t(value?.label)}
                name={nameForm?.concat([key])}
                component={
                  <Slider
                    range={value?.is_range ?? true}
                    max={value?.maximum}
                    min={value?.minimum}
                    disabled={type === "edit"}
                    tooltip={{
                      placement: "right",
                      // open: props?.currentStep === 1 ? true : false,
                      autoAdjustOverflow: false,
                      zIndex: 5,
                    }}
                  />
                }
                // rules={[{ required: true, message: t("Please input here!") }]}
                rules={[
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (key === "offNadirMax") {
                        const offNadirMin = getFieldValue([
                          "properties",
                          "collectConstraints",
                          "offNadirMin",
                        ]);

                        if (offNadirMin > value) {
                          return Promise.reject([
                            t(
                              "Off nadir max must be greater than off nadir min"
                            ),
                          ]);
                        }
                      }

                      return Promise.resolve();
                    },
                  }),
                ]}
              />
            </>
          );
        }

        if (value?.type === "boolean") {
          return (
            <>
              {/* <h3>{value?.label}</h3> */}
              <CommonComponents.FormFieldUI
                label={t(value?.label)}
                name={nameForm?.concat([key])}
                component={
                  <Radio.Group>
                    <Radio value={true}>True</Radio>
                    <Radio value={false}>False</Radio>
                  </Radio.Group>
                }
                // rules={[{ required: true, message: t("Please input here!") }]}
              />
            </>
          );
        }

        if (value?.type === "datetime" && !value?.format) {
          return (
            <>
              <CommonComponents.FormFieldUI
                label={t(value?.label)}
                name={nameForm?.concat([key])}
                initialValue={undefined}
                component={
                  <CommonComponents.DatePickerUI
                    // disabled={type === "edit"}
                    allowClear={false}
                    className="w-full"
                    placeholder={value?.label}
                    format={DefaultFormatDateTime.DATE_FORMAT}
                  />
                }
              />
            </>
          );
        }

        if (
          value?.type === "datetime" &&
          value?.format ===
            `^yyyy-MM-dd'T'HH:mm:ss.SSS'Z' yyyy-MM-dd'T'HH:mm:ss.SSS'Z'$`
        ) {
          // if (props?.type !== "edit") {
          //   props.form.setFieldValue(nameForm?.concat([key, "From"]), dayjs());
          //   props.form.setFieldValue(
          //     nameForm?.concat([key, "To"]),
          //     dayjs().add(3, "month")
          //   );
          // }

          return (
            <>
              {/* Field for "From" */}
              <CommonComponents.FormFieldUI
                label={t(value?.label) + " " + t("From")}
                name={nameForm?.concat([key, "From"])}
                component={
                  <CommonComponents.DatePickerUI
                    disabled={type === "edit"}
                    allowClear={false}
                    className="w-full"
                    defaultValue={undefined}
                    placeholder="From"
                    format={DefaultFormatDateTime.DATE_FORMAT}
                  />
                }
              />

              {/* Field for "To" */}
              <CommonComponents.FormFieldUI
                label={t(value?.label) + " " + t("To")}
                name={nameForm?.concat([key, "To"])}
                component={
                  <CommonComponents.DatePickerUI
                    disabled={type === "edit"}
                    allowClear={false}
                    className="w-full"
                    defaultValue={undefined}
                    placeholder="To"
                    format={DefaultFormatDateTime.DATE_FORMAT}
                  />
                }
              />
            </>
          );
        }
      }
    };
    return Object.entries(schema.properties).map((field) => {
      return renderWithType(
        field,
        ["properties"],
        schema?.required,
        typeDisable
      );
    });
  };

  return (
    <div style={style}>
      <div className="grid grid-cols-3 !gap-x-[20px] !gap-y-[32px]">
        {RenderField()}
      </div>
    </div>
  );
};

export default DeliveryComp;
