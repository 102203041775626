import { apis } from "../../constants/apis";
import httpService from "../httpService";

class MetaKeyService {
  GetDetailMetaKey(id: string) {
    return httpService.get(`${apis.metaKey.default}/${id}`);
  }
}

export default new MetaKeyService();

export async function getMetakey(payload: any) {
  return httpService.get(`${apis.metaKey.default}`, {
    params: payload,
  });
}

export async function getGlobalConfigs(name: string) {
  return httpService.get(`/api/v1/global-configs/by-name/${name}`);
}
